import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK= theme.palette.primary.dark;
 
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 669 669" >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
		<g viewBox="0 0 980.000000 980.000000" >

 

		<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M1.000000,101.000000 
	C1.000000,98.958328 1.000000,96.916656 1.392616,94.318192 
	C6.315932,47.935257 38.175575,14.008523 78.368980,3.644128 
	C81.279716,2.893557 84.124535,1.887351 87.000000,1.000000 
	C252.358231,1.000000 417.716461,1.000000 583.647217,1.385146 
	C622.363281,8.718234 648.860901,30.180613 663.586548,65.964867 
	C666.363708,72.713478 667.894226,79.975113 670.000000,87.000000 
	C670.000000,252.691574 670.000000,418.383148 669.616943,584.595093 
	C668.868774,586.384827 668.463989,587.644287 668.144226,588.924866 
	C657.638489,630.997009 631.182251,657.016174 589.167969,667.328674 
	C584.821899,668.395447 580.391357,669.117859 576.000000,670.000000 
	C575.555542,670.000000 575.111084,670.000000 574.188782,669.582642 
	C573.223145,669.077881 572.735291,668.914001 572.247498,668.913940 
	C414.427094,668.886414 256.606689,668.868225 98.786301,668.901001 
	C97.857460,668.901184 96.928764,669.617920 96.000000,670.000000 
	C95.555557,670.000000 95.111107,670.000000 94.240341,669.605591 
	C93.167488,669.090515 92.525108,668.937683 91.873840,668.853760 
	C47.292015,663.110901 13.307817,632.020264 3.628055,588.136047 
	C2.806617,584.411987 1.878542,580.711487 1.000002,577.000000 
	C1.000000,575.933289 1.000000,574.866638 1.414196,573.242554 
	C1.899379,569.699646 2.032276,566.714050 2.032478,563.728516 
	C2.042750,411.647644 2.046020,259.566803 1.998618,107.485954 
	C1.997944,105.323868 1.347295,103.161980 1.000000,101.000000 
M207.280380,441.401245 
	C207.280380,441.401245 207.262894,441.734436 206.603210,441.320984 
	C206.091187,441.282928 205.579163,441.244873 204.692352,440.639771 
	C203.794006,441.346863 202.895645,442.053955 201.353683,443.115234 
	C200.657852,443.338776 199.962006,443.562347 198.609238,443.358643 
	C198.094681,443.306671 197.580124,443.254700 196.656784,442.617126 
	C194.305328,441.361176 193.057114,442.047607 192.881851,445.119934 
	C192.881851,445.119934 192.817764,445.511627 192.126755,445.549896 
	C191.437515,445.617828 190.748276,445.685791 189.565125,445.377625 
	C188.742661,445.343414 187.920197,445.309235 186.726868,444.689240 
	C185.825089,445.170959 184.923309,445.652649 183.614990,446.388184 
	C183.321060,446.556366 183.129684,446.803833 182.451462,447.081268 
	C181.963562,447.338287 181.475677,447.595306 180.188873,447.777222 
	C179.126968,447.879364 178.065048,447.981476 176.593323,447.516449 
	C175.429047,447.408142 174.264771,447.299835 172.786850,446.693085 
	C172.186874,447.246796 171.586899,447.800537 170.390823,448.765656 
	C169.923355,448.887238 169.455887,449.008820 168.557861,448.581421 
	C167.400055,448.467102 166.242264,448.352783 164.792725,447.594360 
	C162.524750,445.131409 160.954117,445.610138 159.220505,448.940918 
	C158.142120,448.875153 157.063736,448.809387 155.818130,448.050598 
	C152.527069,445.474762 149.249191,444.223633 145.582626,448.181122 
	C145.582626,448.181122 145.157013,448.063934 144.802017,448.082428 
	C144.568222,448.130035 144.362198,448.233093 143.592010,448.479370 
	C143.402649,448.416656 143.213287,448.353973 142.967606,447.688934 
	C141.958252,447.523010 140.948914,447.357117 139.302383,447.196533 
	C138.515915,447.395416 137.729446,447.594299 136.230270,447.639801 
	C134.823380,447.257416 133.416489,446.875031 131.963547,445.814087 
	C129.275024,444.969757 126.586487,444.125427 123.121803,443.194305 
	C122.349014,443.241974 121.576225,443.289642 120.269157,443.118256 
	C119.894211,443.032654 119.519272,442.947052 118.917000,442.171661 
	C118.069008,441.763611 117.221016,441.355560 116.461861,440.643402 
	C116.461861,440.643402 116.778580,440.634613 116.866577,439.838867 
	C115.132729,435.773651 114.512711,435.495636 111.402374,437.650452 
	C110.226273,436.793640 109.050179,435.936798 107.894447,434.391510 
	C106.255501,432.938202 104.616562,431.484894 102.696533,429.389893 
	C102.248093,428.319061 101.799652,427.248199 102.229637,426.086395 
	C103.484177,425.650177 104.738708,425.213989 105.993248,424.777771 
	C105.761986,424.202484 105.530716,423.627197 105.299454,423.051910 
	C103.733070,423.769623 102.166687,424.487305 100.144920,425.223022 
	C100.144920,425.223022 99.703712,425.108887 99.900139,424.368988 
	C99.863808,423.607483 99.827477,422.845978 100.321274,421.746613 
	C99.802711,421.198151 99.284149,420.649719 98.236290,419.659821 
	C97.761993,418.131470 97.287704,416.603149 97.383598,414.693695 
	C96.892059,413.800934 96.400528,412.908173 95.690529,411.288391 
	C95.404991,409.535034 95.119461,407.781677 95.444122,405.560181 
	C96.306274,402.713440 96.832329,400.020782 92.489281,399.284332 
	C88.950951,405.849182 90.083138,412.830383 90.220467,419.658203 
	C90.229805,420.122620 91.692650,420.557770 92.643585,421.647827 
	C92.713112,422.083740 92.782639,422.519653 92.417923,423.486877 
	C92.333817,424.637177 92.249710,425.787445 91.585518,427.297546 
	C88.889877,430.691376 91.174911,431.487335 94.172279,432.623505 
	C94.170662,433.058228 94.169044,433.492950 93.553535,434.409332 
	C92.064926,437.998230 93.483604,439.322784 97.353065,438.763733 
	C97.353065,438.763733 97.675354,438.750122 97.320595,439.421570 
	C97.270630,439.924042 97.220665,440.426544 96.592865,441.326233 
	C95.502251,445.113800 98.012878,445.676971 101.239265,446.421509 
	C101.773674,446.706665 102.308083,446.991821 102.918022,447.928467 
	C103.030052,448.573792 103.142090,449.219147 102.652901,450.235718 
	C102.102135,451.490784 101.551369,452.745850 100.302795,453.930054 
	C96.263268,448.348206 90.875122,443.265930 88.569527,437.041779 
	C86.334679,431.008636 87.411819,423.786835 86.709335,417.121307 
	C86.531494,415.433960 85.199356,413.817657 84.153458,412.341766 
	C84.034004,412.173157 81.925941,412.857483 81.431099,413.604309 
	C78.840378,417.514130 79.858803,430.981689 82.881004,435.735565 
	C82.888191,436.493683 82.895378,437.251831 82.397942,438.497986 
	C82.651787,446.671753 87.302063,453.033630 91.307350,459.610870 
	C91.836586,460.479980 93.762985,460.498260 95.642693,461.097656 
	C96.085152,461.761200 96.527618,462.424744 96.987320,463.808655 
	C101.333511,472.313904 108.684990,476.640320 118.124367,478.493286 
	C118.470222,478.477173 118.767975,478.588989 119.084503,479.547516 
	C122.558548,481.267670 124.546165,487.970978 130.737885,482.792053 
	C131.152496,482.947296 131.567108,483.102539 132.024200,484.009369 
	C133.934372,487.629059 136.508545,486.084137 139.793350,484.856659 
	C140.526657,485.031921 141.259949,485.207214 142.198853,486.042236 
	C145.352463,487.993713 148.563171,489.233856 152.930878,486.733643 
	C154.953552,486.869354 156.976212,487.005096 159.207001,487.905945 
	C166.171982,488.148499 173.136536,488.411316 180.103516,488.561615 
	C180.389267,488.567780 180.697830,487.516876 181.712814,486.778046 
	C182.448883,486.785278 183.184952,486.792511 184.326584,487.390594 
	C185.550751,486.952545 186.774918,486.514465 188.791168,485.850250 
	C190.159866,485.811920 191.528564,485.773621 193.296875,486.344727 
	C194.530807,485.910370 195.764740,485.475983 197.624466,484.827515 
	C198.062454,484.833344 198.500458,484.839203 199.352402,485.424194 
	C200.568115,484.964386 201.783829,484.504578 203.699234,483.797699 
	C204.436874,483.795044 205.174530,483.792389 206.380173,484.421265 
	C210.380203,483.858643 214.447540,483.576080 218.356873,482.636230 
	C221.020767,481.995819 223.463699,480.436249 226.627487,479.028931 
	C227.071823,478.985504 227.516159,478.942078 228.336304,479.441162 
	C229.224243,478.973785 230.112167,478.506409 231.600327,477.614838 
	C233.035400,477.329498 234.470474,477.044189 236.301666,477.348389 
	C241.192810,476.528442 246.083954,475.708466 251.120590,474.864136 
	C252.671890,473.947052 254.561005,472.906250 256.366913,471.736877 
	C257.635712,470.915283 258.791199,469.918671 260.572510,468.589294 
	C262.354187,467.987244 264.135895,467.385162 266.288086,467.349213 
	C268.524445,466.250000 270.760834,465.150787 273.603882,463.664581 
	C276.066376,462.769287 278.528870,461.873993 281.465179,461.534027 
	C284.879669,462.639465 287.224884,461.770935 288.498444,457.590912 
	C289.297943,457.311035 290.097412,457.031158 291.337799,457.375793 
	C295.673920,459.058929 297.773376,456.808136 299.531006,452.591248 
	C301.010468,452.034698 302.489929,451.478119 304.298340,451.572327 
	C305.880341,451.795776 308.095306,452.778625 308.918854,452.092194 
	C310.931183,450.414734 312.299957,447.965240 314.363281,445.322571 
	C316.639984,443.968170 318.916687,442.613800 321.869659,441.161133 
	C322.533875,441.002167 323.198090,440.843201 324.242676,441.294830 
	C326.493805,439.861877 328.744934,438.428955 331.390656,436.705994 
	C331.390656,436.705994 331.866058,436.584015 331.866058,436.584015 
	C331.866058,436.584015 332.339539,436.713318 333.153961,436.846924 
	C334.397095,435.801422 335.640259,434.755920 337.239899,433.248077 
	C337.786316,433.068298 338.332703,432.888550 339.224487,433.282898 
	C340.121399,432.458221 341.018311,431.633575 342.341492,430.292755 
	C345.533539,428.458374 348.725616,426.624023 352.308258,425.378937 
	C355.917847,425.897339 358.064728,424.323303 358.985504,420.865112 
	C358.985504,420.865112 358.839569,420.852081 359.597229,420.942505 
	C360.391022,420.226837 361.184814,419.511169 362.180634,418.651093 
	C362.180634,418.651093 362.398712,418.769897 363.163025,418.855896 
	C363.754944,418.212036 364.346863,417.568176 365.002441,416.980011 
	C365.002441,416.980011 364.937408,416.934143 365.673370,416.976227 
	C366.427002,416.265594 367.180634,415.554932 368.252289,414.371765 
	C368.960358,414.165039 369.668427,413.958313 371.165710,413.860779 
	C372.095154,412.877930 373.024567,411.895050 374.244171,410.452118 
	C374.743286,410.262268 375.242432,410.072449 376.521484,409.951660 
	C377.314758,409.221222 378.108002,408.490784 379.211487,407.219574 
	C380.113647,406.750549 381.015778,406.281525 382.322723,406.398315 
	C383.229034,406.381836 384.404785,406.727936 384.996552,406.288177 
	C388.097443,403.983673 391.128540,401.568390 394.021057,399.007660 
	C395.424530,397.765167 396.471954,396.120483 397.853088,394.711700 
	C397.853088,394.711700 397.688568,394.674133 398.474243,394.874359 
	C399.304932,393.896423 400.135620,392.918488 401.463318,391.347534 
	C403.280609,391.165649 405.097931,390.983795 407.449371,391.459991 
	C415.023743,393.535461 421.515503,389.637482 428.145538,387.293365 
	C431.428192,386.132721 434.062653,383.138794 437.238129,380.412659 
	C437.826141,380.275757 438.414124,380.138824 439.376709,380.492920 
	C440.242096,379.639801 441.107483,378.786682 442.237701,377.387421 
	C442.818329,377.240448 443.398987,377.093475 444.380646,377.492188 
	C445.254181,377.661041 446.127686,377.829926 447.013153,378.682373 
	C447.336395,379.292084 447.659668,379.901794 447.982941,380.511505 
	C448.263458,380.216034 448.543976,379.920563 448.824493,379.625092 
	C448.215912,379.083801 447.607361,378.542511 446.984406,377.107544 
	C446.748322,376.255280 446.512238,375.403046 446.283020,374.463837 
	C446.283020,374.463837 446.311340,374.381378 447.083405,374.672821 
	C448.830322,374.007202 451.174957,373.837616 452.214569,372.584961 
	C455.635895,368.462524 458.731171,364.041016 461.609039,359.516479 
	C462.310120,358.414276 461.700470,356.478394 461.853241,354.229370 
	C462.614410,352.489685 463.375610,350.750000 464.820007,348.873993 
	C468.876495,344.886383 469.678436,339.983978 469.088867,334.110565 
	C469.961090,333.366486 470.833313,332.622375 472.430878,331.910004 
	C473.312256,330.963470 474.193604,330.016907 475.830078,329.018158 
	C480.358124,328.554016 480.822418,325.274567 481.130280,321.133759 
	C482.491974,319.529663 483.853638,317.925598 485.847107,316.171265 
	C487.169403,315.088928 488.491669,314.006592 490.609314,312.972595 
	C495.347046,312.466614 496.598358,309.085571 497.901520,305.038300 
	C503.573822,304.676361 504.364807,300.409943 505.755219,295.945557 
	C508.981628,294.112091 511.683441,291.956635 510.871490,287.164795 
	C511.019073,286.885040 511.121704,286.589691 511.702881,286.216919 
	C512.029541,286.092682 512.356140,285.968475 513.457458,285.923248 
	C514.928711,283.852173 516.400024,281.781128 517.962524,279.128021 
	C518.567261,278.387573 519.171997,277.647095 520.518982,276.913605 
	C521.375183,275.640015 522.231384,274.366455 523.740601,272.921539 
	C524.186401,271.974518 524.632202,271.027496 525.776245,269.880798 
	C526.134277,268.827332 526.492249,267.773865 526.909546,266.135681 
	C526.918030,265.748383 526.926514,265.361115 527.182007,264.725250 
	C527.182007,264.725250 527.267212,264.385345 527.778503,264.267975 
	C528.100647,264.138306 528.422791,264.008636 529.462341,263.899139 
	C530.276489,262.255554 531.090637,260.611938 531.969421,258.336365 
	C532.014038,257.918121 532.058655,257.499908 532.015381,256.992493 
	C532.015381,256.992493 532.090698,257.091003 532.734314,256.847595 
	C534.820374,253.230698 536.906433,249.613800 539.228333,245.751053 
	C539.228333,245.751053 539.289185,245.415894 539.809937,245.308228 
	C540.135559,245.172852 540.461121,245.037491 541.556030,244.857147 
	C541.986694,243.781860 542.417358,242.706589 542.861816,241.005646 
	C543.452332,239.954590 544.042847,238.903534 545.366028,237.994080 
	C546.922058,239.017227 548.478149,240.040359 550.034180,241.063492 
	C550.254700,240.774261 550.475159,240.485046 550.695618,240.195816 
	C549.366089,238.077393 548.036499,235.958969 546.787048,233.282608 
	C547.073853,232.807953 547.360657,232.333298 548.418762,231.895218 
	C552.157532,230.327103 553.581055,227.791656 551.826416,223.170410 
	C552.871338,220.773651 553.916199,218.376892 555.668396,215.894318 
	C558.192078,213.051331 560.765930,210.224518 558.904785,205.315628 
	C559.259216,204.542145 559.613647,203.768661 560.679138,202.893265 
	C563.141663,200.309448 565.496216,197.700439 562.780029,193.179840 
	C563.157776,192.066879 563.535461,190.953918 564.321411,190.417694 
	C565.819763,189.616531 567.318115,188.815369 568.816467,188.014206 
	C567.645813,186.645477 566.475098,185.276733 564.911438,183.923325 
	C564.911438,183.923325 564.544067,184.063904 564.772827,183.375290 
	C564.925293,182.919098 565.077820,182.462921 565.906372,181.850616 
	C566.224060,179.550278 566.541748,177.249954 566.801697,174.121201 
	C566.978821,172.750336 567.155945,171.379456 568.029968,169.869873 
	C568.680176,168.671097 569.907715,167.459778 569.881958,166.275681 
	C569.762817,160.787384 569.902771,155.170303 568.713623,149.877045 
	C568.056824,146.953293 570.003174,142.508224 565.078308,140.363525 
	C565.002808,139.701477 564.927368,139.039444 564.946594,137.490311 
	C564.763733,134.640442 565.651245,131.322418 561.275757,129.737411 
	C561.100647,129.191788 560.925537,128.646164 561.286926,127.777542 
	C560.799194,127.228897 560.311462,126.680244 559.856628,126.013031 
	C559.856628,126.013031 559.866577,126.135643 559.953979,125.405861 
	C559.299500,124.606178 558.645020,123.806488 557.462036,122.667580 
	C556.740601,121.415382 556.019104,120.163193 555.726013,118.371788 
	C556.678589,114.350906 554.201965,113.887016 550.902283,113.759445 
	C550.902283,113.759445 550.827271,113.407204 550.981079,112.598473 
	C551.273865,111.256851 551.566589,109.915237 552.117493,107.390312 
	C549.624939,108.297852 548.234985,108.803932 546.224121,109.163795 
	C545.105652,108.215973 543.987244,107.268150 542.889709,105.530823 
	C541.990112,103.166862 541.090515,100.802902 540.232910,98.549347 
	C538.614136,98.888702 535.912048,99.687943 535.812744,99.440887 
	C534.448059,96.046066 531.922363,98.265602 529.287903,97.959404 
	C528.862427,97.799507 528.437012,97.639618 528.011902,96.716949 
	C528.226807,93.657928 527.208557,92.235161 523.944214,93.507011 
	C522.733032,93.978920 521.420593,94.191200 519.599731,94.657005 
	C519.221375,94.613419 518.843018,94.569824 518.226807,94.037552 
	C517.817017,93.678368 517.407227,93.319183 516.910400,92.325783 
	C516.205078,92.394943 515.499695,92.464111 514.075073,92.570633 
	C513.381165,92.400284 512.687317,92.229927 511.946747,91.477440 
	C511.673889,91.125053 511.401001,90.772659 511.128113,90.420273 
	C510.723846,90.823380 510.319580,91.226486 509.603149,92.008461 
	C508.750336,91.919044 507.897552,91.829628 507.117615,91.275528 
	C507.117615,91.275528 507.051514,90.809845 506.933563,90.243507 
	C506.266693,90.222466 505.599854,90.201424 504.282013,90.200691 
	C503.852753,90.142609 503.423523,90.084518 502.883484,89.322678 
	C501.558777,89.294083 500.234070,89.265488 498.150421,89.265755 
	C497.144775,89.314156 496.139160,89.362564 494.237610,89.144920 
	C491.494843,88.964294 488.752106,88.783669 485.802765,87.982063 
	C484.198059,88.198425 482.593353,88.414787 480.113647,88.807121 
	C477.437592,88.885475 474.761536,88.963837 471.166962,89.001617 
	C467.958435,89.653030 464.090149,87.432632 461.681854,92.184090 
	C464.773376,91.810074 467.864899,91.436058 471.715729,91.002586 
	C472.470154,91.086159 473.224609,91.169739 474.107208,91.933807 
	C477.362518,95.908821 480.689178,92.463554 484.826385,91.987938 
	C486.213593,92.120544 487.600769,92.253151 489.202026,93.022278 
	C491.783966,93.519936 494.216064,96.918808 497.706604,93.298401 
	C498.799408,93.556564 499.892212,93.814720 501.121765,94.778633 
	C504.055969,97.278580 507.199860,98.773308 511.698578,96.866302 
	C512.468445,97.219009 513.238281,97.571709 514.099426,98.638611 
	C518.003357,100.659897 521.899414,102.697304 525.843689,104.636581 
	C525.984558,104.705841 526.602539,103.804710 527.709839,103.511894 
	C527.906067,103.655014 528.102234,103.798141 528.284668,103.900719 
	C528.284668,103.900719 528.239624,103.882736 528.716187,104.281090 
	C528.835815,104.450066 528.955444,104.619041 529.032959,105.632675 
	C528.850586,110.751411 532.700806,108.497620 535.055664,108.953987 
	C535.055664,108.953987 535.076111,108.919769 535.012634,109.671616 
	C534.425537,113.105988 532.453186,114.960236 528.437683,114.661476 
	C527.605774,114.146667 526.773865,113.631859 525.979919,112.283379 
	C525.486023,110.898132 524.992188,109.512878 524.498291,108.127609 
	C523.325012,108.778435 522.151794,109.429260 520.387939,110.059105 
	C519.897461,109.767090 519.406982,109.475075 518.967102,108.368927 
	C518.500061,107.298370 518.033081,106.227814 517.566101,105.157249 
	C516.698853,105.831314 515.831604,106.505379 514.232239,107.097618 
	C511.483154,106.096420 508.734100,105.095222 505.908203,103.409943 
	C504.911896,103.341431 503.915619,103.272919 502.174866,103.181511 
	C501.445557,103.040665 500.716278,102.899826 499.836060,102.090141 
	C497.774353,99.898346 495.558441,98.904022 492.089874,101.208054 
	C490.723877,101.037109 489.357880,100.866165 487.889130,100.149689 
	C487.231689,100.173622 486.574249,100.197556 485.051392,100.244987 
	C483.030182,100.161949 481.009003,100.078911 478.895386,99.330818 
	C477.933899,99.548569 476.972443,99.766312 475.087067,100.101372 
	C470.748810,100.131157 466.410553,100.160950 461.789307,99.696083 
	C461.195679,100.021774 460.602081,100.347458 459.181763,100.888596 
	C457.474915,100.977631 455.768036,101.066666 453.609375,100.560608 
	C452.076233,101.037613 450.543060,101.514610 448.211182,102.179077 
	C445.491974,102.498947 442.772766,102.818810 439.595490,102.545883 
	C438.068359,103.063812 436.541260,103.581741 434.315643,104.288277 
	C432.901794,104.585960 431.487946,104.883636 429.609314,104.568069 
	C427.635956,103.960632 425.480347,102.898727 424.642487,106.783630 
	C423.792908,106.937202 422.943329,107.090775 421.729187,106.633118 
	C419.626404,105.045029 417.853271,104.253494 417.423645,108.496849 
	C416.313477,108.740173 415.203339,108.983498 413.709900,108.639870 
	C412.813385,109.151863 411.916901,109.663857 410.326691,110.389069 
	C406.569244,111.642189 402.811768,112.895309 398.754578,113.658249 
	C398.173218,114.036583 397.591827,114.414917 396.364441,115.070610 
	C395.255005,115.415970 394.145569,115.761337 392.730469,115.627617 
	C392.156799,116.023041 391.583130,116.418457 390.391785,117.039650 
	C389.958649,117.100121 389.525543,117.160599 388.710815,116.637512 
	C387.477417,117.384544 386.244049,118.131584 384.346039,119.129684 
	C382.582001,119.799957 380.817993,120.470230 378.628754,120.554825 
	C372.978424,118.407692 371.976257,118.843758 369.765106,124.396011 
	C369.765106,124.396011 369.534821,124.204247 368.795837,124.136444 
	C368.201447,124.743492 367.607086,125.350540 366.357300,126.206009 
	C364.249176,127.169434 362.141083,128.132858 359.696106,128.605179 
	C359.136047,129.104446 358.576019,129.603714 357.476562,130.430695 
	C356.690735,130.715164 355.904877,130.999634 354.791260,130.733566 
	C354.202332,131.220444 353.613434,131.707336 352.489227,132.512848 
	C351.705536,132.773972 350.921875,133.035110 349.795990,132.742523 
	C349.197906,133.444656 348.599823,134.146805 347.400909,135.251038 
	C341.953156,137.932159 336.505432,140.613281 330.430725,143.208511 
	C329.886719,143.766006 329.342712,144.323502 328.169495,144.999329 
	C327.106354,145.657150 326.043182,146.314987 324.321594,146.968094 
	C323.883148,146.992188 323.444733,147.016281 322.672089,146.572083 
	C322.119812,147.050720 321.567535,147.529343 320.619171,148.550781 
	C318.772156,149.428360 316.925171,150.305923 314.706940,150.632706 
	C313.144745,151.793900 311.582581,152.955078 309.558411,154.495819 
	C308.701385,155.322968 307.844360,156.150101 306.270416,157.052948 
	C300.175964,160.363602 294.081512,163.674271 287.608948,166.509750 
	C286.742340,167.343811 285.875732,168.177887 284.727905,169.585556 
	C283.705200,170.681915 282.682465,171.778259 281.369385,172.878174 
	C281.369385,172.878174 281.073090,172.903931 280.386780,173.027466 
	C279.869690,173.953781 279.352600,174.880096 278.199554,175.829407 
	C277.770294,175.854202 277.341003,175.879013 276.207794,176.003662 
	C272.477905,178.342010 268.748016,180.680374 264.321136,183.076736 
	C262.873413,184.308136 261.425690,185.539520 259.977936,186.770905 
	C260.433441,187.300095 260.888947,187.829285 261.344452,188.358459 
	C262.556671,187.260590 263.768921,186.162704 265.696991,184.959091 
	C268.132294,183.966476 270.567566,182.973862 273.389709,182.477905 
	C278.338562,181.324020 278.950775,180.598679 277.117432,177.588608 
	C277.117432,177.588608 277.032715,177.181641 277.627472,177.065948 
	C278.121765,176.755966 278.616089,176.445984 279.702118,176.088181 
	C280.191742,175.785812 280.681396,175.483444 281.716400,175.071320 
	C282.148773,174.756256 282.581116,174.441193 283.646576,174.040894 
	C284.071045,174.010605 284.495514,173.980316 285.641754,173.973770 
	C286.427734,173.318039 287.213745,172.662292 288.000000,171.999664 
	C288.000000,171.999664 288.006073,171.999939 288.765381,171.997589 
	C290.178558,171.016022 291.591736,170.034470 293.304291,168.416397 
	C297.896240,165.439835 302.488190,162.463257 307.852264,159.290924 
	C308.902344,159.187637 309.952454,159.084351 311.345062,159.446121 
	C311.891235,158.940918 312.437408,158.435699 313.524353,157.819016 
	C313.891357,157.823837 314.258362,157.828659 315.374359,157.872299 
	C315.883423,157.177017 316.392487,156.481750 317.291565,155.348480 
	C317.849579,155.198868 318.407593,155.049271 319.309753,155.394760 
	C319.845001,154.870499 320.380249,154.346237 321.293030,153.369720 
	C321.853119,153.218094 322.413208,153.066467 323.312653,153.402679 
	C323.855255,152.895828 324.397888,152.388977 325.293182,151.412674 
	C325.845734,151.242035 326.398254,151.071396 327.360962,151.446411 
	C328.239319,150.687851 329.117676,149.929306 330.584808,148.772034 
	C341.693237,144.107483 352.801697,139.442932 364.238831,135.308716 
	C364.819122,134.822433 365.399414,134.336151 366.587860,133.689407 
	C367.211334,133.721832 367.834808,133.754272 369.301880,133.902130 
	C370.864227,132.984604 372.426575,132.067062 374.714020,130.918350 
	C377.124542,130.230286 379.535095,129.542221 382.266479,129.354797 
	C382.839844,128.903473 383.413208,128.452148 384.673462,127.753357 
	C386.097229,127.454750 387.520996,127.156136 389.388092,127.445312 
	C393.588409,129.817551 395.895721,127.259285 398.616302,123.936150 
	C399.053650,123.895744 399.491028,123.855331 400.295502,124.376457 
	C401.192719,123.947479 402.089966,123.518494 403.617065,122.906021 
	C404.051971,122.872078 404.486908,122.838142 405.348053,123.404320 
	C407.560242,122.676956 409.772430,121.949600 412.748779,121.017242 
	C413.798950,120.931267 414.849152,120.845291 416.360352,121.390976 
	C418.902954,120.678642 421.445526,119.966309 424.865631,119.052795 
	C427.555359,118.981834 430.245087,118.910881 433.496948,119.492226 
	C439.696838,119.251419 445.896729,119.010612 452.965973,118.740128 
	C456.312958,119.181717 459.659943,119.623306 463.155487,120.784401 
	C465.618225,121.451775 468.080994,122.119148 471.294922,122.667198 
	C477.632721,124.337807 483.901489,126.136597 488.040466,132.515289 
	C488.239594,134.910782 488.438751,137.306290 488.670807,140.097977 
	C491.373810,139.021683 492.829376,138.442108 494.855560,138.222794 
	C496.300293,140.790009 497.745026,143.357224 498.615814,146.311432 
	C499.136047,147.200653 499.656281,148.089890 500.409302,149.659653 
	C501.330078,152.739578 502.250854,155.819489 502.541779,159.432205 
	C502.760498,164.993866 502.979187,170.555511 503.216278,176.902145 
	C503.091797,177.938446 502.967316,178.974762 502.272919,180.088730 
	C502.239868,180.747482 502.206848,181.406235 502.169403,182.859634 
	C501.500946,185.913666 500.832489,188.967697 499.521057,192.065933 
	C499.434509,192.745728 499.347961,193.425537 499.238068,194.895737 
	C497.527222,199.614136 495.816376,204.332535 493.490448,209.075684 
	C493.364685,209.730347 493.238922,210.385025 493.045074,211.764557 
	C491.039490,215.847839 489.033936,219.931122 486.300995,224.017349 
	C483.924927,224.343033 483.227295,225.251541 485.131622,227.750992 
	C484.466766,228.854706 483.801910,229.958435 482.384888,231.129944 
	C481.969635,232.188950 481.554382,233.247955 481.093567,234.841080 
	C480.777069,235.249741 480.460571,235.658401 479.455170,236.077789 
	C479.049225,237.085907 478.643311,238.094009 478.112244,239.798538 
	C476.052002,242.861740 473.991760,245.924927 471.126801,248.999313 
	C467.573700,248.765274 467.911011,251.122772 468.196198,254.039368 
	C467.812714,254.703232 467.429199,255.367096 466.358398,256.017334 
	C466.002930,256.722717 465.647461,257.428131 465.104889,258.814178 
	C461.107025,263.903534 457.109192,268.992889 452.429962,273.768158 
	C451.135193,273.550659 449.840424,273.333130 448.380157,273.087830 
	C448.664062,275.593414 448.864227,277.359985 448.843628,279.681793 
	C447.980774,280.490784 447.117920,281.299805 445.514618,282.119415 
	C445.014709,283.081268 444.514771,284.043121 443.765564,285.627380 
	C441.166168,288.418518 438.566772,291.209656 435.219177,294.010040 
	C431.866760,294.709717 429.462891,296.195251 429.797363,300.783875 
	C423.899567,306.540894 418.001770,312.297913 411.346588,318.031494 
	C410.563324,319.010712 409.780060,319.989899 408.913757,321.564514 
	C408.853363,321.959229 408.792969,322.353943 408.237823,323.076050 
	C407.814880,323.711517 407.391968,324.346985 406.386841,325.043365 
	C405.971039,325.433014 405.555237,325.822693 404.483398,326.096527 
	C403.351715,326.753815 402.220001,327.411102 400.428009,327.765472 
	C399.154785,327.523102 397.881531,327.280701 395.414520,326.811066 
	C396.712280,328.939453 397.329987,329.952515 397.902863,331.575592 
	C397.858673,331.978699 397.814453,332.381836 397.435303,332.967590 
	C397.435303,332.967590 397.135254,333.203186 396.464630,333.072266 
	C396.013275,333.125702 395.561920,333.179108 394.673157,332.620880 
	C390.050323,331.286041 389.207062,333.062317 390.675293,337.600403 
	C385.815979,341.475037 380.956665,345.349670 375.719849,348.655609 
	C372.413483,348.333527 370.799500,349.762756 370.806458,353.603821 
	C370.336243,353.811493 369.866028,354.019135 368.588257,354.100708 
	C364.408386,352.491455 363.854675,350.910583 366.711670,347.874756 
	C368.155457,346.340546 369.958527,345.106628 371.720978,343.917572 
	C376.030029,341.010559 378.455627,336.199280 377.363922,332.920227 
	C376.704834,330.940643 376.134216,328.839233 376.063538,326.774078 
	C375.881226,321.450531 376.164032,316.109772 375.941345,310.789246 
	C375.782196,306.984863 374.326569,303.980713 369.706390,303.993561 
	C358.047638,304.025970 346.385712,303.876770 334.733704,304.158966 
	C333.235504,304.195251 330.587555,306.281860 330.509399,307.544739 
	C329.917847,317.102783 329.358337,326.712341 329.759125,336.265106 
	C330.030945,342.743469 331.076172,349.452087 333.315887,355.485962 
	C334.643982,359.063934 338.808136,363.743774 341.977203,363.967926 
	C346.484924,364.286743 347.430450,366.667694 348.777954,369.859222 
	C345.346619,371.967682 341.915253,374.076111 337.705475,376.139526 
	C337.177124,376.855194 336.648804,377.570892 335.748413,378.834473 
	C328.912292,383.260284 322.076172,387.686096 314.384247,392.040741 
	C311.479004,391.863770 308.717194,391.879913 308.618469,396.560577 
	C306.916565,397.104736 305.214691,397.648895 302.735107,398.140930 
	C302.191986,398.843506 301.648865,399.546082 300.728668,400.818756 
	C294.180267,404.265381 287.631866,407.712036 280.740631,410.654480 
	C280.158386,411.314148 279.576111,411.973816 278.537567,413.065857 
	C278.040253,413.449768 277.542938,413.833679 276.460541,414.101471 
	C276.066071,414.120758 275.671600,414.140045 274.553589,414.029572 
	C270.352325,412.390564 268.534790,414.982971 266.695343,418.682617 
	C266.070099,419.399139 265.444855,420.115692 264.181122,420.950317 
	C263.539795,421.011017 262.898468,421.071716 261.582886,420.838379 
	C257.867249,421.239349 253.440735,420.212769 252.181534,425.960541 
	C252.048508,426.219452 251.943665,426.489319 251.087341,426.722748 
	C250.084869,426.983521 249.082397,427.244324 247.482315,427.308990 
	C246.877960,427.806885 246.273605,428.304749 245.002090,428.849976 
	C244.367371,429.047852 243.732666,429.245758 242.441666,429.216614 
	C241.981323,429.207245 241.520981,429.197876 240.654755,428.602539 
	C238.110046,429.802673 235.565323,431.002808 232.363541,432.418457 
	C229.946564,433.347870 227.529587,434.277283 224.794098,434.702728 
	C224.188354,435.249512 223.582611,435.796265 222.564499,436.741241 
	C222.005173,437.226593 221.445831,437.711945 220.112320,438.354034 
	C219.098495,438.636383 218.084686,438.918732 216.467880,439.012543 
	C215.976593,439.317963 215.485321,439.623383 214.483215,440.000732 
	C214.332321,440.083160 214.181412,440.165619 213.501602,440.124939 
	C213.246536,440.360962 212.991486,440.596985 211.982758,440.815125 
	C210.986938,440.806824 209.991135,440.798492 208.892059,440.220123 
	C208.286667,440.525940 207.681274,440.831757 207.280380,441.401245 
M448.572906,265.943939 
	C452.484436,264.069214 455.040771,261.278961 454.857178,256.239990 
	C454.966888,255.956009 455.173950,255.812088 456.255219,255.951569 
	C457.211273,256.187592 458.167328,256.423584 458.757416,256.569275 
	C462.479675,251.648926 466.055267,246.922516 469.373901,242.535706 
	C468.909180,240.815903 468.210419,239.442215 468.237579,238.083008 
	C468.297577,235.077393 468.814636,232.084015 468.975739,229.076553 
	C469.179993,225.263138 468.661438,221.319382 469.463623,217.652573 
	C470.491180,212.955566 468.356232,209.059845 463.463776,209.040283 
	C441.969147,208.954361 420.473724,208.944412 398.979218,209.043839 
	C393.902130,209.067322 392.018433,211.331085 392.005341,216.606094 
	C391.981323,226.270447 392.317963,235.949097 391.890045,245.594528 
	C391.581879,252.541595 395.240295,255.177002 401.995605,253.746414 
	C408.055603,252.463089 413.839020,252.722839 419.844788,255.651947 
	C419.758728,256.097900 419.672638,256.543884 418.871094,257.263123 
	C418.590729,257.832092 418.068512,258.399872 418.065704,258.970215 
	C418.010651,270.266357 417.957397,281.563293 418.047729,292.858917 
	C418.071716,295.857361 421.080750,297.425629 423.588409,295.394073 
	C425.538147,293.814484 426.500854,291.016602 427.979767,288.305847 
	C428.154602,288.005249 428.415070,287.872009 429.493530,287.920929 
	C437.681671,284.044312 441.117859,275.578613 447.091492,269.559357 
	C447.841431,268.803741 447.617249,267.081390 447.893433,265.935852 
	C447.893433,265.935852 447.786804,265.848358 448.572906,265.943939 
M309.777954,366.013977 
	C310.847687,365.476105 312.513947,365.204987 312.892670,364.358124 
	C315.896545,357.641144 318.740936,350.847809 321.403473,343.988281 
	C321.834534,342.877808 321.123871,341.324188 320.766907,339.235046 
	C320.879578,338.491425 320.992279,337.747803 321.880310,336.803284 
	C322.129822,336.593109 322.584778,336.396301 322.599426,336.170868 
	C323.361694,324.440247 324.283905,312.714722 324.730682,300.971191 
	C324.948181,295.255249 324.178436,289.507843 324.016632,283.770111 
	C323.674622,271.645569 323.258606,259.518616 323.219238,247.391541 
	C323.190735,238.598495 323.867462,229.803635 323.867889,221.009583 
	C323.867981,219.335968 322.174866,217.662262 321.022461,215.273376 
	C320.981689,214.272018 320.940948,213.270645 320.872742,211.477051 
	C318.513184,210.651382 316.171051,209.176987 313.791016,209.113022 
	C303.812836,208.844864 293.816437,208.788162 283.841522,209.111191 
	C280.268127,209.226913 276.736511,210.633041 273.847015,211.298050 
	C274.309143,215.999008 274.946289,219.518280 274.955505,223.039215 
	C275.034729,253.244186 275.051117,283.449615 274.967041,313.654541 
	C274.943787,322.007446 274.520599,330.359222 274.368500,339.192932 
	C274.315948,339.539368 274.139984,339.803619 273.127045,339.993591 
	C271.421844,341.712555 269.716614,343.431519 267.869019,345.290741 
	C267.869019,345.290741 267.673279,345.250275 266.757080,345.103180 
	C263.552765,345.382965 259.306976,344.474060 257.355591,346.183807 
	C252.958038,350.036835 248.609879,345.884216 244.219070,347.100647 
	C242.686844,347.525116 238.789307,344.270447 235.223404,344.787292 
	C234.496750,344.892609 233.286346,343.989258 232.826569,343.230957 
	C229.729767,338.123566 225.958801,333.339478 225.969391,326.845978 
	C226.029205,290.193939 226.004196,253.541687 225.992523,216.889511 
	C225.990433,210.323776 224.675644,209.015839 218.124878,209.003876 
	C207.520432,208.984482 196.912598,208.854233 186.313492,209.093506 
	C183.304947,209.161453 180.321686,210.349152 178.000748,210.871613 
	C178.000748,214.551697 178.000778,217.342926 178.000748,220.134171 
	C178.000214,256.786346 178.131226,293.439209 177.932281,330.090332 
	C177.856171,344.110107 183.181519,355.842377 190.479187,367.439178 
	C193.726654,372.599762 201.201065,373.869476 201.797806,380.975922 
	C201.825134,381.301147 203.480988,381.545471 204.398148,381.727875 
	C206.048172,382.056030 207.809921,382.052521 209.355591,382.636200 
	C216.773224,385.437225 224.030045,388.714874 231.568634,391.118683 
	C234.411835,392.025299 237.860443,391.033356 241.954895,390.748718 
	C247.963074,390.813995 253.971252,390.879272 260.480896,391.526459 
	C263.288818,391.886505 266.450775,393.520569 268.675385,389.014893 
	C269.439575,388.981232 270.203796,388.947540 271.461060,389.508148 
	C277.449005,389.879547 283.036926,388.637207 288.085358,385.313782 
	C292.963776,382.102295 297.494476,378.098785 302.775269,375.839264 
	C307.689178,373.736725 309.931885,371.202179 309.777954,366.013977 
M117.695732,570.466736 
	C122.979240,568.647766 126.114403,564.753479 128.020905,559.707031 
	C131.189819,551.319092 131.322510,542.711365 128.830170,534.250916 
	C126.892639,527.673828 123.283531,521.708191 115.819077,520.558289 
	C109.367256,519.564331 102.730797,519.768982 96.279221,519.448914 
	C96.279221,537.499146 96.279221,554.695374 96.279221,572.552979 
	C103.451569,571.945190 110.211563,571.372437 117.695732,570.466736 
M335.806335,557.430786 
	C333.380951,556.093384 330.955566,554.755981 328.309875,553.297119 
	C327.369812,555.168945 326.769623,556.311829 326.215607,557.476746 
	C324.452240,561.184509 321.820099,563.927124 317.474823,563.751343 
	C312.761200,563.560730 310.508301,560.180420 309.435059,556.084351 
	C307.453400,548.521423 307.340363,540.878235 310.102692,533.498230 
	C312.438049,527.258972 319.816711,525.791626 324.206543,530.548218 
	C325.614319,532.073547 326.479553,534.099670 327.737396,536.129639 
	C330.399719,535.361511 333.062164,534.593323 335.713806,533.828186 
	C333.307892,524.227661 327.824799,519.305298 319.329468,518.887512 
	C310.566223,518.456543 304.232208,522.694153 301.245636,531.490295 
	C298.231018,540.369141 298.074615,549.448914 300.673126,558.468018 
	C302.610504,565.192322 306.195740,570.552002 313.585846,572.267029 
	C323.350586,574.533203 330.895264,569.681091 335.806335,557.430786 
M229.225739,549.658875 
	C224.050140,550.922180 219.887314,553.373169 219.044479,559.164795 
	C218.352875,563.917358 219.410889,568.296631 223.757889,570.921753 
	C230.201767,574.813171 236.172562,572.809570 241.879684,568.523987 
	C243.782959,574.268738 248.189285,571.392517 251.300476,571.849854 
	C250.911499,567.237610 250.399048,562.849915 250.208633,558.448181 
	C250.000458,553.635803 250.225235,548.804688 250.015930,543.992371 
	C249.750275,537.884338 247.136642,534.731262 241.141129,533.875244 
	C237.586014,533.367676 233.866669,533.443481 230.283188,533.830872 
	C224.504684,534.455505 221.361481,538.135132 219.971527,543.866699 
	C223.826370,544.725769 226.968155,546.443604 230.391251,542.384949 
	C231.782822,540.735046 236.275574,541.218140 239.238556,541.615662 
	C240.410370,541.772949 241.259125,544.337891 242.665146,546.411438 
	C237.676453,547.576782 233.824478,548.476624 229.225739,549.658875 
M139.439423,539.123413 
	C138.841034,540.591003 138.242645,542.058594 137.502182,543.874634 
	C141.380875,544.558228 144.291122,546.543823 147.725296,542.655701 
	C149.291931,540.882019 153.882477,541.358032 157.027573,541.639404 
	C158.060577,541.731873 158.861053,544.423218 160.060501,546.411072 
	C156.400513,547.321533 153.827652,547.926758 151.274612,548.606384 
	C148.711380,549.288757 146.079132,549.825500 143.633560,550.813416 
	C137.885986,553.135193 135.605743,557.830139 136.872910,564.154602 
	C137.924042,569.400818 142.363449,573.283569 148.067825,572.766846 
	C152.027969,572.408142 155.858749,570.621399 159.952927,569.411926 
	C161.443222,574.521240 165.977783,571.262207 168.940384,571.796448 
	C168.604950,567.380127 168.154602,563.125549 167.991516,558.860046 
	C167.807037,554.034058 168.357864,549.128845 167.669250,544.391724 
	C167.229752,541.368347 165.853226,537.689636 163.620880,535.870605 
	C157.553391,530.926575 145.296295,532.675354 139.439423,539.123413 
M465.573425,549.164490 
	C465.724609,552.792908 465.425873,556.509766 466.117706,560.031982 
	C467.714172,568.159973 474.134460,572.938110 482.671906,572.927612 
	C490.453613,572.918152 495.633118,568.996277 497.498566,561.494812 
	C494.906677,561.044189 492.332428,560.596619 489.467468,560.098450 
	C487.648224,565.133972 483.760254,566.012024 479.369904,564.576111 
	C475.493317,563.308105 474.071320,560.126770 474.329468,555.767517 
	C482.391876,555.767517 490.152100,555.767517 497.859985,555.767517 
	C499.192719,545.319641 494.852478,536.529663 487.378143,534.120239 
	C476.798859,530.709900 468.170654,536.382446 465.573425,549.164490 
M345.528259,566.901001 
	C355.392365,577.080383 370.657623,574.163452 374.299683,560.887390 
	C375.671631,555.886414 375.357330,549.953796 374.165710,544.828613 
	C372.383575,537.163574 365.820343,533.056458 358.179932,533.191467 
	C350.444366,533.328125 344.198700,538.107544 342.203491,545.779175 
	C340.334595,552.964966 340.825897,559.932983 345.528259,566.901001 
M456.752899,571.099976 
	C456.536591,561.969238 456.923126,552.772766 455.937653,543.725830 
	C454.913574,534.324585 445.926666,530.450623 437.638550,535.108643 
	C436.236145,535.896912 434.984436,536.953186 433.236786,538.186462 
	C433.068359,536.647644 432.938965,535.465698 432.813507,534.319275 
	C430.138947,534.319275 427.726074,534.319275 425.261719,534.319275 
	C425.261719,546.982910 425.261719,559.372437 425.261719,571.696167 
	C428.081970,571.696167 430.630066,571.696167 433.767792,571.696167 
	C433.767792,564.407410 433.526855,557.431213 433.843109,550.480469 
	C434.101898,544.791931 437.488312,541.251587 441.750122,541.302673 
	C445.612579,541.348999 447.852295,544.415894 447.987518,550.484009 
	C448.145599,557.574829 448.024475,564.671814 448.024475,571.833923 
	C451.231201,571.833923 453.704834,571.833923 456.752899,571.099976 
M412.742981,537.890015 
	C406.840912,531.677795 401.023987,531.633240 392.378845,537.844910 
	C392.007874,536.539673 391.651276,535.285156 391.386261,534.352661 
	C388.670288,534.352661 386.415497,534.352661 384.224823,534.352661 
	C384.224823,547.070496 384.224823,559.472595 384.224823,571.830200 
	C387.003967,571.830200 389.461121,571.830200 391.872437,571.830200 
	C392.229156,563.833435 392.339142,556.014160 393.018036,548.244568 
	C393.398865,543.885742 396.492126,541.288940 400.863495,541.420532 
	C405.260223,541.553040 406.460663,545.084778 406.784943,548.784363 
	C407.046204,551.764771 407.009705,554.774658 407.032837,557.771973 
	C407.068481,562.394836 407.042206,567.018127 407.042206,571.770142 
	C410.061523,571.770142 412.629333,571.770142 415.594940,571.770142 
	C415.594940,564.726868 415.890533,557.910278 415.486664,551.135498 
	C415.234894,546.912537 413.926483,542.752502 412.742981,537.890015 
M531.984375,546.778931 
	C534.670471,546.273193 537.356567,545.767456 540.109802,545.249084 
	C536.739197,535.444458 529.640442,531.661499 519.450439,533.754456 
	C510.154907,535.663757 505.140289,543.860901 506.023956,555.701965 
	C506.764954,565.631470 512.907715,572.162476 522.160034,572.857849 
	C531.766357,573.579834 538.436462,568.601501 540.373352,559.064758 
	C537.648987,558.573181 534.932312,558.082947 532.235107,557.596252 
	C530.183350,562.621643 527.267334,565.660767 521.787781,564.462830 
	C516.616577,563.332214 515.102539,559.471008 514.875122,554.811951 
	C514.778259,552.828064 514.744141,550.782593 515.112061,548.846985 
	C515.850342,544.962952 518.012817,541.986877 522.102112,541.345947 
	C526.265320,540.693420 529.663269,542.090576 531.984375,546.778931 
M208.398788,571.206970 
	C208.509933,568.702942 208.621078,566.198975 208.737518,563.575684 
	C205.122269,563.972046 202.655258,564.386719 200.176315,564.478210 
	C197.015503,564.594849 195.085831,563.031616 195.050354,559.732727 
	C194.988281,553.959656 195.033051,548.185486 195.033051,542.002014 
	C198.914108,542.002014 202.333572,542.002014 205.594116,542.002014 
	C205.594116,539.171265 205.594116,536.774658 205.594116,534.067139 
	C201.943222,534.067139 198.671326,534.067139 195.007172,534.067139 
	C195.007172,529.681396 195.007172,525.608704 195.007172,521.458679 
	C187.995010,523.041382 185.040146,527.168274 186.449722,534.324890 
	C183.278168,534.324890 180.851013,534.324890 178.372437,534.324890 
	C178.372437,537.144165 178.372437,539.535461 178.372437,541.998779 
	C181.114914,542.130493 183.556824,542.247742 186.405151,542.384521 
	C186.405151,546.670227 186.404068,550.647949 186.405350,554.625732 
	C186.410538,570.736389 190.185379,574.025146 206.231216,571.880981 
	C206.718781,571.815857 207.186111,571.599243 208.398788,571.206970 
M577.949707,571.568054 
	C582.590271,569.100159 578.005859,566.328369 578.708923,563.659729 
	C575.739075,563.986389 573.275574,564.354248 570.798889,564.505554 
	C567.602478,564.700806 565.627258,563.154236 565.560364,559.890991 
	C565.442017,554.119263 565.527161,548.343262 565.527161,542.025635 
	C569.305237,542.025635 572.697205,542.025635 575.934998,542.025635 
	C575.934998,539.069458 575.934998,536.690308 575.934998,533.975891 
	C572.232544,533.975891 568.943848,533.975891 565.162476,533.975891 
	C565.162476,529.560059 565.162476,525.488770 565.162476,521.398254 
	C558.560730,523.394897 555.222839,527.181091 557.090698,533.992920 
	C553.946350,534.132080 551.515442,534.239685 548.988464,534.351501 
	C548.988464,537.078186 548.988464,539.479492 548.988464,542.276428 
	C551.710144,542.276428 554.136719,542.276428 556.955322,542.276428 
	C556.955322,546.296082 556.952881,549.926514 556.955688,553.557007 
	C556.969482,571.312134 559.529541,573.625122 577.949707,571.568054 
M535.211060,267.933167 
	C536.076782,267.566254 536.942505,267.199371 537.808228,266.832458 
	C537.537537,266.317017 537.266785,265.801575 536.996033,265.286133 
	C536.354126,265.938080 535.712219,266.590027 535.211060,267.933167 
M391.518402,407.311951 
	C390.975403,406.975464 390.432373,406.639008 389.889374,406.302521 
	C389.755432,406.574860 389.621521,406.847198 389.487610,407.119537 
	C390.019409,407.323303 390.551178,407.527069 391.518402,407.311951 
M387.280090,244.885300 
	C387.471039,245.425171 387.662018,245.965042 387.852997,246.504898 
	C388.129913,246.378448 388.406830,246.252014 388.683807,246.125565 
	C388.360077,245.569839 388.036346,245.014099 387.280090,244.885300 
M234.562515,338.293762 
	C234.292679,338.010986 234.022827,337.728180 233.752991,337.445404 
	C233.635910,337.557251 233.518829,337.669067 233.401749,337.780914 
	C233.671906,338.063721 233.942078,338.346497 234.562515,338.293762 
M482.199371,132.601517 
	C482.203674,132.211853 482.207947,131.822174 482.212219,131.432510 
	C482.050537,131.430939 481.748352,131.418488 481.747528,131.429367 
	C481.717499,131.817108 481.715607,132.207031 482.199371,132.601517 
M238.438782,421.706940 
	C238.708160,421.989319 238.977539,422.271729 239.246918,422.554108 
	C239.363815,422.442444 239.588486,422.239807 239.582047,422.232239 
	C239.330673,421.934357 239.058212,421.654327 238.438782,421.706940 
M361.561279,426.292999 
	C361.291962,426.010681 361.022644,425.728333 360.753326,425.445984 
	C360.636475,425.557617 360.411865,425.760223 360.418274,425.767853 
	C360.669617,426.065643 360.942047,426.345642 361.561279,426.292999 
M368.293579,419.437408 
	C368.010590,419.707397 367.727570,419.977356 367.444519,420.247345 
	C367.556427,420.364471 367.759460,420.589569 367.767090,420.583160 
	C368.065582,420.331177 368.346283,420.058136 368.293579,419.437408 
M557.706238,223.562378 
	C557.989075,223.292496 558.271912,223.022598 558.554749,222.752747 
	C558.442932,222.635620 558.331116,222.518478 558.219299,222.401337 
	C557.936462,222.671539 557.653625,222.941742 557.706238,223.562378 
M299.398132,394.199646 
	C299.788086,394.203918 300.178009,394.208160 300.567932,394.212433 
	C300.569489,394.050629 300.581909,393.748199 300.571075,393.747345 
	C300.183044,393.717346 299.792847,393.715546 299.398132,394.199646 
M261.398285,340.199310 
	C261.788055,340.203613 262.177826,340.207916 262.567596,340.212219 
	C262.569153,340.050507 262.581604,339.748260 262.570679,339.747406 
	C262.182861,339.717285 261.792877,339.715454 261.398285,340.199310 
M489.438049,319.706207 
	C489.707794,319.988953 489.977509,320.271729 490.247253,320.554474 
	C490.364319,320.442657 490.589264,320.239746 490.582855,320.232147 
	C490.331085,319.933929 490.058289,319.653503 489.438049,319.706207 
z"/>
<path fill="#FDFDFD" opacity="1.000000" stroke="none" 
	d="
M1.000002,577.468628 
	C1.878542,580.711487 2.806617,584.411987 3.628055,588.136047 
	C13.307817,632.020264 47.292015,663.110901 91.873840,668.853760 
	C92.525108,668.937683 93.167488,669.090515 93.907013,669.605591 
	C63.073082,670.000000 32.146160,670.000000 1.000000,670.000000 
	C1.000000,639.312805 1.000000,608.625061 1.000002,577.468628 
z"/>
<path fill="#FDFDFD" opacity="1.000000" stroke="none" 
	d="
M576.468628,670.000000 
	C580.391357,669.117859 584.821899,668.395447 589.167969,667.328674 
	C631.182251,657.016174 657.638489,630.997009 668.144226,588.924866 
	C668.463989,587.644287 668.868774,586.384827 669.616943,585.057739 
	C670.000000,613.263916 670.000000,641.527832 670.000000,670.000000 
	C638.979431,670.000000 607.958374,670.000000 576.468628,670.000000 
z"/>
<path fill="#FDFDFD" opacity="1.000000" stroke="none" 
	d="
M86.531342,1.000000 
	C84.124535,1.887351 81.279716,2.893557 78.368980,3.644128 
	C38.175575,14.008523 6.315932,47.935257 1.392616,93.880707 
	C1.000000,63.075943 1.000000,32.151890 1.000000,1.000000 
	C29.353821,1.000000 57.708252,1.000000 86.531342,1.000000 
z"/>
<path fill="#FDFDFD" opacity="1.000000" stroke="none" 
	d="
M670.000000,86.531342 
	C667.894226,79.975113 666.363708,72.713478 663.586548,65.964867 
	C648.860901,30.180613 622.363281,8.718234 584.109863,1.385146 
	C612.593567,1.000000 641.187134,1.000000 670.000000,1.000000 
	C670.000000,29.353851 670.000000,57.708267 670.000000,86.531342 
z"/>
<path fill="#92918F" opacity="1.000000" stroke="none" 
	d="
M96.466988,670.000000 
	C96.928764,669.617920 97.857460,668.901184 98.786301,668.901001 
	C256.606689,668.868225 414.427094,668.886414 572.247498,668.913940 
	C572.735291,668.914001 573.223145,669.077881 573.855469,669.582642 
	C414.977997,670.000000 255.955978,670.000000 96.466988,670.000000 
z"/>
<path fill="#919191" opacity="1.000000" stroke="none" 
	d="
M1.000000,101.468658 
	C1.347295,103.161980 1.997944,105.323868 1.998618,107.485954 
	C2.046020,259.566803 2.042750,411.647644 2.032478,563.728516 
	C2.032276,566.714050 1.899379,569.699646 1.414196,572.842590 
	C1.000000,415.979095 1.000000,258.958221 1.000000,101.468658 
z"/>
<path fill="#02B2F9" opacity="1.000000" stroke="none" 
	d="
M267.673279,345.250275 
	C267.673279,345.250275 267.869019,345.290741 268.285950,345.207733 
	C270.415497,343.411713 272.128082,341.698700 273.840637,339.985687 
	C274.139984,339.803619 274.315948,339.539368 274.657043,338.745361 
	C275.834412,333.622528 277.459137,328.952087 277.489899,324.271179 
	C277.660645,298.296844 277.404572,272.319946 277.379669,246.343887 
	C277.369965,236.187759 277.619324,226.031601 277.667419,215.875061 
	C277.680267,213.158447 278.423065,211.192612 281.507416,211.263138 
	C294.639160,211.563370 307.769379,211.928116 320.900177,212.269287 
	C320.940948,213.270645 320.981689,214.272018 321.013855,216.085022 
	C321.006927,226.699539 321.002380,236.502411 321.011566,246.305298 
	C321.039886,276.538269 321.073425,306.771210 321.104950,337.004181 
	C320.992279,337.747803 320.879578,338.491425 320.627014,339.885468 
	C316.658081,349.023926 312.829041,357.511963 308.672852,366.081512 
	C305.252045,369.108887 302.233246,372.138489 299.050049,374.984192 
	C290.980103,382.198517 280.886841,385.393402 270.967987,388.913849 
	C270.203796,388.947540 269.439575,388.981232 267.972198,389.164917 
	C264.839142,389.858154 262.409302,390.401337 259.979431,390.944519 
	C253.971252,390.879272 247.963074,390.813995 241.199249,390.575562 
	C234.154816,388.852081 227.677048,387.830017 221.610321,385.659180 
	C196.628296,376.719971 180.535141,356.534332 180.221375,332.315125 
	C179.722794,293.832672 180.098663,255.339294 179.988068,216.850861 
	C179.976028,212.668777 181.786804,211.221802 185.675003,211.231934 
	C196.164963,211.259293 206.663071,211.394394 217.141891,211.027786 
	C222.020920,210.857101 223.356720,212.543121 223.337189,217.278641 
	C223.188675,253.267410 223.404556,289.257874 223.208557,325.246155 
	C223.154465,335.180176 226.114197,343.063843 235.519318,347.231598 
	C246.462158,352.080688 257.354797,352.367401 267.673279,345.250275 
z"/>
<path fill="#FD0100" opacity="1.000000" stroke="none" 
	d="
M397.135254,333.203186 
	C397.135254,333.203186 397.435303,332.967590 397.943115,332.960785 
	C399.299347,332.608765 400.147736,332.263550 400.991577,332.197479 
	C400.988129,332.662689 400.989197,332.848785 401.034119,333.393494 
	C401.781738,334.867096 402.291199,336.663849 403.235901,336.932983 
	C404.563995,337.311340 406.215118,336.555725 407.728088,336.285034 
	C405.593353,330.911438 412.330292,330.298767 412.624603,325.809601 
	C414.241180,327.083313 415.311951,327.926971 415.363220,327.967346 
	C420.476685,324.053680 424.611786,319.662109 429.694580,317.398041 
	C433.558685,315.676849 432.772858,314.881409 431.838959,311.839600 
	C431.190552,309.727600 432.252869,305.237244 433.670288,304.685638 
	C438.240540,302.906891 443.329102,302.459991 448.226654,301.497955 
	C447.963593,290.684082 448.824615,288.896179 454.936462,288.175110 
	C454.420471,286.560425 453.923615,285.005676 453.343079,283.189056 
	C456.312408,284.250702 458.781647,285.133545 461.250885,286.016357 
	C461.795898,283.235748 462.340942,280.455170 462.955780,277.318298 
	C461.041107,278.316406 459.803314,279.036499 458.482086,279.536011 
	C458.348053,279.586700 457.210388,277.996582 457.394531,277.700806 
	C458.220184,276.374542 459.145660,274.918915 460.414459,274.092316 
	C466.856110,269.895752 473.415802,265.880371 479.934113,261.801544 
	C479.377380,261.565125 478.820648,261.328705 478.263885,261.092316 
	C478.955017,257.793304 478.747009,252.314880 480.500641,251.591446 
	C486.497070,249.117722 487.309875,245.753235 485.011078,240.499069 
	C484.949982,240.359406 485.003540,240.169601 485.360535,239.965851 
	C488.619568,240.220947 491.521729,240.513351 494.787872,240.842438 
	C494.091736,238.790497 493.152374,237.282074 493.135620,235.763474 
	C493.042175,227.284195 497.718140,220.463074 501.249634,213.243393 
	C502.286316,211.124039 503.119354,208.902786 504.000000,206.709869 
	C505.671814,202.546829 506.350281,197.458633 509.216370,194.440231 
	C512.491089,190.991562 514.237305,188.425812 511.596069,184.317551 
	C510.699402,182.922821 509.740936,181.567795 508.810730,180.194626 
	C514.106812,182.344986 514.834595,178.859467 513.830078,176.126419 
	C511.441040,169.626434 516.264587,162.097565 510.698456,156.176300 
	C510.318054,155.771606 510.500641,154.605865 510.730743,153.894073 
	C513.813110,144.359573 507.425598,133.333694 497.466003,130.746445 
	C496.503845,130.496490 495.221222,128.847397 495.246857,127.878471 
	C495.364685,123.427071 492.535522,121.385536 489.227295,119.538490 
	C487.491760,118.569504 485.570892,117.741760 484.195862,116.382675 
	C483.228424,115.426483 483.039856,113.682304 482.503479,112.289978 
	C483.996643,112.034027 485.519440,111.479568 486.972931,111.623741 
	C487.996002,111.725204 489.077881,112.626656 489.866638,113.432205 
	C492.072662,115.685226 494.140106,118.073967 496.262238,120.409157 
	C496.912170,119.934509 497.562103,119.459862 498.212036,118.985214 
	C496.870636,116.594162 495.529236,114.203117 493.483063,110.555847 
	C497.349823,111.209206 501.283630,110.717278 502.462128,112.290001 
	C505.988281,116.995682 510.535675,115.860207 515.334961,115.998695 
	C516.110840,116.001106 516.554199,116.002693 517.062622,116.356079 
	C523.590942,120.459877 523.590942,120.459877 520.439453,126.200096 
	C524.852844,128.901260 525.997498,128.342484 527.337402,122.995728 
	C528.113220,122.997185 528.556641,122.998039 529.030151,123.344330 
	C529.856445,126.476501 527.798645,130.159500 531.837524,131.946396 
	C534.111633,132.952530 537.200562,133.112396 537.186279,136.866150 
	C537.176331,139.485321 538.430298,142.356583 537.704895,144.671997 
	C536.374634,148.918121 537.992126,151.983246 539.800049,155.451874 
	C540.715698,157.208511 540.960144,159.697281 540.572266,161.671173 
	C539.773865,165.734329 538.607544,169.523727 541.068115,173.572220 
	C541.809753,174.792557 541.229370,177.391922 540.341125,178.771317 
	C539.930603,179.408844 537.327087,178.613983 535.715149,178.511398 
	C535.283386,178.483948 534.841675,178.614059 534.452698,178.665466 
	C537.700317,186.297394 538.858398,193.416916 531.708313,199.591125 
	C530.965393,200.232681 530.729248,202.274139 531.145203,203.309662 
	C533.402466,208.929016 527.567322,210.467896 525.938782,214.088745 
	C524.216675,217.917664 525.488159,223.161392 519.866699,225.192184 
	C515.570496,226.744217 514.091187,230.376526 515.451355,234.948929 
	C515.759277,235.983917 515.721191,237.121796 515.848877,238.270584 
	C506.714203,237.732666 500.705414,241.140198 500.743652,247.437729 
	C502.665680,246.170761 504.107758,244.859131 505.803375,244.217758 
	C507.136688,243.713425 508.782898,244.036407 510.290131,243.991882 
	C509.599884,245.259827 509.032928,246.617676 508.189636,247.773987 
	C507.018524,249.379776 505.551483,250.769821 504.381836,252.376495 
	C503.616150,253.428223 503.152557,254.699875 502.010468,256.937134 
	C501.170380,254.160416 500.737915,252.731079 500.205627,250.971786 
	C496.443115,253.115585 496.082672,256.164185 497.729980,258.831604 
	C499.401031,261.537415 498.696167,263.165192 496.734955,264.808319 
	C494.646393,266.558136 491.192474,267.746063 490.512299,269.875061 
	C489.294250,273.687439 487.228851,274.796844 483.737762,275.109680 
	C482.789368,275.194641 481.337677,276.384674 481.228729,277.213623 
	C481.121216,278.031952 482.428589,279.029694 483.101501,279.958679 
	C484.073364,281.300293 485.032837,282.650848 485.588745,283.998352 
	C483.754456,284.274597 481.218323,284.288391 481.081848,284.867554 
	C480.116943,288.963135 476.371521,289.274475 473.591370,290.442139 
	C470.610687,291.694031 469.039551,293.303833 468.648804,296.503937 
	C468.202240,300.160797 467.986389,304.097229 463.377777,305.429962 
	C461.740417,305.903442 460.159729,306.623871 458.621063,307.371368 
	C457.452789,307.938934 456.163086,308.520538 455.320190,309.451477 
	C449.261932,316.142365 443.304077,322.924164 437.314362,329.677094 
	C437.071228,329.390015 436.828094,329.102936 436.584991,328.815826 
	C436.355682,330.764557 436.126404,332.713257 435.785675,335.609039 
	C430.201996,330.405548 428.876526,335.747986 426.365143,337.514343 
	C426.988220,339.607208 427.492889,341.302399 427.651978,342.964874 
	C423.342438,341.061462 422.178345,344.626617 420.235962,346.614716 
	C419.235443,347.638855 418.336792,349.772888 417.456116,349.739044 
	C411.926880,349.526581 409.144165,353.623779 405.246704,356.277435 
	C402.266357,358.306702 400.376770,359.628174 402.193054,364.030518 
	C397.132233,362.060822 393.936401,363.565704 391.105072,367.015472 
	C390.141815,368.189117 387.870392,368.810547 386.206787,368.791779 
	C380.877045,368.731659 377.502869,371.466766 377.431366,374.807648 
	C374.296844,377.819336 372.026031,380.001190 369.551392,382.378876 
	C364.645020,380.790619 361.739197,381.846619 358.023010,386.954651 
	C351.885010,395.391571 343.141693,400.009247 334.844788,403.489929 
	C332.695404,406.991547 330.917847,409.887421 329.140289,412.783295 
	C326.321198,412.728821 324.198486,413.957184 321.916870,414.450439 
	C319.998047,414.865295 317.737732,414.872101 315.916992,414.235199 
	C314.150055,413.617096 312.750275,411.949402 310.879974,410.503510 
	C308.571442,416.269135 313.234131,417.973450 314.864990,421.290405 
	C313.814087,422.051025 312.907043,422.523682 311.955261,422.621643 
	C309.252869,416.623688 304.557983,419.779541 300.117981,418.969086 
	C301.507507,423.430420 300.881256,425.560425 296.200806,424.171234 
	C295.294678,423.902283 293.782593,424.979065 292.808899,425.758484 
	C289.269562,428.591400 285.889191,431.624176 282.323059,434.421143 
	C281.699646,434.910156 280.431885,434.483154 279.525452,434.721588 
	C274.609467,436.014618 267.750916,432.701843 265.765167,441.227783 
	C262.838898,439.864777 260.375305,438.717255 257.147156,437.213623 
	C263.665283,431.913116 269.956543,431.049957 277.610962,433.114716 
	C276.597412,431.000488 276.383698,429.970093 275.760773,429.352142 
	C272.282867,425.902344 274.888184,423.366241 277.632355,422.522552 
	C281.820709,421.234894 285.591614,420.269897 286.251862,415.143768 
	C286.297913,414.786011 287.066528,414.155579 287.254425,414.234528 
	C295.022736,417.497894 299.844635,412.733307 304.949982,408.118744 
	C306.742401,406.498688 309.653870,406.177277 311.663422,404.722809 
	C314.752411,402.487122 321.729065,400.787079 323.726990,402.259033 
	C328.417084,405.714600 329.096008,405.496857 330.593750,399.695160 
	C331.261566,397.108429 331.091827,393.826202 336.033997,396.065155 
	C333.149353,388.600403 339.380737,389.106079 342.614655,387.277161 
	C341.537903,381.062592 342.584930,380.016510 348.025543,381.466095 
	C348.786072,381.668793 349.905579,381.971222 350.368011,381.605988 
	C352.968964,379.551575 355.819427,377.622803 357.841461,375.063782 
	C362.610352,369.028503 367.376801,363.288879 375.872711,362.825623 
	C376.454498,362.793884 377.349182,362.314331 377.517395,361.837921 
	C379.283081,356.836731 383.028229,356.427307 388.234863,358.126038 
	C386.007324,354.107086 384.695953,351.003906 390.426941,350.740814 
	C391.287781,350.701294 392.056885,348.664032 392.989166,347.385284 
	C394.022522,347.799774 395.166748,348.258759 396.573151,348.822876 
	C396.872009,347.197693 397.105133,345.929993 397.394409,344.356964 
	C401.247467,345.252075 403.153473,343.831726 402.904236,341.243835 
	C400.392792,338.622498 398.712402,336.868561 397.058533,334.794495 
	C397.101807,334.050629 397.118530,333.626892 397.135254,333.203186 
M506.984497,128.558212 
	C506.525909,129.668365 506.067322,130.778519 505.608765,131.888687 
	C506.440063,131.985184 507.271332,132.081696 508.102631,132.178192 
	C507.951080,130.899307 507.799530,129.620407 506.984497,128.558212 
M370.604919,370.981262 
	C371.227997,370.062988 371.851074,369.144714 372.474152,368.226440 
	C371.514130,368.145081 370.554077,368.063721 369.594055,367.982361 
	C369.692657,368.977356 369.791260,369.972321 370.604919,370.981262 
M515.386475,122.105873 
	C514.725891,121.998222 514.065369,121.890579 513.404785,121.782936 
	C513.469727,122.351944 513.534607,122.920952 513.599548,123.489952 
	C514.283752,123.225479 514.967896,122.961006 515.386475,122.105873 
M450.286621,310.536438 
	C450.398193,309.961121 450.509766,309.385803 450.621368,308.810486 
	C450.212677,308.805328 449.803986,308.800140 449.395264,308.794983 
	C449.492676,309.373047 449.590088,309.951111 450.286621,310.536438 
M437.563507,322.294281 
	C437.293060,322.010834 437.022614,321.727417 436.752197,321.444031 
	C436.634857,321.556152 436.409393,321.759644 436.415771,321.767181 
	C436.668274,322.066071 436.941772,322.347198 437.563507,322.294281 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M528.909485,115.022079 
	C532.453186,114.960236 534.425537,113.105988 535.353638,109.321030 
	C536.098206,109.049675 536.501831,109.128914 536.959595,109.428047 
	C537.179565,109.915520 537.410278,110.103386 537.798767,110.534424 
	C539.817139,112.664856 541.742676,114.472397 543.757751,116.127472 
	C543.847290,115.974998 543.690735,116.291168 543.727295,116.632774 
	C545.440186,119.080101 547.116455,121.185822 548.850708,123.475418 
	C548.908630,123.659294 549.177307,123.935936 549.147949,124.274887 
	C549.970032,125.881317 550.821533,127.148788 551.713745,128.583878 
	C551.754456,128.751495 551.991577,129.002060 551.960938,129.384521 
	C553.989624,136.504257 556.049072,143.241531 558.083740,150.338989 
	C558.276672,152.119446 558.494385,153.539673 558.524902,155.209717 
	C558.566589,156.637894 558.795593,157.816269 558.978516,159.410736 
	C558.964966,161.220306 558.997437,162.613770 558.811401,164.311371 
	C558.539368,166.117645 558.485962,167.619827 558.312744,169.497620 
	C558.154663,170.919785 558.116272,171.966309 557.851929,173.294678 
	C557.619507,174.764786 557.613098,175.953018 557.465210,177.485321 
	C557.288208,178.569077 557.252686,179.308807 556.942383,180.295990 
	C555.450012,184.994980 554.417358,189.507370 552.976135,193.885269 
	C550.098022,202.627365 546.995789,211.295670 543.783569,220.213501 
	C543.285278,221.169571 542.989502,221.906586 542.433594,222.835983 
	C542.004883,223.637848 541.836426,224.247330 541.550537,224.976654 
	C541.433044,225.096466 541.359558,225.423904 541.094055,225.625702 
	C539.610291,228.571899 538.392029,231.316284 536.975159,234.287231 
	C536.431946,235.228439 536.087402,235.943085 535.442627,236.799866 
	C534.483948,238.315598 533.825623,239.689194 533.039429,241.210861 
	C532.911621,241.358917 532.809326,241.736481 532.503540,241.865051 
	C531.506348,243.344955 530.814941,244.696289 529.993164,246.188019 
	C529.862732,246.328445 529.754211,246.695999 529.485596,246.855820 
	C528.980286,247.580139 528.743652,248.144638 528.297607,248.934387 
	C527.345032,250.377625 526.601990,251.595596 525.579895,252.940292 
	C525.086182,253.617844 524.871521,254.168671 524.740051,254.873047 
	C524.823303,255.026566 524.641113,254.723495 524.360229,254.833252 
	C523.779053,255.649567 523.478760,256.356110 523.038574,257.198242 
	C522.898682,257.333862 522.781372,257.705475 522.486023,257.813721 
	C521.859558,258.639679 521.528442,259.357422 521.057068,260.202881 
	C520.916809,260.330597 520.777832,260.683533 520.469727,260.774658 
	C519.818237,261.592194 519.474854,262.318573 518.995544,263.171082 
	C518.859558,263.297180 518.734741,263.646484 518.438232,263.757660 
	C517.813416,264.599213 517.485168,265.329590 517.019409,266.182556 
	C516.881897,266.305115 516.742004,266.645905 516.438843,266.749756 
	C515.770020,267.574524 515.404358,268.295441 514.914551,269.136047 
	C514.790344,269.255768 514.681641,269.583191 514.369751,269.691772 
	C513.401550,270.886017 512.745239,271.971741 512.047241,273.034912 
	C512.005554,273.012421 512.064148,273.086914 511.748474,273.193146 
	C510.483124,274.780975 509.533417,276.262573 508.356995,277.924927 
	C507.609253,278.908813 507.088226,279.712006 506.334137,280.643433 
	C505.970581,281.090240 505.840088,281.408844 505.720520,281.851990 
	C505.731415,281.976593 505.676849,281.732147 505.376404,281.834534 
	C504.385620,282.957886 503.695251,283.978882 502.768616,285.175140 
	C501.973419,286.158112 501.414520,286.965820 500.587372,287.858154 
	C500.157410,288.264465 499.995667,288.586121 499.557678,289.078827 
	C495.537750,293.868164 491.794128,298.486481 487.748779,303.173340 
	C487.159515,303.771393 486.871918,304.300873 486.416534,304.893250 
	C486.248718,304.956146 486.069611,305.266602 485.749817,305.322754 
	C483.982208,307.263397 482.534424,309.147919 480.796875,311.166626 
	C479.264709,312.731476 478.022369,314.162170 476.457092,315.700562 
	C474.629456,317.469727 473.124756,319.131195 471.426025,320.883575 
	C470.989685,321.161713 470.859680,321.408142 470.518280,321.818268 
	C462.423340,329.455444 454.697662,337.035919 446.865997,344.505310 
	C440.989014,350.110443 434.975830,355.572845 428.739563,361.195953 
	C427.570404,362.104950 426.684937,362.916718 425.535217,363.779663 
	C425.099365,364.129791 424.927734,364.428741 424.826965,364.827423 
	C424.897766,364.927185 424.721924,364.757050 424.474121,364.797089 
	C424.060028,365.110352 423.893738,365.383545 423.797150,365.766357 
	C423.866882,365.876007 423.686676,365.688782 423.394714,365.715881 
	C422.728455,366.155151 422.354187,366.567322 421.989960,366.989746 
	C422.000000,367.000000 421.981354,366.978058 421.635742,366.993866 
	C417.608246,370.060547 413.926300,373.111420 410.195068,376.148743 
	C410.145782,376.135223 410.148804,376.237427 409.850891,376.242859 
	C409.218414,376.718964 408.883850,377.189636 408.396057,377.728027 
	C408.242798,377.795746 408.031738,378.056030 407.704773,378.045044 
	C406.496704,378.916443 405.615662,379.798828 404.868530,380.811646 
	C405.002472,380.942139 404.720001,380.697693 404.373718,380.717957 
	C400.241394,383.677307 396.455322,386.616364 392.694153,389.616699 
	C392.719055,389.678040 392.605164,389.610596 392.267151,389.660034 
	C384.271790,395.126556 376.614441,400.543610 368.979187,405.979706 
	C369.001221,405.998779 368.963715,405.953308 368.612793,405.982300 
	C362.004730,410.213348 355.747528,414.415405 349.238678,418.675537 
	C348.554657,419.006653 348.122284,419.279663 347.431641,419.604309 
	C346.929657,419.899536 346.686005,420.143158 346.264984,420.406067 
	C346.087616,420.425385 345.802063,420.639374 345.467224,420.666962 
	C344.276276,421.346771 343.420166,421.998993 342.402832,422.701202 
	C342.241608,422.751190 341.989319,422.975555 341.625122,422.970551 
	C331.355713,428.520325 321.450562,434.075104 311.288696,439.705719 
	C310.721039,439.954865 310.410095,440.128174 309.783569,440.232117 
	C308.671204,440.788391 307.874390,441.414001 306.859802,442.118469 
	C306.349060,442.300659 306.073029,442.438080 305.457092,442.607483 
	C303.931915,443.297699 302.763580,443.989960 301.425659,444.719513 
	C301.256073,444.756805 300.988800,444.978699 300.672302,444.953979 
	C299.709320,445.417358 299.062866,445.905518 298.108948,446.458496 
	C297.237000,446.793793 296.672424,447.064240 295.743103,447.276978 
	C282.202026,452.573853 269.050201,457.989990 255.839233,463.257690 
	C248.955078,466.002686 241.966644,468.486053 234.685226,471.094879 
	C233.924088,471.258301 233.503052,471.414398 232.709732,471.482849 
	C220.656952,473.873169 209.039139,476.713348 197.282028,478.748840 
	C176.817810,482.291779 156.187485,483.411926 135.920044,477.962830 
	C127.945824,475.818909 120.560394,471.485077 113.130974,467.854248 
	C114.710754,467.698853 116.300262,467.428711 117.381584,468.047150 
	C122.071571,470.729523 122.506119,470.571564 123.271210,464.988281 
	C123.711189,464.809845 123.886086,464.735779 124.379044,464.800781 
	C125.150581,464.912262 125.604065,464.884644 126.238289,464.962860 
	C126.419037,465.068695 126.824791,465.172882 126.913307,465.509949 
	C129.664948,466.539093 132.328079,467.231201 135.273239,468.010559 
	C135.940643,468.177460 136.326004,468.257080 136.853561,468.658875 
	C141.010361,469.705414 145.024948,470.429749 149.373734,471.213928 
	C150.819656,471.432343 151.931412,471.590881 153.323547,472.052307 
	C160.821487,472.503326 168.038910,472.712677 175.256699,472.727081 
	C176.230377,472.729004 177.205978,471.750641 178.533875,471.223755 
	C179.587326,471.138580 180.287567,471.055817 181.299774,471.094482 
	C182.056580,471.215942 182.501434,471.216034 183.239197,471.429016 
	C185.485092,471.196259 187.438065,470.750580 189.653687,470.327942 
	C190.268433,470.323975 190.620560,470.296967 191.218979,470.459503 
	C192.657837,470.226624 193.850372,469.804230 195.301102,469.417297 
	C196.030502,469.282379 196.501724,469.111969 197.292114,469.057404 
	C198.062042,469.160095 198.512817,469.146912 199.220612,469.339508 
	C201.387909,468.800751 203.298172,468.056183 205.214218,467.275696 
	C205.220001,467.239746 205.161026,467.197052 205.505981,467.270233 
	C206.553467,467.273834 207.255997,467.204254 208.161209,467.309937 
	C209.068756,467.084991 209.773590,466.684814 210.730133,466.315491 
	C211.314621,466.292725 211.647385,466.239075 212.170120,466.365234 
	C212.965591,466.108307 213.571091,465.671631 214.178741,465.196503 
	C214.180908,465.158051 214.106720,465.137299 214.409225,465.215698 
	C215.126617,465.253601 215.541519,465.213074 216.164871,465.341248 
	C216.893677,465.201904 217.414017,464.893921 218.241943,464.581482 
	C218.931671,464.436310 219.313812,464.295502 219.979492,464.359161 
	C220.997696,464.143311 221.732346,463.722961 222.782379,463.306763 
	C224.711029,463.018494 226.324295,462.726105 228.229492,462.548828 
	C229.150543,462.186279 229.779663,461.708618 230.657532,461.174316 
	C231.909164,460.930298 232.912079,460.742920 234.212784,460.640808 
	C235.066910,460.231812 235.623230,459.737518 236.182312,459.207642 
	C236.185089,459.172058 236.117783,459.148254 236.437927,459.198608 
	C244.304642,456.849854 252.035049,454.910004 259.366913,451.974579 
	C297.141541,436.851105 332.895477,417.925537 366.669525,395.149048 
	C395.695190,375.574799 422.897095,353.738068 448.254547,329.717621 
	C473.406952,305.891479 496.193726,279.865784 515.232117,250.784943 
	C518.925232,245.143784 521.780579,238.954193 525.025879,233.047180 
	C525.029358,233.076782 525.088928,233.079529 525.337219,232.985931 
	C525.675842,232.570587 525.766113,232.248840 526.046692,231.635147 
	C527.897522,227.873993 529.558105,224.404770 531.335327,220.806610 
	C531.452087,220.677689 531.458435,220.329880 531.672852,220.112198 
	C532.339783,218.998657 532.792358,218.102783 533.570251,217.103485 
	C540.956360,205.909653 544.796692,193.501068 546.828613,180.775253 
	C548.460022,170.557587 549.071472,159.945068 548.331970,149.637131 
	C547.313538,135.439758 539.807678,124.086037 528.909485,115.022079 
z"/>
<path fill="#F70101" opacity="1.000000" stroke="none" 
	d="
M136.711365,468.336700 
	C136.326004,468.257080 135.940643,468.177460 135.079468,467.711548 
	C132.010696,466.607788 129.417740,465.890350 126.824791,465.172882 
	C126.824791,465.172882 126.419037,465.068695 126.243912,464.617737 
	C125.345894,463.535828 124.623001,462.904907 123.567734,462.200562 
	C122.560875,462.038635 121.886383,461.950195 121.136108,461.582764 
	C120.683807,460.926819 120.307297,460.549866 119.606987,460.133423 
	C118.536743,459.720886 117.790298,459.347778 116.990242,458.626617 
	C115.296890,457.176880 113.657150,456.075226 112.016678,454.982788 
	C112.015961,454.991974 112.025703,454.976959 111.989700,454.685852 
	C111.194000,454.450165 110.434319,454.505615 109.614670,454.549957 
	C109.554710,454.538849 109.467049,454.623657 109.238052,454.458008 
	C108.685234,453.840607 108.361412,453.388794 108.192101,452.662598 
	C107.548820,450.617737 106.751030,448.847321 105.972229,446.690216 
	C106.833199,441.503479 110.598938,442.121521 114.029999,442.320740 
	C115.065392,442.740753 116.036545,442.840332 117.365509,442.979156 
	C118.196991,442.966095 118.670662,442.913788 119.144325,442.861450 
	C119.519272,442.947052 119.894211,443.032654 120.524658,443.417725 
	C120.780167,443.717194 120.885147,444.084473 120.922150,444.455322 
	C122.398354,449.034027 125.166824,449.860382 129.330002,447.994751 
	C130.106171,448.009979 130.549347,448.016296 131.054504,448.358093 
	C131.292343,448.963623 131.436554,449.441406 131.648682,449.473724 
	C139.304825,450.640778 146.938980,452.177734 154.641953,452.708191 
	C158.033585,452.941711 161.566971,451.116699 165.394653,450.182587 
	C166.831696,449.802765 167.910065,449.466583 168.988434,449.130402 
	C169.455887,449.008820 169.923355,448.887238 170.914276,448.768311 
	C171.622742,448.856506 171.807739,448.942078 172.037933,449.371277 
	C173.436066,449.523895 174.788971,449.332886 176.357193,449.029022 
	C176.866913,448.716675 177.010468,448.439178 177.003143,448.083618 
	C178.065048,447.981476 179.126968,447.879364 180.636230,448.194397 
	C183.972168,450.825897 186.443314,450.916412 188.476868,447.178772 
	C189.197281,447.107574 189.610733,447.076416 190.396133,447.054443 
	C191.512283,447.039856 192.256516,447.016113 193.216553,447.243103 
	C194.297470,446.687195 195.162552,445.880554 196.203140,444.971436 
	C196.378632,444.868958 196.773605,444.773071 197.068756,444.874329 
	C197.997986,444.579010 198.632080,444.182434 199.266174,443.785889 
	C199.962006,443.562347 200.657852,443.338776 202.009247,443.038513 
	C203.356598,442.884674 204.048355,442.807526 205.039368,442.839783 
	C205.980042,442.544281 206.621475,442.139343 207.262894,441.734436 
	C207.262894,441.734436 207.280380,441.401245 207.511993,441.333618 
	C208.160843,441.107422 208.578079,440.948822 208.995316,440.790192 
	C209.991135,440.798492 210.986938,440.806824 212.622513,440.856995 
	C213.518356,440.681915 213.774429,440.464966 214.030502,440.248047 
	C214.181412,440.165619 214.332321,440.083160 214.747253,440.286102 
	C215.470566,440.874786 215.929840,441.178101 216.389130,441.481415 
	C216.616364,440.721313 216.843613,439.961182 217.070862,439.201080 
	C218.084686,438.918732 219.098495,438.636383 220.595917,438.660461 
	C223.670227,440.881927 223.876236,439.031525 224.208832,436.997681 
	C224.387802,436.896973 224.781433,436.779755 225.173187,436.861053 
	C228.999603,436.178406 232.434265,435.414459 236.190765,434.719025 
	C237.014496,434.196442 237.516357,433.605408 238.068527,432.765167 
	C238.103607,432.182068 238.088379,431.848175 238.413513,431.411530 
	C239.445297,431.151642 240.136765,430.994476 241.184113,430.930115 
	C242.029419,431.019348 242.518829,431.015778 243.425690,431.020905 
	C247.109085,430.723755 251.086975,431.758942 251.866974,426.770111 
	C251.943665,426.489319 252.048508,426.219452 252.561737,425.711243 
	C255.291824,424.690491 257.641693,423.919098 260.170471,423.371948 
	C260.807373,423.232513 261.265350,422.868835 261.810974,422.274567 
	C262.090973,421.768768 262.210449,421.464905 262.257141,421.132416 
	C262.898468,421.071716 263.539795,421.011017 264.879089,420.976013 
	C266.342621,420.911743 267.108215,420.821777 268.054321,420.734131 
	C268.318970,420.652313 268.489471,420.521271 268.473419,420.491669 
	C268.365509,420.293304 268.217896,420.116577 268.320557,419.729919 
	C269.372620,419.353638 270.187347,419.180206 271.394104,419.007996 
	C275.229309,417.047028 278.672516,415.084839 282.390076,413.001160 
	C285.746490,411.536407 288.951965,410.418091 291.887573,408.807800 
	C300.872498,403.879211 309.955414,399.082153 318.620514,393.630402 
	C327.909149,387.786316 336.819519,381.331970 345.795746,375.003204 
	C352.981079,369.937134 360.172180,364.856934 367.083862,359.430328 
	C377.196503,351.490570 387.061493,343.235443 397.032043,335.114685 
	C398.712402,336.868561 400.392792,338.622498 402.904236,341.243835 
	C403.153473,343.831726 401.247467,345.252075 397.394409,344.356964 
	C397.105133,345.929993 396.872009,347.197693 396.573151,348.822876 
	C395.166748,348.258759 394.022522,347.799774 392.989166,347.385284 
	C392.056885,348.664032 391.287781,350.701294 390.426941,350.740814 
	C384.695953,351.003906 386.007324,354.107086 388.234863,358.126038 
	C383.028229,356.427307 379.283081,356.836731 377.517395,361.837921 
	C377.349182,362.314331 376.454498,362.793884 375.872711,362.825623 
	C367.376801,363.288879 362.610352,369.028503 357.841461,375.063782 
	C355.819427,377.622803 352.968964,379.551575 350.368011,381.605988 
	C349.905579,381.971222 348.786072,381.668793 348.025543,381.466095 
	C342.584930,380.016510 341.537903,381.062592 342.614655,387.277161 
	C339.380737,389.106079 333.149353,388.600403 336.033997,396.065155 
	C331.091827,393.826202 331.261566,397.108429 330.593750,399.695160 
	C329.096008,405.496857 328.417084,405.714600 323.726990,402.259033 
	C321.729065,400.787079 314.752411,402.487122 311.663422,404.722809 
	C309.653870,406.177277 306.742401,406.498688 304.949982,408.118744 
	C299.844635,412.733307 295.022736,417.497894 287.254425,414.234528 
	C287.066528,414.155579 286.297913,414.786011 286.251862,415.143768 
	C285.591614,420.269897 281.820709,421.234894 277.632355,422.522552 
	C274.888184,423.366241 272.282867,425.902344 275.760773,429.352142 
	C276.383698,429.970093 276.597412,431.000488 277.610962,433.114716 
	C269.956543,431.049957 263.665283,431.913116 257.147156,437.213623 
	C260.375305,438.717255 262.838898,439.864777 265.765167,441.227783 
	C267.750916,432.701843 274.609467,436.014618 279.525452,434.721588 
	C280.431885,434.483154 281.699646,434.910156 282.323059,434.421143 
	C285.889191,431.624176 289.269562,428.591400 292.808899,425.758484 
	C293.782593,424.979065 295.294678,423.902283 296.200806,424.171234 
	C300.881256,425.560425 301.507507,423.430420 300.117981,418.969086 
	C304.557983,419.779541 309.252869,416.623688 311.956207,422.961975 
	C312.008057,424.130646 312.014252,424.584320 311.790222,425.299622 
	C304.033081,428.947327 296.552826,432.441956 288.969147,435.695618 
	C275.310333,441.555725 261.558685,447.199829 247.913727,453.091492 
	C243.864868,454.839783 240.043427,457.114655 236.117783,459.148254 
	C236.117783,459.148254 236.185089,459.172058 235.830780,459.088654 
	C234.984711,459.010529 234.492950,459.015808 233.779205,458.777039 
	C229.331131,459.990845 225.105026,461.448639 220.676849,463.007690 
	C220.215149,463.457520 219.955551,463.806122 219.695938,464.154694 
	C219.313812,464.295502 218.931671,464.436310 218.069550,464.376587 
	C217.059967,464.112854 216.530350,464.049622 215.815765,463.767303 
	C215.122772,464.077911 214.614746,464.607605 214.106720,465.137299 
	C214.106720,465.137299 214.180908,465.158051 213.831879,465.069946 
	C212.993958,464.963562 212.505081,464.945282 211.763779,464.683746 
	C209.394592,465.359314 207.277802,466.278198 205.161026,467.197052 
	C205.161026,467.197052 205.220001,467.239746 204.864532,467.130646 
	C204.008636,467.004791 203.508224,466.988007 202.831970,466.760254 
	C202.044418,466.983917 201.432709,467.418610 200.517029,467.864929 
	C199.808441,467.903503 199.403793,467.930420 198.818848,467.748993 
	C198.083344,468.007599 197.528137,468.474548 196.972931,468.941528 
	C196.501724,469.111969 196.030502,469.282379 195.011749,469.243164 
	C193.312134,469.011322 192.160034,468.989136 190.779480,468.720459 
	C189.294632,468.917206 188.038239,469.360443 186.403717,469.818909 
	C185.016846,469.867279 184.008118,469.900452 182.816040,469.724243 
	C182.084412,470.000885 181.536118,470.486969 180.987808,470.973053 
	C180.287567,471.055817 179.587326,471.138580 178.063980,471.089478 
	C169.850677,470.842590 162.460480,470.727570 155.046844,470.323029 
	C154.339020,469.711090 153.654633,469.388702 152.611969,469.060242 
	C151.169662,469.334595 150.085632,469.615021 148.675934,469.879272 
	C147.920166,469.803375 147.490067,469.743622 147.027252,469.328339 
	C144.600769,466.749542 142.256287,464.187744 138.618805,467.857239 
	C137.721283,468.048218 137.216324,468.192474 136.711365,468.336700 
z"/>
<path fill="#02AFF5" opacity="1.000000" stroke="none" 
	d="
M428.761169,287.906158 
	C428.415070,287.872009 428.154602,288.005249 427.618134,288.654083 
	C425.428528,290.722107 423.600525,292.441895 420.739410,295.133636 
	C420.443817,291.140778 420.157654,288.729340 420.105255,286.312866 
	C419.893219,276.539368 419.753174,266.764313 419.586578,256.989838 
	C419.672638,256.543884 419.758728,256.097900 419.833771,254.957001 
	C418.365143,253.328857 416.958710,251.699081 415.440765,251.587646 
	C409.975891,251.186478 404.473083,251.217377 398.985382,251.244827 
	C395.652954,251.261490 394.060333,249.815338 394.070709,246.478836 
	C394.101807,236.496582 394.157318,226.513702 394.072479,216.532166 
	C394.042297,212.979431 395.530701,211.317200 399.090698,211.310745 
	C419.889435,211.273056 440.688477,211.245407 461.486450,211.089935 
	C465.175018,211.062363 466.478424,212.742767 466.679626,216.160385 
	C467.503235,230.150345 467.564636,243.890640 456.050598,254.452499 
	C455.721893,254.754028 455.663025,255.349716 455.478333,255.808228 
	C455.173950,255.812088 454.966888,255.956009 454.540100,256.585083 
	C452.077637,259.902893 449.932220,262.875641 447.786804,265.848389 
	C447.786804,265.848358 447.893433,265.935852 447.553406,265.995239 
	C441.062653,273.338501 434.911896,280.622345 428.761169,287.906158 
z"/>
<path fill="#05A5E6" opacity="1.000000" stroke="none" 
	d="
M488.050537,303.104797 
	C491.794128,298.486481 495.537750,293.868164 499.834412,289.007141 
	C500.543518,288.434113 500.699585,288.103821 500.855652,287.773529 
	C501.414520,286.965820 501.973419,286.158112 503.121155,285.155029 
	C504.365601,283.883820 505.021210,282.807983 505.676849,281.732147 
	C505.676849,281.732147 505.731415,281.976593 506.022614,281.799683 
	C506.398254,281.253571 506.482727,280.884369 506.567200,280.515198 
	C507.088226,279.712006 507.609253,278.908813 508.713623,277.887573 
	C510.219330,276.141998 511.141724,274.614441 512.064148,273.086914 
	C512.064148,273.086914 512.005554,273.012421 512.403687,272.993805 
	C513.428406,271.844513 514.054993,270.713867 514.681641,269.583191 
	C514.681641,269.583191 514.790344,269.255768 515.259644,269.091980 
	C516.066711,268.167450 516.404358,267.406677 516.742004,266.645905 
	C516.742004,266.645905 516.881897,266.305115 517.354980,266.124146 
	C518.130249,265.177643 518.432495,264.412079 518.734741,263.646484 
	C518.734741,263.646484 518.859558,263.297180 519.330322,263.119385 
	C520.126709,262.188873 520.452271,261.436218 520.777832,260.683533 
	C520.777832,260.683533 520.916809,260.330597 521.390930,260.149323 
	C522.170471,259.213867 522.475952,258.459656 522.781372,257.705475 
	C522.781372,257.705475 522.898682,257.333862 523.367920,257.144470 
	C524.105103,256.211243 524.373108,255.467361 524.641113,254.723511 
	C524.641113,254.723495 524.823303,255.026566 525.065796,254.809784 
	C525.491760,253.999847 525.675293,253.406708 525.858887,252.813583 
	C526.601990,251.595596 527.345032,250.377625 528.647461,248.872559 
	C529.389221,247.955627 529.571716,247.325806 529.754211,246.695999 
	C529.754211,246.695999 529.862732,246.328445 530.335938,246.138229 
	C531.475952,244.544174 532.142639,243.140335 532.809326,241.736481 
	C532.809326,241.736481 532.911621,241.358917 533.379089,241.144318 
	C534.478699,239.505722 535.110779,238.081741 535.742859,236.657745 
	C536.087402,235.943085 536.431946,235.228439 537.309448,234.223755 
	C539.014832,231.097137 540.187195,228.260529 541.359558,225.423904 
	C541.359558,225.423904 541.433044,225.096466 541.887695,224.857269 
	C542.459412,223.959915 542.576599,223.301773 542.693726,222.643616 
	C542.989502,221.906586 543.285278,221.169571 544.103333,220.070099 
	C547.782654,211.897797 551.303406,204.209396 553.966980,196.234711 
	C555.696838,191.055252 556.177063,185.458389 557.217163,180.048523 
	C557.252686,179.308807 557.288208,178.569077 557.739014,177.349655 
	C558.128784,175.584244 558.103333,174.298538 558.077881,173.012833 
	C558.116272,171.966309 558.154663,170.919785 558.624390,169.384216 
	C559.047180,167.265869 559.038574,165.636551 559.029968,164.007248 
	C558.997437,162.613770 558.964966,161.220306 559.234619,159.183243 
	C559.261902,157.346405 558.987061,156.153152 558.712158,154.959900 
	C558.494385,153.539673 558.276672,152.119446 558.349304,150.074722 
	C558.329529,142.014542 556.687561,135.011826 551.991577,129.002060 
	C551.991577,129.002060 551.754456,128.751495 551.813232,128.219269 
	C550.973755,126.436661 550.075562,125.186295 549.177307,123.935928 
	C549.177307,123.935936 548.908630,123.659294 548.910583,123.107170 
	C547.171936,120.467087 545.431335,118.379128 543.690735,116.291168 
	C543.690735,116.291168 543.847290,115.974998 543.836914,115.768196 
	C541.786377,113.778099 539.746155,111.994804 537.705933,110.211525 
	C537.410278,110.103386 537.179565,109.915520 536.996704,109.005768 
	C537.001770,107.511307 537.023987,106.659035 537.392456,105.874359 
	C544.391235,112.378517 551.043762,118.815071 557.784790,125.606018 
	C559.302002,129.423950 560.756348,132.877213 562.151672,136.354187 
	C563.318970,139.263031 564.417419,142.199539 565.669861,145.428864 
	C565.799438,146.169846 565.805542,146.605774 565.548096,147.317413 
	C565.733215,150.095123 566.181946,152.597107 566.721680,155.548462 
	C566.739990,160.298737 566.667358,164.599655 566.268799,169.013550 
	C565.911560,171.068558 565.880188,173.010605 565.887024,175.281219 
	C565.830505,176.037628 565.735840,176.465469 565.345703,176.963074 
	C565.024292,178.019699 564.998352,179.006577 564.995972,180.333496 
	C565.089783,181.117935 565.160034,181.562332 565.230225,182.006729 
	C565.077820,182.462921 564.925293,182.919098 564.329346,183.792389 
	C562.593506,189.474823 561.301208,194.740173 559.976196,200.330048 
	C559.794250,201.061050 559.644897,201.467514 559.175354,201.990509 
	C558.428711,203.604080 558.002258,205.101120 557.536560,206.949677 
	C557.174561,208.183609 556.851807,209.066025 556.206909,210.025970 
	C552.349182,218.184113 548.813538,226.264725 545.204895,234.614838 
	C544.968689,235.218460 544.805420,235.552567 544.308594,235.953705 
	C542.959595,238.289993 541.944092,240.559280 540.939697,242.875153 
	C540.950806,242.921753 540.855103,242.926331 540.510315,242.988647 
	C539.873413,243.839264 539.581299,244.627579 539.289185,245.415894 
	C539.289185,245.415894 539.228333,245.751053 538.765503,245.904053 
	C536.231995,249.735046 534.161377,253.413025 532.090698,257.091003 
	C532.090698,257.091003 532.015381,256.992493 531.692810,257.064941 
	C531.072144,257.942261 530.774109,258.747101 530.379395,259.826263 
	C529.823059,260.721832 529.363281,261.343079 528.561523,262.027466 
	C527.902100,262.855530 527.584656,263.620453 527.267212,264.385345 
	C527.267212,264.385345 527.182007,264.725250 526.722290,264.892883 
	C525.816711,266.004578 525.370789,266.948639 524.956665,267.928558 
	C524.988464,267.964417 524.895935,267.939636 524.552612,267.974060 
	C522.779114,270.301147 521.348938,272.593750 519.932495,274.919830 
	C519.946228,274.953278 519.874146,274.941010 519.530212,274.966003 
	C517.247925,277.803101 515.309631,280.615173 513.303589,283.590027 
	C513.235779,283.752777 512.977844,283.993103 512.632263,284.024841 
	C511.917542,284.797302 511.548431,285.537994 511.179321,286.278687 
	C511.121704,286.589691 511.019073,286.885040 510.587738,287.501648 
	C509.866730,288.225067 509.429474,288.611664 508.644928,289.020020 
	C507.502991,290.296051 506.708313,291.550262 505.773987,293.021179 
	C505.392395,293.480927 505.150482,293.723969 504.589386,293.995270 
	C503.796051,294.882294 503.321960,295.741089 502.759094,296.778748 
	C502.670288,296.957672 502.351898,297.199005 502.039490,297.233765 
	C501.462219,297.796021 501.197327,298.323547 500.795166,299.061768 
	C500.418793,299.506348 500.179657,299.740204 499.594910,299.987244 
	C498.117004,301.572723 496.984711,303.145050 495.861694,304.782410 
	C495.871002,304.847412 495.739655,304.849152 495.440979,304.890930 
	C494.873627,305.399780 494.604889,305.866852 494.163147,306.192596 
	C493.990112,306.051239 494.346588,306.319489 494.029999,306.330566 
	C493.468079,306.882629 493.222748,307.423615 492.869934,308.186096 
	C492.569611,308.689117 492.311493,308.884399 491.640076,309.000916 
	C489.266479,311.446075 487.240875,313.883789 485.215271,316.321472 
	C483.853638,317.925598 482.491974,319.529663 480.715637,321.517242 
	C479.788269,322.227997 479.275513,322.555267 478.456055,322.930634 
	C477.754456,323.636292 477.359589,324.293823 476.982178,324.975647 
	C476.999603,325.000000 476.949677,324.966888 476.605774,324.979187 
	C475.172241,326.321960 474.082581,327.652435 472.877441,329.202850 
	C472.555603,329.698456 472.287262,329.884979 471.609222,329.989746 
	C469.613373,332.086670 467.965271,334.176239 466.189301,336.267944 
	C466.061432,336.270081 466.314148,336.235962 465.996399,336.292175 
	C465.144470,337.232391 464.610291,338.116425 463.784058,338.966431 
	C463.244934,338.363159 463.030273,337.777008 462.743927,337.228210 
	C462.170654,336.129608 461.558197,335.051453 461.110901,333.667908 
	C464.453918,329.485260 467.647980,325.599548 470.842041,321.713837 
	C470.859680,321.408142 470.989685,321.161713 471.802917,320.927551 
	C473.842529,319.118011 475.311249,317.355408 476.780029,315.592834 
	C478.022369,314.162170 479.264709,312.731476 481.141174,311.142578 
	C483.206696,309.078430 484.638153,307.172516 486.069611,305.266602 
	C486.069611,305.266602 486.248718,304.956146 486.738647,304.848572 
	C487.502563,304.195587 487.776550,303.650208 488.050537,303.104797 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M528.673584,114.841782 
	C539.807678,124.086037 547.313538,135.439758 548.331970,149.637131 
	C549.071472,159.945068 548.460022,170.557587 546.828613,180.775253 
	C544.796692,193.501068 540.956360,205.909653 533.478271,216.698181 
	C533.440735,214.809677 533.617615,213.139572 534.230896,211.649063 
	C542.122681,192.469299 548.784546,173.163086 546.146484,151.768417 
	C544.892212,141.596802 540.980713,132.937973 534.873169,124.630432 
	C531.501038,121.855583 528.273071,119.376114 524.895142,116.607292 
	C523.512634,115.504219 522.280212,114.690498 520.864014,113.574890 
	C517.137207,111.809669 513.594177,110.346344 509.789429,108.615311 
	C503.724640,107.199333 497.921600,106.051079 492.030090,104.567406 
	C490.949829,103.847267 489.958008,103.462540 488.555603,103.045776 
	C485.771545,102.750237 483.398102,102.486725 480.558899,102.151543 
	C474.743652,102.109612 469.394135,102.139351 463.708130,102.118790 
	C462.575928,102.359703 461.780182,102.650940 460.553375,102.948608 
	C455.538605,103.530800 450.890228,103.798286 446.384064,104.743706 
	C435.562042,107.014236 424.806763,109.602867 413.644104,112.076889 
	C394.354767,117.463127 376.173492,124.716133 358.460144,133.255814 
	C359.985718,133.580170 361.270172,133.207367 362.532379,132.770187 
	C367.074493,131.196976 371.536407,129.337891 376.160492,128.066071 
	C388.738403,124.606590 401.383698,121.392120 414.415649,118.071075 
	C416.204407,117.988060 417.579834,117.914536 419.251221,117.873238 
	C420.038757,117.631386 420.530304,117.357307 421.480774,117.073799 
	C430.194031,116.713120 438.446228,116.170532 446.703369,116.081078 
	C451.798828,116.025871 456.901794,116.661232 462.402466,117.008484 
	C468.837341,119.164009 475.001404,120.991547 480.877960,123.494217 
	C493.523529,128.879608 500.805634,138.838562 504.035034,152.279816 
	C504.320129,153.059525 504.611053,153.543015 504.921082,154.463745 
	C504.837158,156.350677 504.734161,157.800385 504.380066,159.224457 
	C503.809845,159.099030 503.490723,158.999222 503.171631,158.899414 
	C502.250854,155.819489 501.330078,152.739578 500.291260,148.983765 
	C499.845428,147.513397 499.517609,146.718918 499.189789,145.924423 
	C497.745026,143.357224 496.300293,140.790009 494.404999,137.746185 
	C492.011017,135.424194 490.067566,133.578827 488.124146,131.733459 
	C483.901489,126.136597 477.632721,124.337807 470.743958,122.387215 
	C467.797638,121.426445 465.402283,120.745667 463.006927,120.064888 
	C459.659943,119.623306 456.312958,119.181717 452.182983,118.528015 
	C445.244934,118.490578 439.089874,118.665245 432.934814,118.839912 
	C430.245087,118.910881 427.555359,118.981834 424.046631,119.108917 
	C420.784882,119.696457 418.342102,120.227882 415.899323,120.759323 
	C414.849152,120.845291 413.798950,120.931267 411.993530,121.055603 
	C409.132782,121.664040 407.027283,122.234116 404.921814,122.804199 
	C404.486908,122.838142 404.051971,122.872078 402.974609,122.952103 
	C401.530884,123.270424 400.729645,123.542671 399.928375,123.814919 
	C399.491028,123.855331 399.053650,123.895744 397.926880,124.022415 
	C394.473236,125.024963 391.708984,125.941246 388.944763,126.857529 
	C387.520996,127.156136 386.097229,127.454750 384.036011,127.869568 
	C382.914246,128.275253 382.429932,128.564713 381.945618,128.854156 
	C379.535095,129.542221 377.124542,130.230286 373.993286,131.011902 
	C371.667786,131.999222 370.063049,132.892960 368.458282,133.786713 
	C367.834808,133.754272 367.211334,133.721832 365.985443,133.782410 
	C364.892059,134.176407 364.401093,134.477402 363.910126,134.778381 
	C352.801697,139.442932 341.693237,144.107483 329.961060,148.929169 
	C328.683594,149.160263 328.029907,149.234207 327.072418,149.148834 
	C326.172424,148.317276 325.576233,147.645050 324.980042,146.972809 
	C326.043182,146.314987 327.106354,145.657150 328.806580,144.955078 
	C329.981689,144.372009 330.519684,143.833206 331.057678,143.294403 
	C336.505432,140.613281 341.953156,137.932159 347.976776,135.012573 
	C349.081146,134.281494 349.609650,133.788864 350.138184,133.296234 
	C350.921875,133.035110 351.705536,132.773972 353.057617,132.345398 
	C354.123688,131.879990 354.621368,131.582047 355.119049,131.284119 
	C355.904877,130.999634 356.690735,130.715164 358.045959,130.254074 
	C359.087891,129.750397 359.560425,129.423325 360.032959,129.096283 
	C362.141083,128.132858 364.249176,127.169434 367.015808,126.075912 
	C368.294525,125.365303 368.914673,124.784782 369.534821,124.204254 
	C369.534821,124.204247 369.765106,124.396011 370.294037,124.333206 
	C373.566620,123.227104 376.310303,122.183800 379.053955,121.140503 
	C380.817993,120.470230 382.582001,119.799957 385.006165,119.026421 
	C386.808319,118.355804 387.950378,117.788445 389.092407,117.221077 
	C389.525543,117.160599 389.958649,117.100121 390.987488,116.968781 
	C392.067535,116.634171 392.551819,116.370438 393.036133,116.106705 
	C394.145569,115.761337 395.255005,115.415970 396.971802,114.980911 
	C398.070923,114.643616 398.562622,114.396027 399.054321,114.148438 
	C402.811768,112.895309 406.569244,111.642189 411.009735,110.285034 
	C412.492920,109.862938 413.293030,109.544891 414.093170,109.226837 
	C415.203339,108.983498 416.313477,108.740173 418.085785,108.344009 
	C419.863190,107.875572 420.978485,107.559967 422.093750,107.244354 
	C422.943329,107.090775 423.792908,106.937202 425.262085,106.529190 
	C427.279144,105.910278 428.676636,105.545792 430.074097,105.181313 
	C431.487946,104.883636 432.901794,104.585960 435.047638,104.220490 
	C437.204285,103.814690 438.628906,103.476685 440.053558,103.138672 
	C442.772766,102.818810 445.491974,102.498947 448.992126,102.110413 
	C451.202423,101.746399 452.631805,101.451050 454.061188,101.155701 
	C455.768036,101.066666 457.474915,100.977631 459.871979,100.857834 
	C461.065552,100.614960 461.568939,100.402847 462.072296,100.190735 
	C466.410553,100.160950 470.748810,100.131157 475.918427,100.104202 
	C477.495789,100.069977 478.241791,100.032921 478.987793,99.995865 
	C481.009003,100.078911 483.030182,100.161949 485.691895,100.428772 
	C486.885590,100.640106 487.438721,100.667656 487.991882,100.695213 
	C489.357880,100.866165 490.723877,101.037109 492.782532,101.438004 
	C495.645752,102.031631 497.816376,102.395309 499.986969,102.758980 
	C500.716278,102.899826 501.445557,103.040665 502.781952,103.426559 
	C504.254364,103.812416 505.119690,103.953224 505.985016,104.094025 
	C508.734100,105.095222 511.483154,106.096420 514.800537,107.384636 
	C516.551392,108.175461 517.733948,108.679268 518.916504,109.183075 
	C519.406982,109.475075 519.897461,109.767090 520.884033,110.324226 
	C522.900696,111.431915 524.421326,112.274475 525.941956,113.117043 
	C526.773865,113.631859 527.605774,114.146667 528.673584,114.841782 
z"/>
<path fill="#F8F8F8" opacity="1.000000" stroke="none" 
	d="
M117.333649,570.633179 
	C110.211563,571.372437 103.451569,571.945190 96.279221,572.552979 
	C96.279221,554.695374 96.279221,537.499146 96.279221,519.448914 
	C102.730797,519.768982 109.367256,519.564331 115.819077,520.558289 
	C123.283531,521.708191 126.892639,527.673828 128.830170,534.250916 
	C131.322510,542.711365 131.189819,551.319092 128.020905,559.707031 
	C126.114403,564.753479 122.979240,568.647766 117.333649,570.633179 
M107.280678,528.325317 
	C106.487747,529.060608 105.014511,529.786438 105.004166,530.532593 
	C104.854752,541.304932 104.901878,552.080017 104.901878,562.814758 
	C116.674728,563.555176 120.586220,560.103760 121.099960,548.912842 
	C121.198967,546.756042 121.186127,544.583496 121.054825,542.428589 
	C120.498436,533.297180 117.187271,529.734375 107.280678,528.325317 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M267.215179,345.176727 
	C257.354797,352.367401 246.462158,352.080688 235.519318,347.231598 
	C226.114197,343.063843 223.154465,335.180176 223.208557,325.246155 
	C223.404556,289.257874 223.188675,253.267410 223.337189,217.278641 
	C223.356720,212.543121 222.020920,210.857101 217.141891,211.027786 
	C206.663071,211.394394 196.164963,211.259293 185.675003,211.231934 
	C181.786804,211.221802 179.976028,212.668777 179.988068,216.850861 
	C180.098663,255.339294 179.722794,293.832672 180.221375,332.315125 
	C180.535141,356.534332 196.628296,376.719971 221.610321,385.659180 
	C227.677048,387.830017 234.154816,388.852081 240.738327,390.654633 
	C237.860443,391.033356 234.411835,392.025299 231.568634,391.118683 
	C224.030045,388.714874 216.773224,385.437225 209.355591,382.636200 
	C207.809921,382.052521 206.048172,382.056030 204.398148,381.727875 
	C203.480988,381.545471 201.825134,381.301147 201.797806,380.975922 
	C201.201065,373.869476 193.726654,372.599762 190.479187,367.439178 
	C183.181519,355.842377 177.856171,344.110107 177.932281,330.090332 
	C178.131226,293.439209 178.000214,256.786346 178.000748,220.134171 
	C178.000778,217.342926 178.000748,214.551697 178.000748,210.871613 
	C180.321686,210.349152 183.304947,209.161453 186.313492,209.093506 
	C196.912598,208.854233 207.520432,208.984482 218.124878,209.003876 
	C224.675644,209.015839 225.990433,210.323776 225.992523,216.889511 
	C226.004196,253.541687 226.029205,290.193939 225.969391,326.845978 
	C225.958801,333.339478 229.729767,338.123566 232.826569,343.230957 
	C233.286346,343.989258 234.496750,344.892609 235.223404,344.787292 
	C238.789307,344.270447 242.686844,347.525116 244.219070,347.100647 
	C248.609879,345.884216 252.958038,350.036835 257.355591,346.183807 
	C259.306976,344.474060 263.552765,345.382965 267.215179,345.176727 
z"/>
<path fill="#F6F6F6" opacity="1.000000" stroke="none" 
	d="
M335.674164,557.782349 
	C330.895264,569.681091 323.350586,574.533203 313.585846,572.267029 
	C306.195740,570.552002 302.610504,565.192322 300.673126,558.468018 
	C298.074615,549.448914 298.231018,540.369141 301.245636,531.490295 
	C304.232208,522.694153 310.566223,518.456543 319.329468,518.887512 
	C327.824799,519.305298 333.307892,524.227661 335.713806,533.828186 
	C333.062164,534.593323 330.399719,535.361511 327.737396,536.129639 
	C326.479553,534.099670 325.614319,532.073547 324.206543,530.548218 
	C319.816711,525.791626 312.438049,527.258972 310.102692,533.498230 
	C307.340363,540.878235 307.453400,548.521423 309.435059,556.084351 
	C310.508301,560.180420 312.761200,563.560730 317.474823,563.751343 
	C321.820099,563.927124 324.452240,561.184509 326.215607,557.476746 
	C326.769623,556.311829 327.369812,555.168945 328.309875,553.297119 
	C330.955566,554.755981 333.380951,556.093384 335.674164,557.782349 
z"/>
<path fill="#F5F5F5" opacity="1.000000" stroke="none" 
	d="
M229.599121,549.517639 
	C233.824478,548.476624 237.676453,547.576782 242.665146,546.411438 
	C241.259125,544.337891 240.410370,541.772949 239.238556,541.615662 
	C236.275574,541.218140 231.782822,540.735046 230.391251,542.384949 
	C226.968155,546.443604 223.826370,544.725769 219.971527,543.866699 
	C221.361481,538.135132 224.504684,534.455505 230.283188,533.830872 
	C233.866669,533.443481 237.586014,533.367676 241.141129,533.875244 
	C247.136642,534.731262 249.750275,537.884338 250.015930,543.992371 
	C250.225235,548.804688 250.000458,553.635803 250.208633,558.448181 
	C250.399048,562.849915 250.911499,567.237610 251.300476,571.849854 
	C248.189285,571.392517 243.782959,574.268738 241.879684,568.523987 
	C236.172562,572.809570 230.201767,574.813171 223.757889,570.921753 
	C219.410889,568.296631 218.352875,563.917358 219.044479,559.164795 
	C219.887314,553.373169 224.050140,550.922180 229.599121,549.517639 
M227.732376,560.621460 
	C228.263580,566.076721 232.104462,565.748291 235.805344,564.943359 
	C240.908127,563.833435 242.629807,559.881287 241.226654,553.171509 
	C239.153854,553.704529 236.900345,553.888855 235.055222,554.853821 
	C232.485458,556.197937 230.221359,558.126343 227.732376,560.621460 
z"/>
<path fill="#F5F5F5" opacity="1.000000" stroke="none" 
	d="
M139.667694,538.822571 
	C145.296295,532.675354 157.553391,530.926575 163.620880,535.870605 
	C165.853226,537.689636 167.229752,541.368347 167.669250,544.391724 
	C168.357864,549.128845 167.807037,554.034058 167.991516,558.860046 
	C168.154602,563.125549 168.604950,567.380127 168.940384,571.796448 
	C165.977783,571.262207 161.443222,574.521240 159.952927,569.411926 
	C155.858749,570.621399 152.027969,572.408142 148.067825,572.766846 
	C142.363449,573.283569 137.924042,569.400818 136.872910,564.154602 
	C135.605743,557.830139 137.885986,553.135193 143.633560,550.813416 
	C146.079132,549.825500 148.711380,549.288757 151.274612,548.606384 
	C153.827652,547.926758 156.400513,547.321533 160.060501,546.411072 
	C158.861053,544.423218 158.060577,541.731873 157.027573,541.639404 
	C153.882477,541.358032 149.291931,540.882019 147.725296,542.655701 
	C144.291122,546.543823 141.380875,544.558228 137.502182,543.874634 
	C138.242645,542.058594 138.841034,540.591003 139.667694,538.822571 
M155.344299,554.027161 
	C153.132019,554.680176 150.504883,554.808716 148.826233,556.136414 
	C147.205490,557.418274 145.626434,560.028687 145.808090,561.880981 
	C145.936752,563.192932 148.971695,565.092285 150.805328,565.207947 
	C157.422318,565.625488 160.267349,561.246216 158.933685,553.199402 
	C157.979721,553.432129 157.041504,553.661072 155.344299,554.027161 
z"/>
<path fill="#F70101" opacity="1.000000" stroke="none" 
	d="
M485.003662,240.003174 
	C485.003540,240.169601 484.949982,240.359406 485.011078,240.499069 
	C487.309875,245.753235 486.497070,249.117722 480.500641,251.591446 
	C478.747009,252.314880 478.955017,257.793304 478.263885,261.092316 
	C478.820648,261.328705 479.377380,261.565125 479.934113,261.801544 
	C473.415802,265.880371 466.856110,269.895752 460.414459,274.092316 
	C459.145660,274.918915 458.220184,276.374542 457.394531,277.700806 
	C457.210388,277.996582 458.348053,279.586700 458.482086,279.536011 
	C459.803314,279.036499 461.041107,278.316406 462.955780,277.318298 
	C462.340942,280.455170 461.795898,283.235748 461.250885,286.016357 
	C458.781647,285.133545 456.312408,284.250702 453.343079,283.189056 
	C453.923615,285.005676 454.420471,286.560425 454.936462,288.175110 
	C448.824615,288.896179 447.963593,290.684082 448.226654,301.497955 
	C443.329102,302.459991 438.240540,302.906891 433.670288,304.685638 
	C432.252869,305.237244 431.190552,309.727600 431.838959,311.839600 
	C432.772858,314.881409 433.558685,315.676849 429.694580,317.398041 
	C424.611786,319.662109 420.476685,324.053680 415.363220,327.967346 
	C415.311951,327.926971 414.241180,327.083313 412.624603,325.809601 
	C412.330292,330.298767 405.593353,330.911438 407.728088,336.285034 
	C406.215118,336.555725 404.563995,337.311340 403.235901,336.932983 
	C402.291199,336.663849 401.781738,334.867096 401.384644,333.414001 
	C407.651642,334.589264 404.476685,331.039154 404.100952,328.691956 
	C404.535309,327.681915 404.837372,326.947144 405.139435,326.212372 
	C405.555237,325.822693 405.971039,325.433014 406.975555,325.156891 
	C408.684540,324.473785 409.804810,323.677155 411.057861,322.628540 
	C411.767334,321.898590 412.344116,321.420715 413.305023,320.958252 
	C414.787415,319.640900 415.885651,318.308075 417.142212,316.684052 
	C424.857452,308.588837 432.414368,300.784821 440.373810,292.988892 
	C444.766205,293.413818 446.654968,292.126740 445.070129,287.599976 
	C449.673981,281.816467 454.313446,276.471588 458.525726,270.809021 
	C465.836823,260.980652 472.883789,250.955765 480.256927,240.899765 
	C480.740906,240.576813 480.909241,240.305237 480.979004,239.983566 
	C480.980621,239.995590 480.963928,239.967804 481.367432,239.978973 
	C482.844177,239.990311 483.917358,239.990479 484.994141,239.994202 
	C484.997681,239.997742 485.003662,240.003174 485.003662,240.003174 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M465.663727,548.750610 
	C468.170654,536.382446 476.798859,530.709900 487.378143,534.120239 
	C494.852478,536.529663 499.192719,545.319641 497.859985,555.767517 
	C490.152100,555.767517 482.391876,555.767517 474.329468,555.767517 
	C474.071320,560.126770 475.493317,563.308105 479.369904,564.576111 
	C483.760254,566.012024 487.648224,565.133972 489.467468,560.098450 
	C492.332428,560.596619 494.906677,561.044189 497.498566,561.494812 
	C495.633118,568.996277 490.453613,572.918152 482.671906,572.927612 
	C474.134460,572.938110 467.714172,568.159973 466.117706,560.031982 
	C465.425873,556.509766 465.724609,552.792908 465.663727,548.750610 
M474.252930,547.115417 
	C474.703064,547.766052 475.136810,548.968018 475.605865,548.982056 
	C480.317383,549.122192 485.034698,549.067322 489.913757,549.067322 
	C489.459442,544.717712 488.046722,541.709900 483.923798,541.035400 
	C479.471161,540.306946 476.106537,541.823853 474.252930,547.115417 
z"/>
<path fill="#F5F5F5" opacity="1.000000" stroke="none" 
	d="
M345.311646,566.605774 
	C340.825897,559.932983 340.334595,552.964966 342.203491,545.779175 
	C344.198700,538.107544 350.444366,533.328125 358.179932,533.191467 
	C365.820343,533.056458 372.383575,537.163574 374.165710,544.828613 
	C375.357330,549.953796 375.671631,555.886414 374.299683,560.887390 
	C370.657623,574.163452 355.392365,577.080383 345.311646,566.605774 
M366.961456,553.417236 
	C366.683105,551.458923 366.576965,549.456665 366.089966,547.551697 
	C365.123291,543.770447 362.585510,541.433533 358.667908,541.340820 
	C354.492157,541.242004 351.442230,543.625183 350.923645,547.644043 
	C350.410217,551.622559 350.219299,556.045837 351.505890,559.704712 
	C352.298676,561.959229 356.143555,564.477539 358.581116,564.440552 
	C361.015594,564.403442 363.704193,561.608521 365.691071,559.473267 
	C366.751404,558.333740 366.564819,556.034058 366.961456,553.417236 
z"/>
<path fill="#F4F4F4" opacity="1.000000" stroke="none" 
	d="
M456.465698,571.466919 
	C453.704834,571.833923 451.231201,571.833923 448.024475,571.833923 
	C448.024475,564.671814 448.145599,557.574829 447.987518,550.484009 
	C447.852295,544.415894 445.612579,541.348999 441.750122,541.302673 
	C437.488312,541.251587 434.101898,544.791931 433.843109,550.480469 
	C433.526855,557.431213 433.767792,564.407410 433.767792,571.696167 
	C430.630066,571.696167 428.081970,571.696167 425.261719,571.696167 
	C425.261719,559.372437 425.261719,546.982910 425.261719,534.319275 
	C427.726074,534.319275 430.138947,534.319275 432.813507,534.319275 
	C432.938965,535.465698 433.068359,536.647644 433.236786,538.186462 
	C434.984436,536.953186 436.236145,535.896912 437.638550,535.108643 
	C445.926666,530.450623 454.913574,534.324585 455.937653,543.725830 
	C456.923126,552.772766 456.536591,561.969238 456.465698,571.466919 
z"/>
<path fill="#F5F5F5" opacity="1.000000" stroke="none" 
	d="
M412.917603,538.227173 
	C413.926483,542.752502 415.234894,546.912537 415.486664,551.135498 
	C415.890533,557.910278 415.594940,564.726868 415.594940,571.770142 
	C412.629333,571.770142 410.061523,571.770142 407.042206,571.770142 
	C407.042206,567.018127 407.068481,562.394836 407.032837,557.771973 
	C407.009705,554.774658 407.046204,551.764771 406.784943,548.784363 
	C406.460663,545.084778 405.260223,541.553040 400.863495,541.420532 
	C396.492126,541.288940 393.398865,543.885742 393.018036,548.244568 
	C392.339142,556.014160 392.229156,563.833435 391.872437,571.830200 
	C389.461121,571.830200 387.003967,571.830200 384.224823,571.830200 
	C384.224823,559.472595 384.224823,547.070496 384.224823,534.352661 
	C386.415497,534.352661 388.670288,534.352661 391.386261,534.352661 
	C391.651276,535.285156 392.007874,536.539673 392.378845,537.844910 
	C401.023987,531.633240 406.840912,531.677795 412.917603,538.227173 
z"/>
<path fill="#0979A9" opacity="1.000000" stroke="none" 
	d="
M112.906830,468.148499 
	C120.560394,471.485077 127.945824,475.818909 135.920044,477.962830 
	C156.187485,483.411926 176.817810,482.291779 197.282028,478.748840 
	C209.039139,476.713348 220.656952,473.873169 232.579834,471.787445 
	C226.382919,474.110809 220.022568,476.386169 213.484268,477.885468 
	C205.410187,479.736938 197.188202,480.943268 189.030136,482.428406 
	C195.860687,482.650604 202.531097,481.749207 209.200897,480.843292 
	C213.796310,480.219116 218.390869,479.588623 222.751801,479.247559 
	C216.982574,480.952698 211.447372,482.371216 205.912170,483.789734 
	C205.174530,483.792389 204.436874,483.795044 202.993317,483.874512 
	C201.171066,484.249237 200.054764,484.547180 198.938446,484.845093 
	C198.500458,484.839203 198.062454,484.833344 196.956451,484.866760 
	C195.158035,485.182465 194.027649,485.458893 192.897263,485.735291 
	C191.528564,485.773621 190.159866,485.811920 188.040405,485.896698 
	C186.166763,486.228699 185.043900,486.514221 183.921021,486.799744 
	C183.184952,486.792511 182.448883,486.785278 180.890900,486.791504 
	C173.045624,486.916931 166.022247,487.028870 158.998886,487.140808 
	C156.976212,487.005096 154.953552,486.869354 152.179779,486.557922 
	C148.283539,486.048950 145.138397,485.715698 141.993256,485.382477 
	C141.259949,485.207214 140.526657,485.031921 139.141815,484.644836 
	C136.320740,484.041290 134.151230,483.649536 131.981720,483.257812 
	C131.567108,483.102539 131.152496,482.947296 130.142990,482.568665 
	C126.037933,481.173126 122.527771,480.000946 119.017609,478.828766 
	C118.767975,478.588989 118.470222,478.477173 117.757561,478.261658 
	C112.541092,474.513916 107.691429,470.997864 102.773407,467.155365 
	C102.139961,466.553345 101.574860,466.277771 100.720535,465.875183 
	C99.277573,464.861511 98.123833,463.974915 96.970085,463.088287 
	C96.527618,462.424744 96.085152,461.761200 95.295876,460.668884 
	C93.331161,457.820038 91.713272,455.400024 90.340836,452.757507 
	C91.156914,451.625336 91.727539,450.715668 92.559631,449.971069 
	C93.645256,451.276764 94.469398,452.417389 95.322685,453.870605 
	C95.694427,454.382996 96.037018,454.582855 96.635880,454.971741 
	C98.042946,456.106903 99.193726,457.052979 100.395645,458.281982 
	C101.316887,459.274414 102.186996,459.983917 103.125565,460.973877 
	C103.533875,461.718658 103.873734,462.183014 104.247177,462.931793 
	C104.592651,463.398041 104.904541,463.579895 105.113045,463.851318 
	C105.009651,463.940948 105.233109,463.783325 105.299911,464.063782 
	C105.710052,464.475098 106.053383,464.605957 106.562950,464.768982 
	C106.729195,464.801117 107.008644,464.992371 107.066505,465.340088 
	C107.893883,466.032013 108.663399,466.376221 109.712761,466.870728 
	C110.964012,467.396851 111.935417,467.772675 112.906830,468.148499 
z"/>
<path fill="#F4F4F4" opacity="1.000000" stroke="none" 
	d="
M531.641357,546.579041 
	C529.663269,542.090576 526.265320,540.693420 522.102112,541.345947 
	C518.012817,541.986877 515.850342,544.962952 515.112061,548.846985 
	C514.744141,550.782593 514.778259,552.828064 514.875122,554.811951 
	C515.102539,559.471008 516.616577,563.332214 521.787781,564.462830 
	C527.267334,565.660767 530.183350,562.621643 532.235107,557.596252 
	C534.932312,558.082947 537.648987,558.573181 540.373352,559.064758 
	C538.436462,568.601501 531.766357,573.579834 522.160034,572.857849 
	C512.907715,572.162476 506.764954,565.631470 506.023956,555.701965 
	C505.140289,543.860901 510.154907,535.663757 519.450439,533.754456 
	C529.640442,531.661499 536.739197,535.444458 540.109802,545.249084 
	C537.356567,545.767456 534.670471,546.273193 531.641357,546.579041 
z"/>
<path fill="#F2F2F2" opacity="1.000000" stroke="none" 
	d="
M208.030823,571.330139 
	C207.186111,571.599243 206.718781,571.815857 206.231216,571.880981 
	C190.185379,574.025146 186.410538,570.736389 186.405350,554.625732 
	C186.404068,550.647949 186.405151,546.670227 186.405151,542.384521 
	C183.556824,542.247742 181.114914,542.130493 178.372437,541.998779 
	C178.372437,539.535461 178.372437,537.144165 178.372437,534.324890 
	C180.851013,534.324890 183.278168,534.324890 186.449722,534.324890 
	C185.040146,527.168274 187.995010,523.041382 195.007172,521.458679 
	C195.007172,525.608704 195.007172,529.681396 195.007172,534.067139 
	C198.671326,534.067139 201.943222,534.067139 205.594116,534.067139 
	C205.594116,536.774658 205.594116,539.171265 205.594116,542.002014 
	C202.333572,542.002014 198.914108,542.002014 195.033051,542.002014 
	C195.033051,548.185486 194.988281,553.959656 195.050354,559.732727 
	C195.085831,563.031616 197.015503,564.594849 200.176315,564.478210 
	C202.655258,564.386719 205.122269,563.972046 208.737518,563.575684 
	C208.621078,566.198975 208.509933,568.702942 208.030823,571.330139 
z"/>
<path fill="#F8F8F8" opacity="1.000000" stroke="none" 
	d="
M577.545898,571.683594 
	C559.529541,573.625122 556.969482,571.312134 556.955688,553.557007 
	C556.952881,549.926514 556.955322,546.296082 556.955322,542.276428 
	C554.136719,542.276428 551.710144,542.276428 548.988464,542.276428 
	C548.988464,539.479492 548.988464,537.078186 548.988464,534.351501 
	C551.515442,534.239685 553.946350,534.132080 557.090698,533.992920 
	C555.222839,527.181091 558.560730,523.394897 565.162476,521.398254 
	C565.162476,525.488770 565.162476,529.560059 565.162476,533.975891 
	C568.943848,533.975891 572.232544,533.975891 575.934998,533.975891 
	C575.934998,536.690308 575.934998,539.069458 575.934998,542.025635 
	C572.697205,542.025635 569.305237,542.025635 565.527161,542.025635 
	C565.527161,548.343262 565.442017,554.119263 565.560364,559.890991 
	C565.627258,563.154236 567.602478,564.700806 570.798889,564.505554 
	C573.275574,564.354248 575.739075,563.986389 578.708923,563.659729 
	C578.005859,566.328369 582.590271,569.100159 577.545898,571.683594 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M349.130249,369.313721 
	C347.430450,366.667694 346.484924,364.286743 341.977203,363.967926 
	C338.808136,363.743774 334.643982,359.063934 333.315887,355.485962 
	C331.076172,349.452087 330.030945,342.743469 329.759125,336.265106 
	C329.358337,326.712341 329.917847,317.102783 330.509399,307.544739 
	C330.587555,306.281860 333.235504,304.195251 334.733704,304.158966 
	C346.385712,303.876770 358.047638,304.025970 369.706390,303.993561 
	C374.326569,303.980713 375.782196,306.984863 375.941345,310.789246 
	C376.164032,316.109772 375.881226,321.450531 376.063538,326.774078 
	C376.134216,328.839233 376.704834,330.940643 377.363922,332.920227 
	C378.455627,336.199280 376.030029,341.010559 371.720978,343.917572 
	C369.958527,345.106628 368.155457,346.340546 366.711670,347.874756 
	C363.854675,350.910583 364.408386,352.491455 368.843567,354.470825 
	C362.442688,359.665192 355.786469,364.489471 349.130249,369.313721 
M331.681915,310.807373 
	C331.681091,318.796509 330.843018,326.899963 331.913483,334.742859 
	C332.998169,342.689606 335.685760,350.473297 338.236816,358.140198 
	C339.474091,361.858582 342.273407,363.008362 345.999420,360.062408 
	C354.732391,353.157837 363.710724,346.563324 372.430939,339.643341 
	C373.632172,338.690094 374.546112,336.598694 374.543945,335.035706 
	C374.532928,327.055084 374.103394,319.076080 373.982422,311.094025 
	C373.939575,308.267853 373.096863,306.752228 369.996918,306.734497 
	C358.685364,306.669891 347.372894,306.351196 336.065521,306.497467 
	C334.606171,306.516327 333.174805,308.699066 331.681915,310.807373 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M455.866791,255.879898 
	C455.663025,255.349716 455.721893,254.754028 456.050598,254.452499 
	C467.564636,243.890640 467.503235,230.150345 466.679626,216.160385 
	C466.478424,212.742767 465.175018,211.062363 461.486450,211.089935 
	C440.688477,211.245407 419.889435,211.273056 399.090698,211.310745 
	C395.530701,211.317200 394.042297,212.979431 394.072479,216.532166 
	C394.157318,226.513702 394.101807,236.496582 394.070709,246.478836 
	C394.060333,249.815338 395.652954,251.261490 398.985382,251.244827 
	C404.473083,251.217377 409.975891,251.186478 415.440765,251.587646 
	C416.958710,251.699081 418.365143,253.328857 419.690704,254.640182 
	C413.839020,252.722839 408.055603,252.463089 401.995605,253.746414 
	C395.240295,255.177002 391.581879,252.541595 391.890045,245.594528 
	C392.317963,235.949097 391.981323,226.270447 392.005341,216.606094 
	C392.018433,211.331085 393.902130,209.067322 398.979218,209.043839 
	C420.473724,208.944412 441.969147,208.954361 463.463776,209.040283 
	C468.356232,209.059845 470.491180,212.955566 469.463623,217.652573 
	C468.661438,221.319382 469.179993,225.263138 468.975739,229.076553 
	C468.814636,232.084015 468.297577,235.077393 468.237579,238.083008 
	C468.210419,239.442215 468.909180,240.815903 469.373901,242.535706 
	C466.055267,246.922516 462.479675,251.648926 458.757416,256.569275 
	C458.167328,256.423584 457.211273,256.187592 455.866791,255.879898 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M320.886475,211.873169 
	C307.769379,211.928116 294.639160,211.563370 281.507416,211.263138 
	C278.423065,211.192612 277.680267,213.158447 277.667419,215.875061 
	C277.619324,226.031601 277.369965,236.187759 277.379669,246.343887 
	C277.404572,272.319946 277.660645,298.296844 277.489899,324.271179 
	C277.459137,328.952087 275.834412,333.622528 274.613251,338.504639 
	C274.520599,330.359222 274.943787,322.007446 274.967041,313.654541 
	C275.051117,283.449615 275.034729,253.244186 274.955505,223.039215 
	C274.946289,219.518280 274.309143,215.999008 273.847015,211.298050 
	C276.736511,210.633041 280.268127,209.226913 283.841522,209.111191 
	C293.816437,208.788162 303.812836,208.844864 313.791016,209.113022 
	C316.171051,209.176987 318.513184,210.651382 320.886475,211.873169 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M412.920837,320.942810 
	C412.344116,321.420715 411.767334,321.898590 410.691254,322.648163 
	C409.705475,322.862762 409.219025,322.805695 408.732574,322.748657 
	C408.792969,322.353943 408.853363,321.959229 409.251587,321.168304 
	C410.427582,319.866364 411.265747,318.960632 412.103943,318.054932 
	C418.001770,312.297913 423.899567,306.540894 430.235046,300.323364 
	C432.437592,297.908813 434.202484,295.954803 435.967346,294.000793 
	C438.566772,291.209656 441.166168,288.418518 444.185547,285.192993 
	C445.155334,283.875336 445.705170,282.992065 446.255035,282.108826 
	C447.117920,281.299805 447.980774,280.490784 449.242615,279.244751 
	C450.798157,277.232574 451.954742,275.657410 453.111328,274.082275 
	C457.109192,268.992889 461.107025,263.903534 465.435974,258.327911 
	C466.193268,257.238068 466.619446,256.634491 467.045654,256.030945 
	C467.429199,255.367096 467.812714,254.703232 468.495483,253.605209 
	C469.840302,251.776733 470.885895,250.382416 471.931519,248.988098 
	C473.991760,245.924927 476.052002,242.861740 478.436462,239.285690 
	C479.221832,237.870911 479.682953,236.968979 480.144043,236.067047 
	C480.460571,235.658401 480.777069,235.249741 481.386475,234.379791 
	C482.165283,232.966385 482.651184,232.014282 483.137085,231.062164 
	C483.801910,229.958435 484.466766,228.854706 485.412048,227.232666 
	C486.137817,225.814377 486.583099,224.914398 487.028381,224.014404 
	C489.033936,219.931122 491.039490,215.847839 493.315796,211.230469 
	C493.759491,210.147903 493.932495,209.599426 494.105530,209.050934 
	C495.816376,204.332535 497.527222,199.614136 499.466461,194.300949 
	C499.851257,193.144684 500.007660,192.583206 500.164032,192.021729 
	C500.832489,188.967697 501.500946,185.913666 502.386230,182.269867 
	C502.682983,181.123764 502.762939,180.567429 502.842896,180.011078 
	C502.967316,178.974762 503.091797,177.938446 503.457214,176.454269 
	C504.030579,175.928070 504.363068,175.849731 504.843018,176.221512 
	C502.978241,185.776947 500.965912,194.882294 498.700134,204.190826 
	C498.240234,205.224884 498.033844,206.055725 497.633240,207.021973 
	C497.212524,207.389359 497.061127,207.662903 496.779968,208.086639 
	C496.319794,208.406830 496.193085,208.678375 496.139099,209.337814 
	C496.041931,210.107040 496.000641,210.548370 495.699829,211.186646 
	C494.961609,212.590012 494.482971,213.796402 493.834595,215.328842 
	C490.763367,221.432693 487.861816,227.210495 484.691772,233.186478 
	C483.270142,235.579041 482.117035,237.773422 480.963928,239.967804 
	C480.963928,239.967804 480.980621,239.995590 480.759460,240.092468 
	C480.267670,240.400635 480.102051,240.675293 480.041504,241.013290 
	C472.883789,250.955765 465.836823,260.980652 458.525726,270.809021 
	C454.313446,276.471588 449.673981,281.816467 444.788757,287.760986 
	C442.890900,289.805359 441.431091,291.393097 439.971252,292.980835 
	C432.414368,300.784821 424.857452,308.588837 416.848816,316.812378 
	C415.238312,318.468872 414.079590,319.705841 412.920837,320.942810 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M348.954102,369.586487 
	C355.786469,364.489471 362.442688,359.665192 369.247375,354.533875 
	C369.866028,354.019135 370.336243,353.811493 371.237488,353.240295 
	C373.144836,351.659302 374.621094,350.441803 376.097351,349.224304 
	C380.956665,345.349670 385.815979,341.475037 391.153870,337.209473 
	C392.791809,335.623169 393.951172,334.427826 395.110565,333.232513 
	C395.561920,333.179108 396.013275,333.125702 396.799957,333.137726 
	C397.118530,333.626892 397.101807,334.050629 397.058533,334.794495 
	C387.061493,343.235443 377.196503,351.490570 367.083862,359.430328 
	C360.172180,364.856934 352.981079,369.937134 345.795746,375.003204 
	C336.819519,381.331970 327.909149,387.786316 318.620514,393.630402 
	C309.955414,399.082153 300.872498,403.879211 291.887573,408.807800 
	C288.951965,410.418091 285.746490,411.536407 282.008667,412.895874 
	C281.100891,412.836426 280.848969,412.760864 280.691925,412.434784 
	C280.885681,411.842407 280.984558,411.500549 281.083435,411.158691 
	C287.631866,407.712036 294.180267,404.265381 301.236938,400.446106 
	C302.334412,399.446655 302.923615,398.819855 303.512787,398.193054 
	C305.214691,397.648895 306.916565,397.104736 309.141815,396.190125 
	C311.523468,394.583771 313.381744,393.347839 315.240051,392.111908 
	C322.076172,387.686096 328.912292,383.260284 336.257202,378.473145 
	C337.338654,377.469421 337.911285,376.826996 338.483887,376.184540 
	C341.915253,374.076111 345.346619,371.967682 348.954102,369.586487 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M321.492615,336.903748 
	C321.073425,306.771210 321.039886,276.538269 321.011566,246.305298 
	C321.002380,236.502411 321.006927,226.699539 321.134094,216.442627 
	C322.174866,217.662262 323.867981,219.335968 323.867889,221.009583 
	C323.867462,229.803635 323.190735,238.598495 323.219238,247.391541 
	C323.258606,259.518616 323.674622,271.645569 324.016632,283.770111 
	C324.178436,289.507843 324.948181,295.255249 324.730682,300.971191 
	C324.283905,312.714722 323.361694,324.440247 322.599426,336.170868 
	C322.584778,336.396301 322.129822,336.593109 321.492615,336.903748 
z"/>
<path fill="#0979A9" opacity="1.000000" stroke="none" 
	d="
M280.991364,460.978729 
	C278.528870,461.873993 276.066376,462.769287 272.942657,463.869568 
	C270.160156,464.977417 268.038849,465.880249 265.917572,466.783081 
	C264.135895,467.385162 262.354187,467.987244 259.895691,468.758423 
	C251.447739,471.537994 243.676636,474.148438 235.905548,476.758850 
	C234.470474,477.044189 233.035400,477.329498 230.967453,477.778595 
	C229.543228,478.261139 228.751862,478.579926 227.960510,478.898682 
	C227.516159,478.942078 227.071823,478.985504 226.046417,479.024078 
	C225.290359,478.920410 225.115372,478.821594 225.033813,478.382599 
	C226.932724,477.370575 228.726807,476.665955 230.546036,476.033661 
	C234.381927,474.700500 238.230957,473.405151 242.462341,472.074615 
	C261.877625,464.615845 280.930298,457.240204 299.919525,449.704590 
	C306.960663,446.910400 313.852844,443.740753 320.910461,440.869812 
	C321.007996,440.997314 321.193390,441.259399 321.193390,441.259399 
	C318.916687,442.613800 316.639984,443.968170 313.788330,445.620850 
	C310.132050,447.586578 307.050720,449.254059 303.969391,450.921539 
	C302.489929,451.478119 301.010468,452.034698 298.908203,452.808899 
	C295.822601,454.268127 293.359741,455.509705 290.896881,456.751282 
	C290.097412,457.031158 289.297943,457.311035 287.886505,457.803619 
	C285.180176,459.003815 283.085754,459.991272 280.991364,460.978729 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M95.293533,453.558044 
	C94.469398,452.417389 93.645256,451.276764 92.503067,449.755890 
	C92.185013,449.375671 91.968765,448.986206 91.923447,448.690247 
	C91.607155,448.105743 91.336189,447.817169 91.134460,447.399567 
	C91.203690,447.270508 91.081398,447.004364 91.008484,446.727844 
	C90.678711,446.172699 90.421860,445.894073 90.150597,445.325378 
	C89.774414,444.357941 89.412636,443.680573 88.980400,442.725739 
	C88.620354,442.212738 88.330772,441.977203 87.848602,441.547729 
	C87.474380,441.368256 87.292740,441.382782 87.167343,441.046631 
	C86.149544,438.501099 87.473404,434.478760 82.865288,434.977112 
	C79.858803,430.981689 78.840378,417.514130 81.431099,413.604309 
	C81.925941,412.857483 84.034004,412.173157 84.153458,412.341766 
	C85.199356,413.817657 86.531494,415.433960 86.709335,417.121307 
	C87.411819,423.786835 86.334679,431.008636 88.569527,437.041779 
	C90.875122,443.265930 96.263268,448.348206 100.651398,453.965027 
	C101.000000,454.000000 100.999390,453.999054 101.026917,454.322479 
	C100.817802,455.763611 100.581161,456.881348 100.344513,457.999054 
	C99.193726,457.052979 98.042946,456.106903 96.516571,454.717804 
	C95.858505,454.035858 95.576019,453.796936 95.293533,453.558044 
z"/>
<path fill="#E60202" opacity="1.000000" stroke="none" 
	d="
M113.965759,442.000305 
	C110.598938,442.121521 106.833199,441.503479 105.662430,446.649292 
	C104.591164,446.625244 103.848694,446.255432 102.935310,445.664185 
	C102.256950,445.249176 101.749504,445.055634 101.193718,444.856628 
	C101.145378,444.851196 101.117844,444.757874 101.142044,444.398682 
	C100.002617,442.276398 98.838989,440.513245 97.675354,438.750122 
	C97.675354,438.750122 97.353065,438.763733 97.259857,438.506927 
	C97.057961,437.558960 96.949265,436.867767 96.923172,435.820190 
	C97.005814,434.976349 97.005844,434.488892 97.222862,433.828003 
	C96.981110,433.078918 96.522377,432.503326 95.924545,431.593384 
	C95.527893,429.841278 95.270332,428.423584 95.260712,426.823730 
	C96.339806,425.756012 97.170959,424.870422 98.223312,424.115540 
	C98.864235,424.533783 99.283974,424.821350 99.703712,425.108887 
	C99.703712,425.108887 100.144920,425.223022 100.424637,425.427979 
	C100.822510,425.930389 101.038139,426.111847 101.351212,426.177368 
	C101.799652,427.248199 102.248093,428.319061 102.830994,430.057800 
	C104.601669,432.177124 106.237877,433.628540 107.874084,435.079956 
	C109.050179,435.936798 110.226273,436.793640 111.904625,437.975952 
	C113.864113,439.079163 115.321350,439.856873 116.778580,440.634613 
	C116.778580,440.634613 116.461861,440.643402 116.441803,440.796448 
	C116.421738,440.949524 116.450287,440.982391 116.184593,440.972839 
	C115.267853,441.308960 114.616806,441.654633 113.965759,442.000305 
z"/>
<path fill="#097EB0" opacity="1.000000" stroke="none" 
	d="
M375.741577,409.882629 
	C375.242432,410.072449 374.743286,410.262268 373.759094,410.721252 
	C372.308197,411.910797 371.342346,412.831177 370.376495,413.751556 
	C369.668427,413.958313 368.960358,414.165039 367.776062,414.650452 
	C366.512329,415.597504 365.724884,416.265808 364.937408,416.934143 
	C364.937408,416.934143 365.002441,416.980011 364.633057,416.970947 
	C363.642029,417.564545 363.020355,418.167206 362.398682,418.769897 
	C362.398712,418.769897 362.180634,418.651093 361.750092,418.752686 
	C360.492889,419.520203 359.666229,420.186157 358.839569,420.852081 
	C358.839569,420.852081 358.985504,420.865112 358.582703,420.886169 
	C356.092499,422.201324 354.005066,423.495483 351.917664,424.789612 
	C348.725616,426.624023 345.533539,428.458374 341.817291,430.581421 
	C340.488434,431.483002 339.683777,432.095886 338.879089,432.708740 
	C338.332703,432.888550 337.786316,433.068298 336.716339,433.546753 
	C334.908386,434.801392 333.623962,435.757355 332.339539,436.713318 
	C332.339539,436.713318 331.866058,436.584015 331.866058,436.584015 
	C331.866058,436.584015 331.390656,436.705994 330.844910,436.869568 
	C328.153564,438.250183 326.007935,439.467224 323.862305,440.684235 
	C323.198090,440.843201 322.533875,441.002167 321.531525,441.210266 
	C321.193390,441.259399 321.007996,440.997314 321.050903,440.572021 
	C327.000458,436.519135 332.890045,432.863403 338.817322,429.269806 
	C351.096100,421.825287 363.415649,414.447815 375.676727,406.974396 
	C381.728149,403.285858 387.686829,399.445251 393.798950,395.793060 
	C393.910431,395.912659 394.131348,396.153748 393.956238,396.437744 
	C389.826752,399.752014 385.872345,402.782227 381.917908,405.812500 
	C381.015778,406.281525 380.113647,406.750549 378.732849,407.548340 
	C377.416687,408.545624 376.579132,409.214142 375.741577,409.882629 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M271.214539,389.210999 
	C280.886841,385.393402 290.980103,382.198517 299.050049,374.984192 
	C302.233246,372.138489 305.252045,369.108887 308.672852,366.081512 
	C309.931885,371.202179 307.689178,373.736725 302.775269,375.839264 
	C297.494476,378.098785 292.963776,382.102295 288.085358,385.313782 
	C283.036926,388.637207 277.449005,389.879547 271.214539,389.210999 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M419.228821,257.126465 
	C419.753174,266.764313 419.893219,276.539368 420.105255,286.312866 
	C420.157654,288.729340 420.443817,291.140778 420.739410,295.133636 
	C423.600525,292.441895 425.428528,290.722107 427.579407,288.881256 
	C426.500854,291.016602 425.538147,293.814484 423.588409,295.394073 
	C421.080750,297.425629 418.071716,295.857361 418.047729,292.858917 
	C417.957397,281.563293 418.010651,270.266357 418.065704,258.970215 
	C418.068512,258.399872 418.590729,257.832092 419.228821,257.126465 
z"/>
<path fill="#5D0508" opacity="1.000000" stroke="none" 
	d="
M284.919983,173.950027 
	C284.495514,173.980316 284.071045,174.010605 283.184723,173.800354 
	C282.368500,173.331406 282.014130,173.102997 281.659760,172.874603 
	C282.682465,171.778259 283.705200,170.681915 285.187256,169.268204 
	C286.426788,168.295547 287.206940,167.640244 287.987061,166.984955 
	C294.081512,163.674271 300.175964,160.363602 306.638611,157.432007 
	C307.031281,158.369614 307.055725,158.928162 307.080170,159.486694 
	C302.488190,162.463257 297.896240,165.439835 292.817719,168.757141 
	C290.889465,170.065231 289.447784,171.032593 288.006073,171.999939 
	C288.006073,171.999939 288.000000,171.999664 287.678680,172.032288 
	C286.544891,172.693268 285.732452,173.321655 284.919983,173.950027 
z"/>
<path fill="#BB0405" opacity="1.000000" stroke="none" 
	d="
M165.035995,450.226257 
	C161.566971,451.116699 158.033585,452.941711 154.641953,452.708191 
	C146.938980,452.177734 139.304825,450.640778 131.648682,449.473724 
	C131.436554,449.441406 131.292343,448.963623 131.058823,448.097839 
	C131.003845,447.329346 131.006500,447.156647 131.267365,446.877625 
	C131.686920,446.678406 131.848267,446.585510 132.009598,446.492615 
	C133.416489,446.875031 134.823380,447.257416 136.812012,448.039734 
	C138.414398,448.526886 139.435043,448.614105 140.786987,448.685913 
	C141.753494,448.544098 142.388718,448.417694 143.023926,448.291290 
	C143.213287,448.353973 143.402649,448.416656 144.069336,448.617004 
	C144.696854,448.836060 144.847031,448.917419 145.170746,449.246155 
	C146.216843,449.374664 147.089401,449.255768 148.406403,449.083923 
	C151.229004,448.935181 153.607178,448.839417 155.985352,448.743622 
	C157.063736,448.809387 158.142120,448.875153 160.020340,448.945984 
	C162.218323,449.009155 163.616455,449.067230 165.008484,449.400757 
	C165.013580,449.859558 165.024780,450.042908 165.035995,450.226257 
z"/>
<path fill="#780607" opacity="1.000000" stroke="none" 
	d="
M307.466217,159.388809 
	C307.055725,158.928162 307.031281,158.369614 306.997070,157.394165 
	C307.844360,156.150101 308.701385,155.322968 310.127441,154.261902 
	C312.157074,153.079819 313.617615,152.131653 315.078186,151.183502 
	C316.925171,150.305923 318.772156,149.428360 321.109680,148.259552 
	C322.068909,147.659012 322.537598,147.349701 323.006287,147.040375 
	C323.444733,147.016281 323.883148,146.992188 324.650818,146.970459 
	C325.576233,147.645050 326.172424,148.317276 326.977356,149.406250 
	C327.107697,150.182266 327.029266,150.541519 326.950806,150.900772 
	C326.398254,151.071396 325.845734,151.242035 324.827759,151.686081 
	C323.899353,152.277939 323.436310,152.596390 322.973297,152.914825 
	C322.413208,153.066467 321.853119,153.218094 320.818939,153.650757 
	C319.885101,154.254410 319.425354,154.577042 318.965607,154.899658 
	C318.407593,155.049271 317.849579,155.198868 316.780884,155.646698 
	C315.721924,156.574448 315.173645,157.203964 314.625366,157.833481 
	C314.258362,157.828659 313.891357,157.823837 312.953735,157.892487 
	C311.922943,158.304321 311.462738,158.642685 311.002533,158.981049 
	C309.952454,159.084351 308.902344,159.187637 307.466217,159.388809 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M557.696350,125.251625 
	C551.043762,118.815071 544.391235,112.378517 537.334045,105.556900 
	C535.270935,103.492554 533.612488,101.813286 532.307617,100.123215 
	C536.063721,102.181709 539.466248,104.251022 542.868774,106.320335 
	C543.987244,107.268150 545.105652,108.215973 546.670410,109.519165 
	C548.353577,111.052086 549.590454,112.229645 550.827271,113.407196 
	C550.827271,113.407204 550.902283,113.759445 551.118530,114.182793 
	C552.655701,116.041092 553.976685,117.476044 555.297668,118.910995 
	C556.019104,120.163193 556.740601,121.415382 557.729797,123.145264 
	C558.064819,124.027740 558.132080,124.432526 558.074280,124.941704 
	C557.949219,125.046097 557.696350,125.251625 557.696350,125.251625 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M566.594666,168.900589 
	C566.667358,164.599655 566.739990,160.298737 566.875488,155.135223 
	C566.562683,151.862320 566.187134,149.452011 565.811646,147.041702 
	C565.805542,146.605774 565.799438,146.169846 565.789673,145.093903 
	C565.632690,143.297882 565.479431,142.141861 565.326233,140.985840 
	C570.003174,142.508224 568.056824,146.953293 568.713623,149.877045 
	C569.902771,155.170303 569.762817,160.787384 569.881958,166.275681 
	C569.907715,167.459778 568.680176,168.671097 567.509399,169.653809 
	C566.857422,169.258682 566.726074,169.079636 566.594666,168.900589 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M461.693420,354.925781 
	C461.700470,356.478394 462.310120,358.414276 461.609039,359.516479 
	C458.731171,364.041016 455.635895,368.462524 452.214569,372.584961 
	C451.174957,373.837616 448.830322,374.007202 446.910309,374.269836 
	C449.019257,371.086273 451.301270,368.305695 453.600525,365.505432 
	C453.617767,365.485718 453.642487,365.438141 453.927551,365.307922 
	C454.870422,364.142273 455.528259,363.106842 456.408997,362.007751 
	C456.913452,361.762604 457.067139,361.504913 457.389130,361.041565 
	C457.850159,360.302490 458.015076,359.692810 458.450500,358.971436 
	C458.864105,358.304901 459.007233,357.750122 459.447235,357.141602 
	C460.393890,356.367188 461.043671,355.646484 461.693420,354.925781 
z"/>
<path fill="#097EB0" opacity="1.000000" stroke="none" 
	d="
M406.915222,390.801941 
	C405.097931,390.983795 403.280609,391.165649 400.904297,391.737030 
	C399.459686,392.975739 398.574127,393.824951 397.688599,394.674133 
	C397.688568,394.674133 397.853088,394.711700 397.448792,394.718445 
	C396.073456,395.201355 395.102417,395.677551 394.131348,396.153748 
	C394.131348,396.153748 393.910431,395.912659 393.947083,395.500732 
	C395.684174,393.163208 397.384613,391.237579 399.434845,389.304749 
	C403.796021,388.738342 407.807159,388.177917 411.818756,387.620361 
	C416.196838,387.011841 420.575287,386.405975 424.780334,386.127655 
	C418.709808,387.904877 412.812500,389.353394 406.915222,390.801941 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M407.182312,391.130981 
	C412.812500,389.353394 418.709808,387.904877 425.105713,386.086121 
	C426.707916,385.135468 427.811493,384.555115 429.285370,383.967926 
	C432.100586,382.965515 434.545471,381.969910 436.990387,380.974304 
	C434.062653,383.138794 431.428192,386.132721 428.145538,387.293365 
	C421.515503,389.637482 415.023743,393.535461 407.182312,391.130981 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M90.095383,452.979980 
	C91.713272,455.400024 93.331161,457.820038 94.995697,460.575317 
	C93.762985,460.498260 91.836586,460.479980 91.307350,459.610870 
	C87.302063,453.033630 82.651787,446.671753 82.943001,438.454590 
	C84.508797,440.477905 85.529541,442.544586 86.581909,444.947357 
	C87.774147,447.848938 88.934769,450.414459 90.095383,452.979980 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M92.479507,421.006531 
	C91.692650,420.557770 90.229805,420.122620 90.220467,419.658203 
	C90.083138,412.830383 88.950951,405.849182 92.489281,399.284332 
	C96.832329,400.020782 96.306274,402.713440 94.900223,405.629883 
	C93.682976,405.732544 93.009636,405.765533 92.336304,405.798523 
	C92.455864,408.212677 92.575424,410.626770 92.688477,413.483948 
	C92.614487,416.286835 92.546997,418.646667 92.479507,421.006531 
z"/>
<path fill="#089DD3" opacity="1.000000" stroke="none" 
	d="
M437.114258,380.693481 
	C434.545471,381.969910 432.100586,382.965515 429.045898,383.773834 
	C428.680237,383.053192 428.786652,382.211090 429.188660,382.031799 
	C437.734558,378.219971 445.589569,373.504608 451.354523,365.861694 
	C451.677856,365.433014 452.821472,365.623077 453.583313,365.525146 
	C451.301270,368.305695 449.019257,371.086273 446.524292,374.124084 
	C446.311340,374.381378 446.283020,374.463837 446.044983,374.646667 
	C445.197815,375.535156 444.588715,376.240845 443.979614,376.946533 
	C443.398987,377.093475 442.818329,377.240448 441.778381,377.680756 
	C440.546753,378.650024 439.774445,379.325958 439.002136,380.001892 
	C438.414124,380.138824 437.826141,380.275757 437.114258,380.693481 
z"/>
<path fill="#0B8BBF" opacity="1.000000" stroke="none" 
	d="
M511.993439,92.059578 
	C512.687317,92.229927 513.381165,92.400284 514.588013,92.750443 
	C515.380859,93.119400 515.660828,93.308540 516.038025,93.809448 
	C516.911743,94.256226 517.688171,94.391228 518.464661,94.526230 
	C518.843018,94.569824 519.221375,94.613419 520.172729,94.794510 
	C522.914795,95.852844 525.083862,96.773666 527.171753,98.017487 
	C527.058228,98.560135 527.025879,98.779800 526.724121,99.021942 
	C526.265137,99.746864 526.075500,100.449318 525.529419,101.118454 
	C524.698669,101.141975 524.224426,101.198822 523.433838,101.109222 
	C520.778259,99.851524 518.438843,98.740265 515.996216,97.296783 
	C514.334045,96.269348 512.697205,95.705688 511.237701,94.842270 
	C509.749268,93.961685 508.435150,92.786453 507.044739,91.740219 
	C507.897552,91.829628 508.750336,91.919044 510.073120,92.011932 
	C511.026520,92.030128 511.509979,92.044853 511.993439,92.059578 
z"/>
<path fill="#0B5D85" opacity="1.000000" stroke="none" 
	d="
M507.081177,91.507874 
	C508.435150,92.786453 509.749268,93.961685 511.237701,94.842270 
	C512.697205,95.705688 514.334045,96.269348 515.657227,97.399986 
	C514.950378,97.865074 514.479309,97.894737 514.008179,97.924408 
	C513.238281,97.571709 512.468445,97.219009 511.103912,96.618362 
	C507.334503,95.604584 504.159760,94.838730 500.985016,94.072884 
	C499.892212,93.814720 498.799408,93.556564 497.060120,93.178963 
	C495.969421,92.934219 495.525177,92.808914 495.062683,92.352341 
	C495.074127,91.151039 495.103821,90.280998 495.133514,89.410965 
	C496.139160,89.362564 497.144775,89.314156 498.802795,89.479767 
	C500.634888,89.804665 501.814575,89.915543 502.994263,90.026428 
	C503.423523,90.084518 503.852753,90.142609 504.807251,90.408081 
	C505.905487,90.680267 506.478516,90.745056 507.051514,90.809845 
	C507.051514,90.809845 507.117615,91.275528 507.081177,91.507874 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M309.388977,366.006989 
	C312.829041,357.511963 316.658081,349.023926 320.714050,340.255646 
	C321.123871,341.324188 321.834534,342.877808 321.403473,343.988281 
	C318.740936,350.847809 315.896545,357.641144 312.892670,364.358124 
	C312.513947,365.204987 310.847687,365.476105 309.388977,366.006989 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M429.127350,287.913544 
	C434.911896,280.622345 441.062653,273.338501 447.528900,265.930786 
	C447.617249,267.081390 447.841431,268.803741 447.091492,269.559357 
	C441.117859,275.578613 437.681671,284.044312 429.127350,287.913544 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M96.978699,463.448486 
	C98.123833,463.974915 99.277573,464.861511 100.845901,466.156494 
	C101.787575,466.870514 102.314667,467.176147 102.841766,467.481812 
	C107.691429,470.997864 112.541092,474.513916 117.533295,478.358643 
	C108.684990,476.640320 101.333511,472.313904 96.978699,463.448486 
z"/>
<path fill="#09475E" opacity="1.000000" stroke="none" 
	d="
M494.685547,89.277939 
	C495.103821,90.280998 495.074127,91.151039 494.694458,92.371323 
	C494.106598,92.659882 493.868744,92.598175 493.450806,92.397308 
	C492.804138,92.176613 492.337524,92.095085 491.509827,92.065323 
	C490.428528,92.206650 489.708252,92.296204 488.987976,92.385765 
	C487.600769,92.253151 486.213593,92.120544 484.110657,91.735329 
	C480.256317,91.406250 477.117676,91.329781 473.979034,91.253311 
	C473.224609,91.169739 472.470154,91.086159 471.448975,90.764175 
	C471.483307,90.031242 471.784393,89.536720 472.085480,89.042198 
	C474.761536,88.963837 477.437592,88.885475 480.962646,88.819855 
	C483.210876,88.756065 484.610107,88.679550 486.009338,88.603043 
	C488.752106,88.783669 491.494843,88.964294 494.685547,89.277939 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M236.103607,477.053619 
	C243.676636,474.148438 251.447739,471.537994 259.608063,468.963898 
	C258.791199,469.918671 257.635712,470.915283 256.366913,471.736877 
	C254.561005,472.906250 252.671890,473.947052 251.120590,474.864136 
	C246.083954,475.708466 241.192810,476.528442 236.103607,477.053619 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M545.277893,234.345337 
	C548.813538,226.264725 552.349182,218.184113 556.287659,210.364929 
	C556.114075,212.410950 555.537598,214.195541 554.961121,215.980133 
	C553.916199,218.376892 552.871338,220.773651 551.543457,223.760803 
	C550.056213,226.853668 548.851868,229.356155 547.647522,231.858643 
	C547.360657,232.333298 547.073853,232.807953 546.504639,233.637238 
	C545.907410,234.109695 545.592651,234.227509 545.277893,234.345337 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M92.694977,413.040894 
	C92.575424,410.626770 92.455864,408.212677 92.336304,405.798523 
	C93.009636,405.765533 93.682976,405.732544 94.595123,405.863953 
	C95.119461,407.781677 95.404991,409.535034 95.783981,411.987000 
	C96.189423,413.481995 96.501411,414.278381 96.813416,415.074799 
	C97.287704,416.603149 97.761993,418.131470 98.547531,420.167114 
	C99.169563,421.144409 99.480362,421.614441 99.791153,422.084473 
	C99.827477,422.845978 99.863808,423.607483 99.801926,424.738953 
	C99.283974,424.821350 98.864235,424.533783 98.184708,423.749939 
	C96.181595,419.849365 94.438278,416.445129 92.694977,413.040894 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M393.956238,396.437744 
	C395.102417,395.677551 396.073456,395.201355 397.362549,394.691528 
	C396.471954,396.120483 395.424530,397.765167 394.021057,399.007660 
	C391.128540,401.568390 388.097443,403.983673 384.996552,406.288177 
	C384.404785,406.727936 383.229034,406.381836 382.120300,406.105408 
	C385.872345,402.782227 389.826752,399.752014 393.956238,396.437744 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M159.102936,487.523376 
	C166.022247,487.028870 173.045624,486.916931 180.532379,486.881744 
	C180.697830,487.516876 180.389267,488.567780 180.103516,488.561615 
	C173.136536,488.411316 166.171982,488.148499 159.102936,487.523376 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M92.688477,413.483948 
	C94.438278,416.445129 96.181595,419.849365 97.963509,423.619202 
	C97.170959,424.870422 96.339806,425.756012 94.996696,426.604004 
	C93.940552,425.362762 93.396362,424.159180 92.852173,422.955566 
	C92.782639,422.519653 92.713112,422.083740 92.561554,421.327179 
	C92.546997,418.646667 92.614487,416.286835 92.688477,413.483948 
z"/>
<path fill="#089DD3" opacity="1.000000" stroke="none" 
	d="
M525.885803,101.151764 
	C526.075500,100.449318 526.265137,99.746864 526.979004,99.292091 
	C528.997253,99.686203 530.491211,99.832634 532.005859,100.023514 
	C532.026428,100.067970 531.954041,100.134003 531.954041,100.134018 
	C533.612488,101.813286 535.270935,103.492554 536.987793,105.489288 
	C537.023987,106.659035 537.001770,107.511307 536.942505,108.785866 
	C536.501831,109.128914 536.098206,109.049675 535.385376,108.945107 
	C535.076111,108.919769 535.055664,108.953987 535.008362,108.670517 
	C534.637085,107.927788 534.313110,107.468544 533.963501,106.642334 
	C532.119263,105.340355 530.300659,104.405350 528.390991,103.570457 
	C528.299866,103.670570 528.298462,103.941261 528.298462,103.941261 
	C528.102234,103.798141 527.906067,103.655014 527.198669,103.201477 
	C526.393555,102.662918 526.099670,102.434769 525.854980,101.945122 
	C525.898071,101.506340 525.891968,101.329048 525.885803,101.151764 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M542.879272,105.925583 
	C539.466248,104.251022 536.063721,102.181709 532.307617,100.123199 
	C531.954041,100.134003 532.026428,100.067970 531.889648,99.757912 
	C531.158081,98.971909 530.563232,98.495972 529.968445,98.020020 
	C531.922363,98.265602 534.448059,96.046066 535.812744,99.440887 
	C535.912048,99.687943 538.614136,98.888702 540.232910,98.549347 
	C541.090515,100.802902 541.990112,103.166862 542.879272,105.925583 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M224.940399,478.722778 
	C225.115372,478.821594 225.290359,478.920410 225.735382,479.153442 
	C223.463699,480.436249 221.020767,481.995819 218.356873,482.636230 
	C214.447540,483.576080 210.380203,483.858643 206.146179,484.105499 
	C211.447372,482.371216 216.982574,480.952698 223.083115,479.265961 
	C224.079102,478.906067 224.509750,478.814423 224.940399,478.722778 
z"/>
<path fill="#3C0508" opacity="1.000000" stroke="none" 
	d="
M265.018127,183.018738 
	C268.748016,180.680374 272.477905,178.342010 276.598083,176.272980 
	C277.003174,176.755417 277.017944,176.968521 277.032715,177.181641 
	C277.032715,177.181641 277.117432,177.588608 276.954987,178.069489 
	C275.529327,179.693985 274.266113,180.837616 273.002869,181.981247 
	C270.567566,182.973862 268.132294,183.966476 265.344360,184.669952 
	C265.000519,183.926804 265.009308,183.472763 265.018127,183.018738 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M433.215881,119.166061 
	C439.089874,118.665245 445.244934,118.490578 451.748291,118.542862 
	C445.896729,119.010612 439.696838,119.251419 433.215881,119.166061 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M565.202271,140.674683 
	C565.479431,142.141861 565.632690,143.297882 565.666138,144.788849 
	C564.417419,142.199539 563.318970,139.263031 562.151672,136.354187 
	C560.756348,132.877213 559.302002,129.423950 557.784790,125.606018 
	C557.696350,125.251625 557.949219,125.046097 558.297485,125.091080 
	C559.052734,125.469254 559.459656,125.802452 559.866577,126.135643 
	C559.866577,126.135643 559.856628,126.013031 559.834351,126.359451 
	C560.124878,127.170761 560.437622,127.635651 560.750366,128.100540 
	C560.925537,128.646164 561.100647,129.191788 561.543030,130.294067 
	C562.824158,133.359604 563.838013,135.868500 564.851929,138.377380 
	C564.927368,139.039444 565.002808,139.701477 565.202271,140.674683 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M468.827454,334.616943 
	C469.678436,339.983978 468.876495,344.886383 464.461029,348.566833 
	C464.294464,346.805908 464.486908,345.352173 464.777252,343.588013 
	C464.886993,342.845306 464.898834,342.413055 465.163757,341.707550 
	C465.715973,339.701538 466.015076,337.968750 466.314148,336.235962 
	C466.314148,336.235962 466.061432,336.270081 466.476807,336.178894 
	C467.537231,335.597473 468.182343,335.107239 468.827454,334.616943 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M504.695526,175.771393 
	C504.363068,175.849731 504.030579,175.928070 503.447998,176.061783 
	C502.979187,170.555511 502.760498,164.993866 502.856689,159.165802 
	C503.490723,158.999222 503.809845,159.099030 504.394409,159.514252 
	C504.686279,160.216721 504.712738,160.603790 504.491669,161.311569 
	C504.409637,165.759613 504.575134,169.886917 504.722168,174.307114 
	C504.700989,174.990463 504.698242,175.380920 504.695526,175.771393 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M525.805725,102.206619 
	C526.099670,102.434769 526.393555,102.662918 526.842834,103.123451 
	C526.602539,103.804710 525.984558,104.705841 525.843689,104.636581 
	C521.899414,102.697304 518.003357,100.659897 514.053833,98.281509 
	C514.479309,97.894737 514.950378,97.865074 515.760498,97.732208 
	C518.438843,98.740265 520.778259,99.851524 523.619873,101.343567 
	C524.683350,101.885094 525.244507,102.045853 525.805725,102.206619 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M499.940491,299.974060 
	C500.179657,299.740204 500.418793,299.506348 501.093567,299.006897 
	C501.803406,298.227173 502.077637,297.713074 502.351868,297.199005 
	C502.351898,297.199005 502.670288,296.957672 503.024109,296.792908 
	C503.820343,296.336639 504.262756,296.045105 504.797272,295.833191 
	C504.889404,295.912842 505.070709,296.075470 505.070709,296.075470 
	C504.364807,300.409943 503.573822,304.676361 497.448364,305.009827 
	C496.995209,304.981323 496.890289,304.866547 497.021332,304.565491 
	C498.081726,302.834320 499.011108,301.404205 499.940491,299.974060 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M304.133850,451.246948 
	C307.050720,449.254059 310.132050,447.586578 313.570557,445.872803 
	C312.299957,447.965240 310.931183,450.414734 308.918854,452.092194 
	C308.095306,452.778625 305.880341,451.795776 304.133850,451.246948 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M235.868927,434.650543 
	C232.434265,435.414459 228.999603,436.178406 225.200012,436.593658 
	C224.927612,435.898865 225.020111,435.552795 225.112610,435.206696 
	C227.529587,434.277283 229.946564,433.347870 233.069519,432.299744 
	C235.208069,431.958771 236.640610,431.736511 238.073166,431.514252 
	C238.088379,431.848175 238.103607,432.182068 237.763931,432.785889 
	C236.895676,433.587341 236.382294,434.118958 235.868927,434.650543 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M560.008911,200.005508 
	C561.301208,194.740173 562.593506,189.474823 564.214966,184.136688 
	C564.544067,184.063904 564.911438,183.923325 564.972046,184.278992 
	C564.659546,186.370087 564.286377,188.105530 563.913208,189.840958 
	C563.535461,190.953918 563.157776,192.066879 562.555908,193.805054 
	C561.557495,196.288681 560.783203,198.147095 560.008911,200.005508 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M488.082306,132.124374 
	C490.067566,133.578827 492.011017,135.424194 494.119690,137.566040 
	C492.829376,138.442108 491.373810,139.021683 488.670807,140.097977 
	C488.438751,137.306290 488.239594,134.910782 488.082306,132.124374 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M471.626221,89.021912 
	C471.784393,89.536720 471.483307,90.031242 471.069336,90.793907 
	C467.864899,91.436058 464.773376,91.810074 461.681854,92.184090 
	C464.090149,87.432632 467.958435,89.653030 471.626221,89.021912 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M119.051056,479.188141 
	C122.527771,480.000946 126.037933,481.173126 129.808350,482.584625 
	C124.546165,487.970978 122.558548,481.267670 119.051056,479.188141 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M464.679352,343.898438 
	C464.486908,345.352173 464.294464,346.805908 464.119415,348.634979 
	C463.375610,350.750000 462.614410,352.489685 461.773315,354.577576 
	C461.043671,355.646484 460.393890,356.367188 459.402710,356.844727 
	C459.044006,356.402283 459.026733,356.202942 459.086945,355.647400 
	C459.854553,352.955597 460.544647,350.619995 461.525208,348.163940 
	C462.173035,347.350189 462.530457,346.656891 462.932617,345.644592 
	C463.011353,344.897552 463.045380,344.469513 463.345795,344.015503 
	C463.967896,343.959167 464.323608,343.928802 464.679352,343.898438 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M378.841370,120.847656 
	C376.310303,122.183800 373.566620,123.227104 370.434143,124.280067 
	C371.976257,118.843758 372.978424,118.407692 378.841370,120.847656 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M261.920013,420.985413 
	C262.210449,421.464905 262.090973,421.768768 261.496063,422.297974 
	C260.726166,422.750549 260.358887,422.949097 259.991577,423.147705 
	C257.641693,423.919098 255.291824,424.690491 252.556366,425.493561 
	C253.440735,420.212769 257.867249,421.239349 261.920013,420.985413 
z"/>
<path fill="#BB0405" opacity="1.000000" stroke="none" 
	d="
M280.597076,412.685272 
	C280.848969,412.760864 281.100891,412.836426 281.734253,413.017334 
	C278.672516,415.084839 275.229309,417.047028 271.555450,418.756409 
	C271.859375,417.943268 272.393982,417.382965 273.073090,416.630981 
	C273.456024,416.206146 273.740997,416.071106 274.409119,416.036713 
	C275.512390,415.432068 276.278992,414.824829 277.045624,414.217590 
	C277.542938,413.833679 278.040253,413.449768 279.028015,412.924561 
	C279.877991,412.750610 280.237549,412.717957 280.597076,412.685272 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M155.901733,448.397095 
	C153.607178,448.839417 151.229004,448.935181 148.242920,448.850159 
	C147.097610,448.495636 146.560242,448.321899 146.022873,448.148193 
	C149.249191,444.223633 152.527069,445.474762 155.901733,448.397095 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M564.117310,190.129333 
	C564.286377,188.105530 564.659546,186.370087 565.168579,184.271317 
	C566.475098,185.276733 567.645813,186.645477 568.816467,188.014206 
	C567.318115,188.815369 565.819763,189.616531 564.117310,190.129333 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M92.635048,423.221222 
	C93.396362,424.159180 93.940552,425.362762 94.748764,426.786133 
	C95.270332,428.423584 95.527893,429.841278 95.921829,431.900909 
	C96.374100,433.029022 96.689987,433.515259 97.005882,434.001465 
	C97.005844,434.488892 97.005814,434.976349 96.629509,435.753693 
	C95.557968,435.338318 94.862694,434.632996 94.167419,433.927673 
	C94.169044,433.492950 94.170662,433.058228 94.102631,431.955261 
	C93.410522,429.837250 92.788063,428.387512 92.165604,426.937744 
	C92.249710,425.787445 92.333817,424.637177 92.635048,423.221222 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M532.412476,256.969299 
	C534.161377,253.413025 536.231995,249.735046 538.647583,246.026978 
	C536.906433,249.613800 534.820374,253.230698 532.412476,256.969299 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M448.179871,265.896179 
	C449.932220,262.875641 452.077637,259.902893 454.541016,256.784546 
	C455.040771,261.278961 452.484436,264.069214 448.179871,265.896179 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M527.252991,97.694489 
	C525.083862,96.773666 522.914795,95.852844 520.450012,94.726959 
	C521.420593,94.191200 522.733032,93.978920 523.944214,93.507011 
	C527.208557,92.235161 528.226807,93.657928 527.818970,97.140854 
	C527.625977,97.564751 527.252991,97.694489 527.252991,97.694489 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M491.988098,308.993439 
	C492.311493,308.884399 492.569611,308.689117 493.160461,308.120178 
	C493.821198,307.328369 494.083893,306.823944 494.346619,306.319489 
	C494.346588,306.319489 493.990112,306.051239 494.444092,306.135315 
	C495.178589,305.762665 495.459137,305.305908 495.739685,304.849152 
	C495.739655,304.849152 495.871002,304.847412 496.116394,304.853516 
	C496.537994,304.861938 496.714142,304.864258 496.890289,304.866547 
	C496.890289,304.866547 496.995209,304.981323 497.051331,305.036926 
	C496.598358,309.085571 495.347046,312.466614 490.340332,312.644531 
	C490.710236,311.208771 491.349182,310.101105 491.988098,308.993439 
z"/>
<path fill="#0B5D85" opacity="1.000000" stroke="none" 
	d="
M86.550278,444.611298 
	C85.529541,442.544586 84.508797,440.477905 83.195312,438.210571 
	C82.895378,437.251831 82.888191,436.493683 82.873146,435.356323 
	C87.473404,434.478760 86.149544,438.501099 87.101807,441.586578 
	C86.836777,443.188538 86.693520,443.899902 86.550278,444.611298 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M555.314758,215.937225 
	C555.537598,214.195541 556.114075,212.410950 556.609741,210.287399 
	C556.851807,209.066025 557.174561,208.183609 557.754883,206.847885 
	C558.253845,206.164017 558.538757,206.018448 558.867065,205.957855 
	C560.765930,210.224518 558.192078,213.051331 555.314758,215.937225 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M474.043121,91.593559 
	C477.117676,91.329781 480.256317,91.406250 483.690918,91.755775 
	C480.689178,92.463554 477.362518,95.908821 474.043121,91.593559 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M291.117340,457.063538 
	C293.359741,455.509705 295.822601,454.268127 298.642151,453.023315 
	C297.773376,456.808136 295.673920,459.058929 291.117340,457.063538 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M545.204895,234.614838 
	C545.592651,234.227509 545.907410,234.109695 546.464600,233.916214 
	C548.036499,235.958969 549.366089,238.077393 550.695618,240.195816 
	C550.475159,240.485046 550.254700,240.774261 550.034180,241.063492 
	C548.478149,240.040359 546.922058,239.017227 545.041138,237.593506 
	C544.691589,236.757507 544.666870,236.322083 544.642151,235.886658 
	C544.805420,235.552567 544.968689,235.218460 545.204895,234.614838 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M389.166443,127.151421 
	C391.708984,125.941246 394.473236,125.024963 397.613129,124.125900 
	C395.895721,127.259285 393.588409,129.817551 389.166443,127.151421 
z"/>
<path fill="#BB0405" opacity="1.000000" stroke="none" 
	d="
M251.477158,426.746429 
	C251.086975,431.758942 247.109085,430.723755 243.429916,430.757751 
	C243.043808,430.138458 243.070892,429.791077 243.097961,429.443665 
	C243.732666,429.245758 244.367371,429.047852 245.653015,428.875305 
	C246.895935,428.435425 247.487930,427.970276 248.079926,427.505127 
	C249.082397,427.244324 250.084869,426.983521 251.477158,426.746429 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M501.053406,94.425758 
	C504.159760,94.838730 507.334503,95.604584 510.782898,96.610710 
	C507.199860,98.773308 504.055969,97.278580 501.053406,94.425758 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M103.106216,445.885620 
	C103.848694,446.255432 104.591164,446.625244 105.643433,447.035950 
	C106.751030,448.847321 107.548820,450.617737 107.905716,452.658020 
	C106.933960,452.752533 106.403107,452.577209 105.797180,452.084595 
	C104.899437,451.132996 104.076782,450.498718 103.254128,449.864471 
	C103.142090,449.219147 103.030052,448.573792 102.952896,447.255463 
	C103.027252,446.350159 103.066727,446.117889 103.106216,445.885620 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M274.072449,416.034119 
	C273.740997,416.071106 273.456024,416.206146 272.694397,416.656860 
	C270.477081,417.327881 268.782898,417.781342 267.088745,418.234802 
	C268.534790,414.982971 270.352325,412.390564 274.760803,414.330872 
	C274.669495,415.099457 274.370972,415.566772 274.072449,416.034119 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M513.371338,283.427246 
	C515.309631,280.615173 517.247925,277.803101 519.581299,275.293884 
	C519.909790,276.033386 519.843262,276.470001 519.776733,276.906616 
	C519.171997,277.647095 518.567261,278.387573 517.620972,279.571320 
	C515.976685,281.152161 514.674011,282.289703 513.371338,283.427246 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M559.976196,200.330048 
	C560.783203,198.147095 561.557495,196.288681 562.554077,194.168701 
	C565.496216,197.700439 563.141663,200.309448 560.228271,202.654327 
	C559.683472,202.234909 559.589478,202.054428 559.495544,201.873962 
	C559.644897,201.467514 559.794250,201.061050 559.976196,200.330048 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M508.992188,288.998230 
	C509.429474,288.611664 509.866730,288.225067 510.579895,287.736755 
	C511.683441,291.956635 508.981628,294.112091 505.412964,296.010498 
	C505.070709,296.075470 504.889404,295.912842 504.853729,295.536346 
	C504.848236,294.762238 504.878418,294.364624 504.908600,293.967010 
	C505.150482,293.723969 505.392395,293.480927 506.093994,292.963593 
	C507.366547,291.458954 508.179382,290.228607 508.992188,288.998230 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M548.033142,231.876923 
	C548.851868,229.356155 550.056213,226.853668 551.509766,224.125732 
	C553.581055,227.791656 552.157532,230.327103 548.033142,231.876923 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M491.640076,309.000916 
	C491.349182,310.101105 490.710236,311.208771 489.942627,312.620361 
	C488.491669,314.006592 487.169403,315.088928 485.531189,316.246368 
	C487.240875,313.883789 489.266479,311.446075 491.640076,309.000916 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M525.960938,112.700211 
	C524.421326,112.274475 522.900696,111.431915 521.179321,110.334717 
	C522.151794,109.429260 523.325012,108.778435 524.498291,108.127617 
	C524.992188,109.512878 525.486023,110.898132 525.960938,112.700211 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M478.762817,322.882538 
	C479.275513,322.555267 479.788269,322.227997 480.594147,321.809570 
	C480.822418,325.274567 480.358124,328.554016 475.365387,328.947754 
	C474.900696,328.877380 474.730774,328.683044 474.888611,328.407196 
	C475.680847,327.076538 476.315277,326.021729 476.949677,324.966888 
	C476.949677,324.966888 476.999603,325.000000 477.281097,324.923950 
	C477.962677,324.192749 478.362732,323.537659 478.762817,322.882538 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M404.811401,326.154449 
	C404.837372,326.947144 404.535309,327.681915 403.821472,328.818970 
	C402.605164,330.120270 401.800629,331.019287 400.996094,331.918335 
	C400.147736,332.263550 399.299347,332.608765 398.110596,332.869446 
	C397.814453,332.381836 397.858673,331.978699 398.222534,331.170349 
	C399.390900,329.866180 400.239624,328.967285 401.088318,328.068359 
	C402.220001,327.411102 403.351715,326.753815 404.811401,326.154449 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M142.096054,485.712341 
	C145.138397,485.715698 148.283539,486.048950 151.747314,486.608093 
	C148.563171,489.233856 145.352463,487.993713 142.096054,485.712341 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M281.228271,461.256378 
	C283.085754,459.991272 285.180176,459.003815 287.635193,458.006348 
	C287.224884,461.770935 284.879669,462.639465 281.228271,461.256378 
z"/>
<path fill="#BB0405" opacity="1.000000" stroke="none" 
	d="
M188.169922,447.218811 
	C186.443314,450.916412 183.972168,450.825897 181.035675,448.231964 
	C181.475677,447.595306 181.963562,447.338287 182.908203,447.316223 
	C183.921921,447.323914 184.478867,447.096680 185.294586,446.810059 
	C185.906403,446.745514 186.259415,446.740295 187.000488,446.860260 
	C187.648987,447.063263 187.909454,447.141022 188.169922,447.218811 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M131.986572,446.153351 
	C131.848267,446.585510 131.686920,446.678406 131.272934,446.882538 
	C131.020279,446.993774 131.040878,446.977173 130.933411,446.815796 
	C130.613770,446.448364 130.374832,446.410217 129.840576,446.298462 
	C128.689178,445.840485 127.806343,445.623993 126.604507,445.282043 
	C125.614250,445.040527 124.942993,444.924500 124.211380,444.551575 
	C124.066658,443.956818 123.982300,443.618958 123.897942,443.281067 
	C126.586487,444.125427 129.275024,444.969757 131.986572,446.153351 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M238.413513,431.411530 
	C236.640610,431.736511 235.208069,431.958771 233.398071,432.192017 
	C235.565323,431.002808 238.110046,429.802673 240.821472,429.170532 
	C240.934860,430.104797 240.881531,430.471039 240.828217,430.837311 
	C240.136765,430.994476 239.445297,431.151642 238.413513,431.411530 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M102.953514,450.050110 
	C104.076782,450.498718 104.899437,451.132996 105.731789,452.414368 
	C104.160789,453.374023 102.580093,453.686554 100.999390,453.999054 
	C100.999390,453.999054 101.000000,454.000000 101.000298,454.000488 
	C101.551369,452.745850 102.102135,451.490784 102.953514,450.050110 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M260.230164,391.235504 
	C262.409302,390.401337 264.839142,389.858154 267.645630,389.372955 
	C266.450775,393.520569 263.288818,391.886505 260.230164,391.235504 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M452.770630,273.925232 
	C451.954742,275.657410 450.798157,277.232574 449.352966,278.967163 
	C448.864227,277.359985 448.664062,275.593414 448.380157,273.087830 
	C449.840424,273.333130 451.135193,273.550659 452.770630,273.925232 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M550.904175,113.002838 
	C549.590454,112.229645 548.353577,111.052086 546.980896,109.592270 
	C548.234985,108.803932 549.624939,108.297852 552.117493,107.390312 
	C551.566589,109.915237 551.273865,111.256851 550.904175,113.002838 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M264.669617,183.047729 
	C265.009308,183.472763 265.000519,183.926804 264.986450,184.722839 
	C263.768921,186.162704 262.556671,187.260590 261.344452,188.358459 
	C260.888947,187.829285 260.433441,187.300095 259.977936,186.770905 
	C261.425690,185.539520 262.873413,184.308136 264.669617,183.047729 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M394.891846,332.926697 
	C393.951172,334.427826 392.791809,335.623169 391.334534,336.928619 
	C389.207062,333.062317 390.050323,331.286041 394.891846,332.926697 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M352.112976,425.084290 
	C354.005066,423.495483 356.092499,422.201324 358.515991,420.855835 
	C358.064728,424.323303 355.917847,425.897339 352.112976,425.084290 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M513.303589,283.590027 
	C514.674011,282.289703 515.976685,281.152161 517.575317,279.862335 
	C516.400024,281.781128 514.928711,283.852173 513.144531,285.576935 
	C512.880432,284.818115 512.929138,284.405609 512.977844,283.993103 
	C512.977844,283.993103 513.235779,283.752777 513.303589,283.590027 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M435.593262,294.005432 
	C434.202484,295.954803 432.437592,297.908813 430.383545,300.029236 
	C429.462891,296.195251 431.866760,294.709717 435.593262,294.005432 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M533.989075,107.009300 
	C534.313110,107.468544 534.637085,107.927788 535.019043,108.662804 
	C532.700806,108.497620 528.850586,110.751411 529.393066,105.270050 
	C531.165100,105.608063 532.577087,106.308685 533.989075,107.009300 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M468.958160,334.363770 
	C468.182343,335.107239 467.537231,335.597473 466.604675,336.176758 
	C467.965271,334.176239 469.613373,332.086670 471.619476,330.310669 
	C471.886810,331.042236 471.796143,331.460266 471.705505,331.878296 
	C470.833313,332.622375 469.961090,333.366486 468.958160,334.363770 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M93.860474,434.168518 
	C94.862694,434.632996 95.557968,435.338318 96.546906,436.110107 
	C96.949265,436.867767 97.057961,437.558960 97.126755,438.598022 
	C93.483604,439.322784 92.064926,437.998230 93.860474,434.168518 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M132.002960,483.633606 
	C134.151230,483.649536 136.320740,484.041290 138.762604,484.671875 
	C136.508545,486.084137 133.934372,487.629059 132.002960,483.633606 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M324.052490,440.989532 
	C326.007935,439.467224 328.153564,438.250183 330.647644,437.014587 
	C328.744934,438.428955 326.493805,439.861877 324.052490,440.989532 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M416.129822,121.075150 
	C418.342102,120.227882 420.784882,119.696457 423.607910,119.209503 
	C421.445526,119.966309 418.902954,120.678642 416.129822,121.075150 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M489.095001,92.704025 
	C489.708252,92.296204 490.428528,92.206650 491.697510,92.234406 
	C492.707794,92.413300 493.169312,92.474884 493.630859,92.536469 
	C493.868744,92.598175 494.106598,92.659882 494.712708,92.702591 
	C495.525177,92.808914 495.969421,92.934219 496.708862,93.242363 
	C494.216064,96.918808 491.783966,93.519936 489.095001,92.704025 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M116.822578,440.236755 
	C115.321350,439.856873 113.864113,439.079163 112.218224,437.985657 
	C114.512711,435.495636 115.132729,435.773651 116.822578,440.236755 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M266.892029,418.458710 
	C268.782898,417.781342 270.477081,417.327881 272.549927,416.848572 
	C272.393982,417.382965 271.859375,417.943268 271.163391,418.755188 
	C270.187347,419.180206 269.372620,419.353638 268.259644,419.927887 
	C267.961365,420.328705 267.873810,420.731812 267.873810,420.731812 
	C267.108215,420.821777 266.342621,420.911743 265.198303,420.916992 
	C265.444855,420.115692 266.070099,419.399139 266.892029,418.458710 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M518.941772,108.776001 
	C517.733948,108.679268 516.551392,108.175461 515.166626,107.425545 
	C515.831604,106.505379 516.698853,105.831314 517.566101,105.157257 
	C518.033081,106.227814 518.500061,107.298370 518.941772,108.776001 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M499.911499,102.424561 
	C497.816376,102.395309 495.645752,102.031631 493.197083,101.433746 
	C495.558441,98.904022 497.774353,99.898346 499.911499,102.424561 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M97.497971,439.085846 
	C98.838989,440.513245 100.002617,442.276398 100.826660,444.350922 
	C99.381615,443.417877 98.276146,442.173462 97.170685,440.929016 
	C97.220665,440.426544 97.270630,439.924042 97.497971,439.085846 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M314.812134,392.076324 
	C313.381744,393.347839 311.523468,394.583771 309.343445,395.921875 
	C308.717194,391.879913 311.479004,391.863770 314.812134,392.076324 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M400.758179,327.916931 
	C400.239624,328.967285 399.390900,329.866180 398.244934,330.865356 
	C397.329987,329.952515 396.712280,328.939453 395.414520,326.811066 
	C397.881531,327.280701 399.154785,327.523102 400.758179,327.916931 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M273.483826,339.989624 
	C272.128082,341.698700 270.415497,343.411713 268.357147,345.137573 
	C269.716614,343.431519 271.421844,341.712555 273.483826,339.989624 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M528.903503,261.964355 
	C529.363281,261.343079 529.823059,260.721832 530.621826,259.735413 
	C531.275452,259.236298 531.590149,259.102325 531.904785,258.968353 
	C531.090637,260.611938 530.276489,262.255554 529.155701,263.570312 
	C528.867188,262.815765 528.885315,262.390045 528.903503,261.964355 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M555.511841,118.641388 
	C553.976685,117.476044 552.655701,116.041092 551.257324,114.297531 
	C554.201965,113.887016 556.678589,114.350906 555.511841,118.641388 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M273.196289,182.229584 
	C274.266113,180.837616 275.529327,179.693985 276.980408,178.275757 
	C278.950775,180.598679 278.338562,181.324020 273.196289,182.229584 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M165.014603,449.125305 
	C163.616455,449.067230 162.218323,449.009155 160.414658,448.839691 
	C160.954117,445.610138 162.524750,445.131409 164.922302,448.137451 
	C165.051895,448.680511 165.014603,449.125275 165.014603,449.125305 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M375.908600,348.939941 
	C374.621094,350.441803 373.144836,351.659302 371.370972,353.011230 
	C370.799500,349.762756 372.413483,348.333527 375.908600,348.939941 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M544.308594,235.953690 
	C544.666870,236.322083 544.691589,236.757507 544.674805,237.522705 
	C544.042847,238.903534 543.452332,239.954590 542.615112,241.486328 
	C541.888489,242.254196 541.408569,242.541397 540.928589,242.828583 
	C541.944092,240.559280 542.959595,238.289993 544.308594,235.953690 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M91.875565,427.117645 
	C92.788063,428.387512 93.410522,429.837250 94.031860,431.636322 
	C91.174911,431.487335 88.889877,430.691376 91.875565,427.117645 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M107.884270,434.735718 
	C106.237877,433.628540 104.601669,432.177124 102.971542,430.378662 
	C104.616562,431.484894 106.255501,432.938202 107.884270,434.735718 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M565.641113,176.893311 
	C565.735840,176.465469 565.830505,176.037628 566.139771,175.289215 
	C566.522705,174.962296 566.691040,174.955948 566.859436,174.949615 
	C566.541748,177.249954 566.224060,179.550278 565.568298,181.928680 
	C565.160034,181.562332 565.089783,181.117935 565.255005,180.108063 
	C565.540771,178.659485 565.590942,177.776413 565.641113,176.893311 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M96.881775,441.127625 
	C98.276146,442.173462 99.381615,443.417877 100.802460,444.710083 
	C101.117844,444.757874 101.145378,444.851196 101.064537,445.137421 
	C100.917084,445.618134 100.850464,445.812592 100.783852,446.007080 
	C98.012878,445.676971 95.502251,445.113800 96.881775,441.127625 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M266.102844,467.066162 
	C268.038849,465.880249 270.160156,464.977417 272.639343,464.063049 
	C270.760834,465.150787 268.524445,466.250000 266.102844,467.066162 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M564.899292,137.933838 
	C563.838013,135.868500 562.824158,133.359604 561.764893,130.481506 
	C565.651245,131.322418 564.763733,134.640442 564.899292,137.933838 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M405.134949,123.104263 
	C407.027283,122.234116 409.132782,121.664040 411.611450,121.158096 
	C409.772430,121.949600 407.560242,122.676956 405.134949,123.104263 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M463.081207,120.424644 
	C465.402283,120.745667 467.797638,121.426445 470.368347,122.446877 
	C468.080994,122.119148 465.618225,121.451775 463.081207,120.424644 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M314.892578,150.908112 
	C313.617615,152.131653 312.157074,153.079819 310.358459,154.072113 
	C311.582581,152.955078 313.144745,151.793900 314.892578,150.908112 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M196.773605,444.773071 
	C196.773605,444.773071 196.378632,444.868958 195.824005,444.876343 
	C194.493301,444.831665 193.717255,444.779602 192.941193,444.727539 
	C193.057114,442.047607 194.305328,441.361176 196.820953,443.172791 
	C196.914597,444.076691 196.844101,444.424866 196.773605,444.773071 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M566.830566,174.535400 
	C566.691040,174.955948 566.522705,174.962296 566.101562,174.960648 
	C565.880188,173.010605 565.911560,171.068558 566.268799,169.013550 
	C566.726074,169.079636 566.857422,169.258682 567.160889,169.723160 
	C567.155945,171.379456 566.978821,172.750336 566.830566,174.535400 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M101.790428,426.131897 
	C101.038139,426.111847 100.822510,425.930389 100.652328,425.418976 
	C102.166687,424.487305 103.733070,423.769623 105.299454,423.051910 
	C105.530716,423.627197 105.761986,424.202484 105.993248,424.777771 
	C104.738708,425.213989 103.484177,425.650177 101.790428,426.131897 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M368.880066,133.844421 
	C370.063049,132.892960 371.667786,131.999222 373.630737,131.127502 
	C372.426575,132.067062 370.864227,132.984604 368.880066,133.844421 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M471.529144,248.993713 
	C470.885895,250.382416 469.840302,251.776733 468.484680,253.301102 
	C467.911011,251.122772 467.573700,248.765274 471.529144,248.993713 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M533.963501,106.642334 
	C532.577087,106.308685 531.165100,105.608063 529.414062,104.847733 
	C528.955444,104.619041 528.835815,104.450066 528.542847,103.980965 
	C528.369507,103.680840 528.482056,103.470345 528.482056,103.470345 
	C530.300659,104.405350 532.119263,105.340355 533.963501,106.642334 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M535.140686,267.587555 
	C535.712219,266.590027 536.354126,265.938080 536.996033,265.286133 
	C537.266785,265.801575 537.537537,266.317017 537.808228,266.832458 
	C536.942505,267.199371 536.076782,267.566254 535.140686,267.587555 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M439.824524,102.842285 
	C438.628906,103.476685 437.204285,103.814690 435.396881,104.126175 
	C436.541260,103.581741 438.068359,103.063812 439.824524,102.842285 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M485.906067,88.292549 
	C484.610107,88.679550 483.210876,88.756065 481.400146,88.731865 
	C482.593353,88.414787 484.198059,88.198425 485.906067,88.292549 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M429.841705,104.874695 
	C428.676636,105.545792 427.279144,105.910278 425.510254,106.317749 
	C425.480347,102.898727 427.635956,103.960632 429.841705,104.874695 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M421.911469,106.938736 
	C420.978485,107.559967 419.863190,107.875572 418.387268,108.178284 
	C417.853271,104.253494 419.626404,105.045029 421.911469,106.938736 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M453.835266,100.858154 
	C452.631805,101.451050 451.202423,101.746399 449.391479,102.016678 
	C450.543060,101.514610 452.076233,101.037613 453.835266,100.858154 
z"/>
<path fill="#DF0405" opacity="1.000000" stroke="none" 
	d="
M408.485199,322.912354 
	C409.219025,322.805695 409.705475,322.862762 410.558533,322.900208 
	C409.804810,323.677155 408.684540,324.473785 407.266663,325.126434 
	C407.391968,324.346985 407.814880,323.711517 408.485199,322.912354 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M388.901611,116.929298 
	C387.950378,117.788445 386.808319,118.355804 385.338440,118.900894 
	C386.244049,118.131584 387.477417,117.384544 388.901611,116.929298 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M204.740143,442.730377 
	C204.048355,442.807526 203.356598,442.884674 202.331055,442.861450 
	C202.895645,442.053955 203.794006,441.346863 204.837738,441.179382 
	C204.902145,442.056091 204.821136,442.393250 204.740143,442.730377 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M288.385742,171.998779 
	C289.447784,171.032593 290.889465,170.065231 292.668030,169.075378 
	C291.591736,170.034470 290.178558,171.016022 288.385742,171.998779 
z"/>
<path fill="#BB0405" opacity="1.000000" stroke="none" 
	d="
M192.911530,444.923737 
	C193.717255,444.779602 194.493301,444.831665 195.648499,444.978821 
	C195.162552,445.880554 194.297470,446.687195 193.180817,446.871704 
	C192.892090,446.003601 192.854919,445.757629 192.817764,445.511627 
	C192.817764,445.511627 192.881851,445.119934 192.911530,444.923737 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M327.155884,151.173584 
	C327.029266,150.541519 327.107697,150.182266 327.281189,149.565582 
	C328.029907,149.234207 328.683594,149.160263 329.666656,149.128540 
	C329.117676,149.929306 328.239319,150.687851 327.155884,151.173584 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M142.995758,447.990112 
	C142.388718,448.417694 141.753494,448.544098 140.678650,448.441345 
	C140.139191,447.871857 140.039383,447.531525 139.939560,447.191193 
	C140.948914,447.357117 141.958252,447.523010 142.995758,447.990112 
z"/>
<path fill="#0B5D85" opacity="1.000000" stroke="none" 
	d="
M527.171753,98.017487 
	C527.252991,97.694489 527.625977,97.564751 527.818726,97.522240 
	C528.437012,97.639618 528.862427,97.799507 529.628174,97.989716 
	C530.563232,98.495972 531.158081,98.971909 531.869019,99.713455 
	C530.491211,99.832634 528.997253,99.686203 527.248352,99.269608 
	C527.025879,98.779800 527.058228,98.560135 527.171753,98.017487 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M559.175354,201.990509 
	C559.589478,202.054428 559.683472,202.234909 559.872681,202.705261 
	C559.613647,203.768661 559.259216,204.542145 558.885925,205.636749 
	C558.538757,206.018448 558.253845,206.164017 557.794128,206.496353 
	C558.002258,205.101120 558.428711,203.604080 559.175354,201.990509 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M199.145432,485.134644 
	C200.054764,484.547180 201.171066,484.249237 202.643463,483.998047 
	C201.783829,484.504578 200.568115,484.964386 199.145432,485.134644 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M520.147827,276.910126 
	C519.843262,276.470001 519.909790,276.033386 519.925232,275.268860 
	C519.874146,274.941010 519.946228,274.953278 520.235840,274.886597 
	C521.286194,274.160645 522.046997,273.501343 522.878418,272.903870 
	C522.949158,272.965729 523.087585,273.092865 523.087585,273.092865 
	C522.231384,274.366455 521.375183,275.640015 520.147827,276.910126 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M332.746765,436.780121 
	C333.623962,435.757355 334.908386,434.801392 336.538086,433.777893 
	C335.640259,434.755920 334.397095,435.801422 332.746765,436.780121 
z"/>
<path fill="#BB0405" opacity="1.000000" stroke="none" 
	d="
M224.029877,437.098389 
	C223.876236,439.031525 223.670227,440.881927 220.983002,438.582092 
	C221.445831,437.711945 222.005173,437.226593 223.030121,436.737915 
	C223.673782,436.855865 223.851822,436.977142 224.029877,437.098389 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M186.612442,446.735046 
	C186.259415,446.740295 185.906403,446.745514 185.168228,446.637268 
	C184.545273,446.362640 184.291428,446.232819 184.021545,446.134338 
	C184.923309,445.652649 185.825089,445.170959 186.866180,445.233826 
	C186.874481,446.097290 186.743469,446.416168 186.612442,446.735046 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M165.008484,449.400757 
	C165.014603,449.125275 165.051895,448.680511 165.068176,448.459473 
	C166.242264,448.352783 167.400055,448.467102 168.773148,448.855896 
	C167.910065,449.466583 166.831696,449.802765 165.394653,450.182587 
	C165.024780,450.042908 165.013580,449.859558 165.008484,449.400757 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M176.798233,447.800049 
	C177.010468,448.439178 176.866913,448.716675 175.915451,448.976501 
	C174.378372,448.922852 173.498352,448.808838 172.694519,448.441467 
	C172.880646,447.855927 172.990570,447.523712 173.100494,447.191498 
	C174.264771,447.299835 175.429047,447.408142 176.798233,447.800049 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M502.938873,89.674553 
	C501.814575,89.915543 500.634888,89.804665 499.182281,89.465340 
	C500.234070,89.265488 501.558777,89.294083 502.938873,89.674553 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M193.097076,486.040039 
	C194.027649,485.458893 195.158035,485.182465 196.643555,484.973816 
	C195.764740,485.475983 194.530807,485.910370 193.097076,486.040039 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M184.123810,487.095184 
	C185.043900,486.514221 186.166763,486.228699 187.644348,486.009766 
	C186.774918,486.514465 185.550751,486.952545 184.123810,487.095184 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M444.180115,377.219360 
	C444.588715,376.240845 445.197815,375.535156 446.041565,374.690125 
	C446.512238,375.403046 446.748322,376.255280 446.992188,377.553772 
	C447.000000,378.000000 447.001221,377.998779 447.001221,377.998779 
	C446.127686,377.829926 445.254181,377.661041 444.180115,377.219360 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M398.081421,394.774261 
	C398.574127,393.824951 399.459686,392.975739 400.655792,392.033569 
	C400.135620,392.918488 399.304932,393.896423 398.081421,394.774261 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M479.799622,236.072418 
	C479.682953,236.968979 479.221832,237.870911 478.499054,238.937469 
	C478.643311,238.094009 479.049225,237.085907 479.799622,236.072418 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M439.189423,380.247406 
	C439.774445,379.325958 440.546753,378.650024 441.645966,377.953827 
	C441.107483,378.786682 440.242096,379.639801 439.189423,380.247406 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M472.068176,331.894165 
	C471.796143,331.460266 471.886810,331.042236 471.967224,330.303284 
	C472.287262,329.884979 472.555603,329.698456 473.175018,329.218506 
	C473.968964,328.903839 474.349884,328.793427 474.730774,328.683044 
	C474.730774,328.683044 474.900696,328.877380 474.987854,328.973877 
	C474.193604,330.016907 473.312256,330.963470 472.068176,331.894165 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M486.664673,224.015869 
	C486.583099,224.914398 486.137817,225.814377 485.461853,226.906250 
	C483.227295,225.251541 483.924927,224.343033 486.664673,224.015869 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M339.051788,432.995819 
	C339.683777,432.095886 340.488434,431.483002 341.604187,430.839539 
	C341.018311,431.633575 340.121399,432.458221 339.051788,432.995819 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M287.798004,166.747345 
	C287.206940,167.640244 286.426788,168.295547 285.327881,168.981400 
	C285.875732,168.177887 286.742340,167.343811 287.798004,166.747345 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M370.771118,413.806152 
	C371.342346,412.831177 372.308197,411.910797 373.614014,410.951294 
	C373.024567,411.895050 372.095154,412.877930 370.771118,413.806152 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M224.208832,436.997681 
	C223.851822,436.977142 223.673782,436.855865 223.236298,436.538818 
	C223.582611,435.796265 224.188354,435.249512 224.953354,434.954712 
	C225.020111,435.552795 224.927612,435.898865 224.808258,436.512360 
	C224.781433,436.779755 224.387802,436.896973 224.208832,436.997681 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M400.111938,124.095688 
	C400.729645,123.542671 401.530884,123.270424 402.659668,123.043846 
	C402.089966,123.518494 401.192719,123.947479 400.111938,124.095688 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M478.941589,99.663345 
	C478.241791,100.032921 477.495789,100.069977 476.380371,100.045547 
	C476.972443,99.766312 477.933899,99.548569 478.941589,99.663345 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M505.946594,103.751984 
	C505.119690,103.953224 504.254364,103.812416 503.154175,103.438011 
	C503.915619,103.272919 504.911896,103.341431 505.946594,103.751984 
z"/>
<path fill="#5D0508" opacity="1.000000" stroke="none" 
	d="
M278.835510,175.806396 
	C279.352600,174.880096 279.869690,173.953781 280.712708,173.346588 
	C281.082794,174.170837 281.126923,174.675949 281.171021,175.181061 
	C280.681396,175.483444 280.191742,175.785812 279.336853,176.028961 
	C278.971619,175.969757 278.835510,175.806396 278.835510,175.806396 
z"/>
<path fill="#BB0405" opacity="1.000000" stroke="none" 
	d="
M216.769379,439.106812 
	C216.843613,439.961182 216.616364,440.721313 216.389130,441.481415 
	C215.929840,441.178101 215.470566,440.874786 215.002655,440.250122 
	C215.485321,439.623383 215.976593,439.317963 216.769379,439.106812 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M498.902802,146.117920 
	C499.517609,146.718918 499.845428,147.513397 500.174866,148.643494 
	C499.656281,148.089890 499.136047,147.200653 498.902802,146.117920 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M187.000488,446.860291 
	C186.743469,446.416168 186.874481,446.097290 187.051620,445.526733 
	C187.920197,445.309235 188.742661,445.343414 189.799225,445.889038 
	C190.030258,446.615387 190.027222,446.830353 190.024200,447.045288 
	C189.610733,447.076416 189.197281,447.107574 188.476868,447.178772 
	C187.909454,447.141022 187.648987,447.063263 187.000488,446.860291 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M411.725250,318.043213 
	C411.265747,318.960632 410.427582,319.866364 409.293091,320.870605 
	C409.780060,319.989899 410.563324,319.010712 411.725250,318.043213 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M413.901550,108.933357 
	C413.293030,109.544891 412.492920,109.862938 411.356598,110.178421 
	C411.916901,109.663857 412.813385,109.151863 413.901550,108.933357 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M172.943665,446.942291 
	C172.990570,447.523712 172.880646,447.855927 172.513931,448.477295 
	C172.257141,448.766418 171.992737,449.027649 171.992737,449.027649 
	C171.807739,448.942078 171.622742,448.856506 171.212341,448.562622 
	C171.586899,447.800537 172.186874,447.246796 172.943665,446.942291 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M145.802750,448.164673 
	C146.560242,448.321899 147.097610,448.495636 147.798492,448.903137 
	C147.089401,449.255768 146.216843,449.374664 145.205261,449.011536 
	C145.066238,448.529510 145.157013,448.063934 145.157013,448.063934 
	C145.157013,448.063934 145.582626,448.181122 145.802750,448.164673 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M139.620972,447.193848 
	C140.039383,447.531525 140.139191,447.871857 140.347351,448.456787 
	C139.435043,448.614105 138.414398,448.526886 137.168365,448.116425 
	C137.729446,447.594299 138.515915,447.395416 139.620972,447.193848 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M97.098503,414.884247 
	C96.501411,414.278381 96.189423,413.481995 95.893204,412.350494 
	C96.400528,412.908173 96.892059,413.800934 97.098503,414.884247 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M391.300690,407.521393 
	C390.551178,407.527069 390.019409,407.323303 389.487610,407.119537 
	C389.621521,406.847198 389.755432,406.574860 389.889374,406.302521 
	C390.432373,406.639008 390.975403,406.975464 391.300690,407.521393 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M228.148407,479.169922 
	C228.751862,478.579926 229.543228,478.261139 230.667328,477.990692 
	C230.112167,478.506409 229.224243,478.973785 228.148407,479.169922 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M280.691925,412.434784 
	C280.237549,412.717957 279.877991,412.750610 279.256165,412.708374 
	C279.576111,411.973816 280.158386,411.314148 280.912048,410.906586 
	C280.984558,411.500549 280.885681,411.842407 280.691925,412.434784 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M446.999390,378.000610 
	C447.607361,378.542511 448.215912,379.083801 448.824493,379.625092 
	C448.543976,379.920563 448.263458,380.216034 447.982941,380.511505 
	C447.659668,379.901794 447.336395,379.292084 447.007202,378.340576 
	C447.001221,377.998779 447.000000,378.000000 446.999390,378.000610 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M387.496338,244.671829 
	C388.036346,245.014099 388.360077,245.569839 388.683777,246.125565 
	C388.406830,246.252014 388.129913,246.378448 387.852997,246.504913 
	C387.662018,245.965042 387.471039,245.425171 387.496338,244.671829 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M285.280884,173.961899 
	C285.732452,173.321655 286.544891,172.693268 287.678558,172.035736 
	C287.213745,172.662292 286.427734,173.318039 285.280884,173.961899 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M349.967102,133.019379 
	C349.609650,133.788864 349.081146,134.281494 348.277222,134.811523 
	C348.599823,134.146805 349.197906,133.444656 349.967102,133.019379 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M376.131531,409.917145 
	C376.579132,409.214142 377.416687,408.545624 378.577759,407.818726 
	C378.108002,408.490784 377.314758,409.221222 376.131531,409.917145 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M525.078064,270.080475 
	C524.632202,271.027496 524.186401,271.974518 523.414062,273.007202 
	C523.087585,273.092865 522.949158,272.965729 522.922852,272.595490 
	C523.526367,271.425476 524.156250,270.625702 524.859741,269.888855 
	C524.933350,269.951782 525.078064,270.080475 525.078064,270.080475 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M466.702026,256.024109 
	C466.619446,256.634491 466.193268,257.238068 465.529541,257.987579 
	C465.647461,257.428131 466.002930,256.722717 466.702026,256.024109 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M123.509872,443.237701 
	C123.982300,443.618958 124.066658,443.956818 123.858658,444.601013 
	C122.672592,444.633026 121.778870,444.358734 120.885147,444.084473 
	C120.885147,444.084473 120.780167,443.717194 120.791801,443.527252 
	C121.576225,443.289642 122.349014,443.241974 123.509872,443.237701 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M526.850281,266.720398 
	C526.492249,267.773865 526.134277,268.827332 525.427124,269.980652 
	C525.078064,270.080475 524.933350,269.951782 524.902222,269.573639 
	C524.879333,268.776886 524.887634,268.358246 524.895935,267.939636 
	C524.895935,267.939636 524.988464,267.964417 525.237427,267.866699 
	C525.941040,267.419464 526.395630,267.069916 526.850281,266.720398 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M274.409119,416.036713 
	C274.370972,415.566772 274.669495,415.099457 275.122589,414.395752 
	C275.671600,414.140045 276.066071,414.120758 276.753082,414.159546 
	C276.278992,414.824829 275.512390,415.432068 274.409119,416.036713 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M365.305389,416.955200 
	C365.724884,416.265808 366.512329,415.597504 367.617035,414.886719 
	C367.180634,415.554932 366.427002,416.265594 365.305389,416.955200 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M445.884827,282.114105 
	C445.705170,282.992065 445.155334,283.875336 444.310181,284.881775 
	C444.514771,284.043121 445.014709,283.081268 445.884827,282.114105 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M359.218384,420.897278 
	C359.666229,420.186157 360.492889,419.520203 361.649048,418.824890 
	C361.184814,419.511169 360.391022,420.226837 359.218384,420.897278 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M482.760986,231.096069 
	C482.651184,232.014282 482.165283,232.966385 481.409271,234.112732 
	C481.554382,233.247955 481.969635,232.188950 482.760986,231.096069 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M540.939697,242.875168 
	C541.408569,242.541397 541.888489,242.254196 542.608276,241.799164 
	C542.417358,242.706589 541.986694,243.781860 541.198242,244.548798 
	C540.845276,243.802414 540.850220,243.364380 540.855103,242.926331 
	C540.855103,242.926331 540.950806,242.921753 540.939697,242.875168 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M526.879883,266.428040 
	C526.395630,267.069916 525.941040,267.419464 525.205688,267.830872 
	C525.370789,266.948639 525.816711,266.004578 526.598877,265.017151 
	C526.926514,265.361115 526.918030,265.748383 526.879883,266.428040 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M559.910278,125.770752 
	C559.459656,125.802452 559.052734,125.469254 558.422607,124.986679 
	C558.132080,124.432526 558.064819,124.027740 557.994019,123.314880 
	C558.645020,123.806488 559.299500,124.606178 559.910278,125.770752 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M234.387375,338.461548 
	C233.942078,338.346497 233.671906,338.063721 233.401749,337.780914 
	C233.518829,337.669067 233.635910,337.557251 233.752991,337.445404 
	C234.022827,337.728180 234.292679,338.010986 234.387375,338.461548 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M398.904449,113.903343 
	C398.562622,114.396027 398.070923,114.643616 397.294830,114.842224 
	C397.591827,114.414917 398.173218,114.036583 398.904449,113.903343 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M512.632263,284.024841 
	C512.929138,284.405609 512.880432,284.818115 512.757202,285.537445 
	C512.356140,285.968475 512.029541,286.092682 511.441101,286.247803 
	C511.548431,285.537994 511.917542,284.797302 512.632263,284.024841 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M369.165344,124.170349 
	C368.914673,124.784782 368.294525,125.365303 367.343506,125.951706 
	C367.607086,125.350540 368.201447,124.743492 369.165344,124.170349 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M323.142975,153.158752 
	C323.436310,152.596390 323.899353,152.277939 324.651428,151.920807 
	C324.397888,152.388977 323.855255,152.895828 323.142975,153.158752 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M506.992554,90.526672 
	C506.478516,90.745056 505.905487,90.680267 505.132751,90.397934 
	C505.599854,90.201424 506.266693,90.222466 506.992554,90.526672 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M511.970093,91.768509 
	C511.509979,92.044853 511.026520,92.030128 510.229187,91.822502 
	C510.319580,91.226486 510.723846,90.823380 511.128113,90.420273 
	C511.401001,90.772659 511.673889,91.125053 511.970093,91.768509 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M354.955139,131.008835 
	C354.621368,131.582047 354.123688,131.879990 353.325256,132.186081 
	C353.613434,131.707336 354.202332,131.220444 354.955139,131.008835 
z"/>
<path fill="#0B5D85" opacity="1.000000" stroke="none" 
	d="
M518.345703,94.281891 
	C517.688171,94.391228 516.911743,94.256226 516.287415,93.645630 
	C516.625549,93.100014 516.811462,93.029999 516.997437,92.959991 
	C517.407227,93.319183 517.817017,93.678368 518.345703,94.281891 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M322.839172,146.806244 
	C322.537598,147.349701 322.068909,147.659012 321.307739,147.988144 
	C321.567535,147.529343 322.119812,147.050720 322.839172,146.806244 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M392.883301,115.867157 
	C392.551819,116.370438 392.067535,116.634171 391.296326,116.855888 
	C391.583130,116.418457 392.156799,116.023041 392.883301,115.867157 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M382.106049,129.104492 
	C382.429932,128.564713 382.914246,128.275253 383.692566,127.993301 
	C383.413208,128.452148 382.839844,128.903473 382.106049,129.104492 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M516.953918,92.642883 
	C516.811462,93.029999 516.625549,93.100014 516.190186,93.333862 
	C515.660828,93.308540 515.380859,93.119400 514.947632,92.731766 
	C515.499695,92.464111 516.205078,92.394943 516.953918,92.642883 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M359.864532,128.850723 
	C359.560425,129.423325 359.087891,129.750397 358.315674,130.090210 
	C358.576019,129.603714 359.136047,129.104446 359.864532,128.850723 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M561.018677,127.939041 
	C560.437622,127.635651 560.124878,127.170761 559.817932,126.418732 
	C560.311462,126.680244 560.799194,127.228897 561.018677,127.939041 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M481.954590,132.599091 
	C481.715607,132.207031 481.717499,131.817108 481.747528,131.429367 
	C481.748352,131.418488 482.050537,131.430939 482.212219,131.432510 
	C482.207947,131.822174 482.203674,132.211853 481.954590,132.599091 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M364.074463,135.043549 
	C364.401093,134.477402 364.892059,134.176407 365.681335,133.862640 
	C365.399414,134.336151 364.819122,134.822433 364.074463,135.043549 
z"/>
<path fill="#780607" opacity="1.000000" stroke="none" 
	d="
M330.744202,143.251465 
	C330.519684,143.833206 329.981689,144.372009 329.121185,144.895905 
	C329.342712,144.323502 329.886719,143.766006 330.744202,143.251465 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M238.613647,421.539429 
	C239.058212,421.654327 239.330673,421.934357 239.582047,422.232239 
	C239.588486,422.239807 239.363815,422.442444 239.246918,422.554108 
	C238.977539,422.271729 238.708160,421.989319 238.613647,421.539429 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M528.561523,262.027466 
	C528.885315,262.390045 528.867188,262.815765 528.796997,263.560242 
	C528.422791,264.008636 528.100647,264.138306 527.522827,264.326660 
	C527.584656,263.620453 527.902100,262.855530 528.561523,262.027466 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M241.184113,430.930115 
	C240.881531,430.471039 240.934860,430.104797 241.024414,429.463501 
	C241.520981,429.197876 241.981323,429.207245 242.769821,429.330139 
	C243.070892,429.791077 243.043808,430.138458 243.012482,430.749023 
	C242.518829,431.015778 242.029419,431.019348 241.184113,430.930115 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M502.557922,180.049896 
	C502.762939,180.567429 502.682983,181.123764 502.388428,181.872559 
	C502.206848,181.406235 502.239868,180.747482 502.557922,180.049896 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M499.842529,192.043823 
	C500.007660,192.583206 499.851257,193.144684 499.478149,193.905762 
	C499.347961,193.425537 499.434509,192.745728 499.842529,192.043823 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M247.781128,427.407043 
	C247.487930,427.970276 246.895935,428.435425 245.986603,428.851624 
	C246.273605,428.304749 246.877960,427.806885 247.781128,427.407043 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M361.386475,426.460510 
	C360.942047,426.345642 360.669617,426.065643 360.418274,425.767853 
	C360.411865,425.760223 360.636475,425.557617 360.753326,425.445984 
	C361.022644,425.728333 361.291962,426.010681 361.386475,426.460510 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M493.797974,209.063309 
	C493.932495,209.599426 493.759491,210.147903 493.349854,210.868042 
	C493.238922,210.385025 493.364685,209.730347 493.797974,209.063309 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M319.137695,155.147217 
	C319.425354,154.577042 319.885101,154.254410 320.630188,153.876892 
	C320.380249,154.346237 319.845001,154.870499 319.137695,155.147217 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M100.056213,421.915527 
	C99.480362,421.614441 99.169563,421.144409 98.812180,420.387817 
	C99.284149,420.649719 99.802711,421.198151 100.056213,421.915527 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M368.461426,419.612610 
	C368.346283,420.058136 368.065582,420.331177 367.767090,420.583160 
	C367.759460,420.589569 367.556427,420.364471 367.444519,420.247314 
	C367.727570,419.977356 368.010590,419.707397 368.461426,419.612610 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M362.780853,418.812866 
	C363.020355,418.167206 363.642029,417.564545 364.601227,416.943115 
	C364.346863,417.568176 363.754944,418.212036 362.780853,418.812866 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M557.538513,223.387161 
	C557.653625,222.941742 557.936462,222.671539 558.219299,222.401337 
	C558.331116,222.518478 558.442932,222.635620 558.554749,222.752731 
	C558.271912,223.022598 557.989075,223.292496 557.538513,223.387161 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M303.123962,398.166992 
	C302.923615,398.819855 302.334412,399.446655 301.425476,400.161072 
	C301.648865,399.546082 302.191986,398.843506 303.123962,398.166992 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M299.400543,393.954712 
	C299.792847,393.715546 300.183044,393.717346 300.571075,393.747345 
	C300.581909,393.748199 300.569489,394.050629 300.567932,394.212402 
	C300.178009,394.208160 299.788086,394.203918 299.400543,393.954712 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M540.510315,242.988647 
	C540.850220,243.364380 540.845276,243.802414 540.813599,244.571289 
	C540.461121,245.037491 540.135559,245.172852 539.549561,245.362061 
	C539.581299,244.627579 539.873413,243.839264 540.510315,242.988647 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M338.094666,376.162048 
	C337.911285,376.826996 337.338654,377.469421 336.443237,378.199219 
	C336.648804,377.570892 337.177124,376.855194 338.094666,376.162048 
z"/>
<path fill="#3C0508" opacity="1.000000" stroke="none" 
	d="
M281.443726,175.126190 
	C281.126923,174.675949 281.082794,174.170837 281.055878,173.284821 
	C281.073090,172.903931 281.369385,172.878174 281.514587,172.876389 
	C282.014130,173.102997 282.368500,173.331406 282.868164,173.842957 
	C282.581116,174.441193 282.148773,174.756256 281.443726,175.126190 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M183.818268,446.261292 
	C184.291428,446.232819 184.545273,446.362640 184.909454,446.696594 
	C184.478867,447.096680 183.921921,447.323914 183.202896,447.340881 
	C183.129684,446.803833 183.321060,446.556366 183.818268,446.261292 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M311.173798,159.213593 
	C311.462738,158.642685 311.922943,158.304321 312.683350,157.948212 
	C312.437408,158.435699 311.891235,158.940918 311.173798,159.213593 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M314.999878,157.852890 
	C315.173645,157.203964 315.721924,156.574448 316.585876,155.865692 
	C316.392487,156.481750 315.883423,157.177017 314.999878,157.852890 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M487.940491,100.422455 
	C487.438721,100.667656 486.885590,100.640106 486.124634,100.417023 
	C486.574249,100.197556 487.231689,100.173622 487.940491,100.422455 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M101.011559,446.214294 
	C100.850464,445.812592 100.917084,445.618134 101.112877,445.142883 
	C101.749504,445.055634 102.256950,445.249176 102.935303,445.664185 
	C103.066727,446.117889 103.027252,446.350159 102.915131,446.929688 
	C102.308083,446.991821 101.773674,446.706665 101.011559,446.214294 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M197.068756,444.874329 
	C196.844101,444.424866 196.914597,444.076691 197.025330,443.465607 
	C197.580124,443.254700 198.094681,443.306671 198.937714,443.572266 
	C198.632080,444.182434 197.997986,444.579010 197.068756,444.874329 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M261.400726,339.954468 
	C261.792877,339.715454 262.182861,339.717285 262.570679,339.747406 
	C262.581604,339.748260 262.569153,340.050507 262.567596,340.212219 
	C262.177826,340.207916 261.788055,340.203613 261.400726,339.954468 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M489.613129,319.538452 
	C490.058289,319.653503 490.331085,319.933929 490.582855,320.232147 
	C490.589264,320.239746 490.364319,320.442657 490.247253,320.554474 
	C489.977509,320.271729 489.707794,319.988953 489.613129,319.538452 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M531.937134,258.652344 
	C531.590149,259.102325 531.275452,259.236298 530.718445,259.461121 
	C530.774109,258.747101 531.072144,257.942261 531.736755,257.109558 
	C532.058655,257.499908 532.014038,257.918121 531.937134,258.652344 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M205.039383,442.839783 
	C204.821136,442.393250 204.902145,442.056091 205.025146,441.462891 
	C205.579163,441.244873 206.091187,441.282928 206.933044,441.527710 
	C206.621475,442.139343 205.980042,442.544281 205.039383,442.839783 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M119.030663,442.516571 
	C118.670662,442.913788 118.196991,442.966095 117.334671,442.638184 
	C116.780769,441.832794 116.615524,441.407593 116.450287,440.982391 
	C116.450287,440.982391 116.421738,440.949524 116.397385,440.948547 
	C117.221016,441.355560 118.069008,441.763611 119.030663,442.516571 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M461.930786,99.943413 
	C461.568939,100.402847 461.065552,100.614960 460.285339,100.750107 
	C460.602081,100.347458 461.195679,100.021774 461.930786,99.943413 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M208.943695,440.505188 
	C208.578079,440.948822 208.160843,441.107422 207.409729,441.201782 
	C207.681274,440.831757 208.286667,440.525940 208.943695,440.505188 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M190.396133,447.054443 
	C190.027222,446.830353 190.030258,446.615387 190.046173,446.077087 
	C190.748276,445.685791 191.437515,445.617828 192.472260,445.530762 
	C192.854919,445.757629 192.892090,446.003601 192.964996,446.620972 
	C192.256516,447.016113 191.512283,447.039856 190.396133,447.054443 
z"/>
<path fill="#5D0508" opacity="1.000000" stroke="none" 
	d="
M277.330078,177.123795 
	C277.017944,176.968521 277.003174,176.755417 276.950073,176.223053 
	C277.341003,175.879013 277.770294,175.854202 278.517517,175.817902 
	C278.835510,175.806396 278.971619,175.969757 279.041016,176.052887 
	C278.616089,176.445984 278.121765,176.755966 277.330078,177.123795 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M528.390991,103.570457 
	C528.482056,103.470345 528.369507,103.680840 528.304565,103.781784 
	C528.239624,103.882736 528.284668,103.900719 528.291565,103.920990 
	C528.298462,103.941261 528.299866,103.670570 528.390991,103.570457 
z"/>
<path fill="#BB0405" opacity="1.000000" stroke="none" 
	d="
M213.766052,440.186493 
	C213.774429,440.464966 213.518356,440.681915 212.999359,440.865906 
	C212.991486,440.596985 213.246536,440.360962 213.766052,440.186493 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M144.979523,448.073181 
	C145.157013,448.063934 145.066238,448.529510 145.031723,448.764160 
	C144.847031,448.917419 144.696854,448.836060 144.365311,448.573120 
	C144.362198,448.233093 144.568222,448.130035 144.979523,448.073181 
z"/>
<path fill="#F70101" opacity="1.000000" stroke="none" 
	d="
M504.843018,176.221497 
	C504.698242,175.380920 504.700989,174.990463 505.064087,174.056824 
	C505.196045,169.339371 504.967590,165.165115 504.739136,160.990845 
	C504.712738,160.603790 504.686279,160.216721 504.645508,159.539871 
	C504.734161,157.800385 504.837158,156.350677 505.166107,154.304138 
	C504.941650,153.132721 504.491241,152.558151 504.040833,151.983582 
	C500.805634,138.838562 493.523529,128.879608 480.877960,123.494217 
	C475.001404,120.991547 468.837341,119.164009 462.364502,116.657425 
	C459.768066,112.259270 457.066895,109.777061 451.977966,111.707245 
	C450.654236,112.209343 448.755157,111.194519 447.120361,110.876419 
	C447.128540,110.292488 447.136688,109.708557 447.144867,109.124626 
	C449.066040,108.426186 450.940308,107.352402 452.917847,107.105438 
	C457.387238,106.547287 461.904999,106.348587 466.408356,106.099663 
	C469.125275,105.949486 471.852692,105.998138 474.574432,105.923157 
	C477.376740,105.845963 480.976990,107.118156 481.024628,102.223213 
	C483.398102,102.486725 485.771545,102.750237 488.622864,103.389000 
	C490.106628,104.143776 491.112579,104.523300 492.118530,104.902817 
	C497.921600,106.051079 503.724640,107.199333 509.803040,108.974548 
	C511.719727,111.733627 513.361084,113.865746 515.002441,115.997864 
	C510.535675,115.860207 505.988281,116.995682 502.462128,112.290001 
	C501.283630,110.717278 497.349823,111.209206 493.483063,110.555847 
	C495.529236,114.203117 496.870636,116.594162 498.212036,118.985214 
	C497.562103,119.459862 496.912170,119.934509 496.262238,120.409157 
	C494.140106,118.073967 492.072662,115.685226 489.866638,113.432205 
	C489.077881,112.626656 487.996002,111.725204 486.972931,111.623741 
	C485.519440,111.479568 483.996643,112.034027 482.503479,112.289978 
	C483.039856,113.682304 483.228424,115.426483 484.195862,116.382675 
	C485.570892,117.741760 487.491760,118.569504 489.227295,119.538490 
	C492.535522,121.385536 495.364685,123.427071 495.246857,127.878471 
	C495.221222,128.847397 496.503845,130.496490 497.466003,130.746445 
	C507.425598,133.333694 513.813110,144.359573 510.730743,153.894073 
	C510.500641,154.605865 510.318054,155.771606 510.698456,156.176300 
	C516.264587,162.097565 511.441040,169.626434 513.830078,176.126419 
	C514.834595,178.859467 514.106812,182.344986 508.810730,180.194626 
	C509.740936,181.567795 510.699402,182.922821 511.596069,184.317551 
	C514.237305,188.425812 512.491089,190.991562 509.216370,194.440231 
	C506.350281,197.458633 505.671814,202.546829 504.000000,206.709869 
	C503.119354,208.902786 502.286316,211.124039 501.249634,213.243393 
	C497.718140,220.463074 493.042175,227.284195 493.135620,235.763474 
	C493.152374,237.282074 494.091736,238.790497 494.787872,240.842438 
	C491.521729,240.513351 488.619568,240.220947 485.360535,239.965851 
	C485.003662,240.003174 484.997681,239.997742 485.000854,239.611328 
	C484.989410,237.146057 484.974854,235.067184 484.960266,232.988312 
	C487.861816,227.210495 490.763367,221.432693 494.159821,215.284561 
	C495.089600,213.606049 495.524445,212.297867 495.959320,210.989685 
	C496.000641,210.548370 496.041931,210.107040 496.323181,209.212677 
	C496.789886,208.545639 496.930450,208.285095 496.984833,207.977997 
	C497.061127,207.662903 497.212524,207.389359 497.977783,207.022095 
	C498.662262,205.920441 498.807953,204.954041 498.953613,203.987625 
	C500.965912,194.882294 502.978241,185.776947 504.843018,176.221497 
z"/>
<path fill="#F70101" opacity="1.000000" stroke="none" 
	d="
M535.017395,124.925804 
	C540.980713,132.937973 544.892212,141.596802 546.146484,151.768417 
	C548.784546,173.163086 542.122681,192.469299 534.230896,211.649063 
	C533.617615,213.139572 533.440735,214.809677 533.152954,216.801605 
	C532.792358,218.102783 532.339783,218.998657 531.456177,220.055588 
	C530.898804,220.286545 530.772400,220.356430 530.645996,220.426300 
	C530.836853,220.596054 531.027771,220.765793 531.218628,220.935547 
	C529.558105,224.404770 527.897522,227.873993 525.810669,231.738281 
	C525.285889,232.448746 525.187378,232.764130 525.088928,233.079529 
	C525.088928,233.079529 525.029358,233.076782 524.673218,233.058640 
	C522.865173,234.772522 521.262390,236.401749 519.996399,238.260422 
	C518.050598,241.117050 516.448181,244.207535 514.502319,247.064133 
	C509.024658,255.105576 503.652893,263.232941 497.829834,271.020142 
	C494.334442,275.694550 490.072632,279.795837 486.115845,284.115417 
	C486.076416,284.076141 485.997589,283.997589 485.997589,283.997589 
	C485.032837,282.650848 484.073364,281.300293 483.101501,279.958679 
	C482.428589,279.029694 481.121216,278.031952 481.228729,277.213623 
	C481.337677,276.384674 482.789368,275.194641 483.737762,275.109680 
	C487.228851,274.796844 489.294250,273.687439 490.512299,269.875061 
	C491.192474,267.746063 494.646393,266.558136 496.734955,264.808319 
	C498.696167,263.165192 499.401031,261.537415 497.729980,258.831604 
	C496.082672,256.164185 496.443115,253.115585 500.205627,250.971786 
	C500.737915,252.731079 501.170380,254.160416 502.010468,256.937134 
	C503.152557,254.699875 503.616150,253.428223 504.381836,252.376495 
	C505.551483,250.769821 507.018524,249.379776 508.189636,247.773987 
	C509.032928,246.617676 509.599884,245.259827 510.290131,243.991882 
	C508.782898,244.036407 507.136688,243.713425 505.803375,244.217758 
	C504.107758,244.859131 502.665680,246.170761 500.743652,247.437729 
	C500.705414,241.140198 506.714203,237.732666 515.848877,238.270584 
	C515.721191,237.121796 515.759277,235.983917 515.451355,234.948929 
	C514.091187,230.376526 515.570496,226.744217 519.866699,225.192184 
	C525.488159,223.161392 524.216675,217.917664 525.938782,214.088745 
	C527.567322,210.467896 533.402466,208.929016 531.145203,203.309662 
	C530.729248,202.274139 530.965393,200.232681 531.708313,199.591125 
	C538.858398,193.416916 537.700317,186.297394 534.452698,178.665466 
	C534.841675,178.614059 535.283386,178.483948 535.715149,178.511398 
	C537.327087,178.613983 539.930603,179.408844 540.341125,178.771317 
	C541.229370,177.391922 541.809753,174.792557 541.068115,173.572220 
	C538.607544,169.523727 539.773865,165.734329 540.572266,161.671173 
	C540.960144,159.697281 540.715698,157.208511 539.800049,155.451874 
	C537.992126,151.983246 536.374634,148.918121 537.704895,144.671997 
	C538.430298,142.356583 537.176331,139.485321 537.186279,136.866150 
	C537.200562,133.112396 534.111633,132.952530 531.837524,131.946396 
	C527.798645,130.159500 529.856445,126.476501 529.385925,123.412796 
	C532.580322,127.722961 532.580322,127.722961 535.017395,124.925804 
z"/>
<path fill="#F70101" opacity="1.000000" stroke="none" 
	d="
M315.008789,421.002441 
	C313.234131,417.973450 308.571442,416.269135 310.879974,410.503510 
	C312.750275,411.949402 314.150055,413.617096 315.916992,414.235199 
	C317.737732,414.872101 319.998047,414.865295 321.916870,414.450439 
	C324.198486,413.957184 326.321198,412.728821 329.140289,412.783295 
	C330.917847,409.887421 332.695404,406.991547 334.844788,403.489929 
	C343.141693,400.009247 351.885010,395.391571 358.023010,386.954651 
	C361.739197,381.846619 364.645020,380.790619 369.551392,382.378876 
	C372.026031,380.001190 374.296844,377.819336 377.431366,374.807648 
	C377.502869,371.466766 380.877045,368.731659 386.206787,368.791779 
	C387.870392,368.810547 390.141815,368.189117 391.105072,367.015472 
	C393.936401,363.565704 397.132233,362.060822 402.193054,364.030518 
	C400.376770,359.628174 402.266357,358.306702 405.246704,356.277435 
	C409.144165,353.623779 411.926880,349.526581 417.456116,349.739044 
	C418.336792,349.772888 419.235443,347.638855 420.235962,346.614716 
	C422.178345,344.626617 423.342438,341.061462 427.691010,343.005341 
	C428.075653,343.078491 428.153748,343.159424 428.083771,343.488342 
	C421.470306,349.604889 415.164612,355.694153 408.315369,361.093933 
	C401.674683,366.329315 394.344055,370.681213 387.573761,375.763885 
	C383.847656,378.561249 381.022034,382.634552 377.151733,385.149902 
	C361.276764,395.467407 345.208466,405.491791 329.087311,415.422821 
	C325.352417,417.723602 321.128723,419.230988 316.776978,421.075287 
	C315.952271,421.032196 315.480530,421.017334 315.008789,421.002441 
z"/>
<path fill="#F70101" opacity="1.000000" stroke="none" 
	d="
M428.036621,343.038025 
	C427.492889,341.302399 426.988220,339.607208 426.365143,337.514343 
	C428.876526,335.747986 430.201996,330.405548 435.785675,335.609039 
	C436.126404,332.713257 436.355682,330.764557 436.584991,328.815826 
	C436.828094,329.102936 437.071228,329.390015 437.314362,329.677094 
	C443.304077,322.924164 449.261932,316.142365 455.320190,309.451477 
	C456.163086,308.520538 457.452789,307.938934 458.621063,307.371368 
	C460.159729,306.623871 461.740417,305.903442 463.377777,305.429962 
	C467.986389,304.097229 468.202240,300.160797 468.648804,296.503937 
	C469.039551,293.303833 470.610687,291.694031 473.591370,290.442139 
	C476.371521,289.274475 480.116943,288.963135 481.081848,284.867554 
	C481.218323,284.288391 483.754456,284.274597 485.588745,283.998352 
	C485.997589,283.997589 486.076416,284.076141 486.046021,284.450562 
	C478.842651,293.226288 471.977997,301.917938 464.412598,309.949554 
	C456.379028,318.478149 447.780853,326.487488 439.223114,334.503510 
	C435.818176,337.692902 431.860046,340.291748 428.153748,343.159424 
	C428.153748,343.159424 428.075653,343.078491 428.036621,343.038025 
z"/>
<path fill="#F70101" opacity="1.000000" stroke="none" 
	d="
M521.047729,113.876785 
	C522.280212,114.690498 523.512634,115.504219 524.911133,116.971573 
	C525.719727,119.415184 526.362305,121.205154 527.004883,122.995117 
	C525.997498,128.342484 524.852844,128.901260 520.439453,126.200096 
	C523.590942,120.459877 523.590942,120.459877 517.417969,116.351463 
	C518.821350,115.288956 519.934570,114.582870 521.047729,113.876785 
z"/>
<path fill="#EE0101" opacity="1.000000" stroke="none" 
	d="
M527.337402,122.995728 
	C526.362305,121.205154 525.719727,119.415184 525.061157,117.260925 
	C528.273071,119.376114 531.501038,121.855583 534.873169,124.630432 
	C532.580322,127.722961 532.580322,127.722961 529.355774,123.067360 
	C528.556641,122.998039 528.113220,122.997185 527.337402,122.995728 
z"/>
<path fill="#EE0101" opacity="1.000000" stroke="none" 
	d="
M520.864014,113.574890 
	C519.934570,114.582870 518.821350,115.288956 517.352905,115.999657 
	C516.554199,116.002693 516.110840,116.001106 515.334961,115.998688 
	C513.361084,113.865746 511.719727,111.733627 510.064758,109.242264 
	C513.594177,110.346344 517.137207,111.809669 520.864014,113.574890 
z"/>
<path fill="#F70101" opacity="1.000000" stroke="none" 
	d="
M507.316223,128.449860 
	C507.799530,129.620407 507.951080,130.899307 508.102631,132.178192 
	C507.271332,132.081696 506.440063,131.985184 505.608765,131.888687 
	C506.067322,130.778519 506.525909,129.668365 507.316223,128.449860 
z"/>
<path fill="#F70101" opacity="1.000000" stroke="none" 
	d="
M370.247406,370.974304 
	C369.791260,369.972321 369.692657,368.977356 369.594055,367.982361 
	C370.554077,368.063721 371.514130,368.145081 372.474152,368.226440 
	C371.851074,369.144714 371.227997,370.062988 370.247406,370.974304 
z"/>
<path fill="#DF0405" opacity="1.000000" stroke="none" 
	d="
M400.991577,332.197449 
	C401.800629,331.019287 402.605164,330.120270 403.689148,329.094238 
	C404.476685,331.039154 407.651642,334.589264 401.340759,333.055359 
	C400.989197,332.848785 400.988129,332.662689 400.991577,332.197449 
z"/>
<path fill="#E60202" opacity="1.000000" stroke="none" 
	d="
M314.864990,421.290405 
	C315.480530,421.017334 315.952271,421.032196 316.707092,421.411194 
	C315.333618,422.862854 313.677032,423.950409 312.020447,425.037964 
	C312.014252,424.584320 312.008057,424.130646 312.000916,423.336670 
	C312.907043,422.523682 313.814087,422.051025 314.864990,421.290405 
z"/>
<path fill="#F70101" opacity="1.000000" stroke="none" 
	d="
M515.519287,122.401207 
	C514.967896,122.961006 514.283752,123.225479 513.599548,123.489960 
	C513.534607,122.920952 513.469727,122.351944 513.404785,121.782936 
	C514.065369,121.890579 514.725891,121.998222 515.519287,122.401207 
z"/>
<path fill="#F70101" opacity="1.000000" stroke="none" 
	d="
M449.987061,310.532806 
	C449.590088,309.951111 449.492676,309.373047 449.395264,308.794983 
	C449.803986,308.800140 450.212677,308.805328 450.621368,308.810486 
	C450.509766,309.385803 450.398193,309.961121 449.987061,310.532806 
z"/>
<path fill="#F70101" opacity="1.000000" stroke="none" 
	d="
M437.388031,322.462463 
	C436.941772,322.347198 436.668274,322.066071 436.415771,321.767181 
	C436.409393,321.759644 436.634857,321.556152 436.752197,321.444031 
	C437.022614,321.727417 437.293060,322.010834 437.388031,322.462463 
z"/>
<path fill="#02B1FC" opacity="1.000000" stroke="none" 
	d="
M461.234741,348.284363 
	C460.544647,350.619995 459.854553,352.955597 458.742493,355.698914 
	C457.878906,357.069427 457.437256,358.032227 456.992371,359.358063 
	C457.023773,360.204376 457.058380,360.687714 457.093018,361.171021 
	C457.067139,361.504913 456.913452,361.762604 456.055634,362.096802 
	C454.867035,363.312408 454.254761,364.375275 453.642517,365.438141 
	C453.642487,365.438141 453.617767,365.485718 453.600525,365.505432 
	C452.821472,365.623077 451.677856,365.433014 451.354523,365.861694 
	C445.589569,373.504608 437.734558,378.219971 429.188660,382.031799 
	C428.786652,382.211090 428.680237,383.053192 428.675598,383.780670 
	C427.811493,384.555115 426.707916,385.135468 425.278931,385.757385 
	C420.575287,386.405975 416.196838,387.011841 411.818756,387.620361 
	C407.807159,388.177917 403.796021,388.738342 399.400757,388.894836 
	C398.977081,387.953125 398.937286,387.414032 399.112061,386.621826 
	C401.124420,384.478333 402.922211,382.588013 404.720001,380.697693 
	C404.720001,380.697693 405.002472,380.942139 405.216553,380.892578 
	C406.297638,379.914001 407.164703,378.985016 408.031738,378.056030 
	C408.031738,378.056030 408.242798,377.795746 408.709167,377.749664 
	C409.499969,377.214874 409.824402,376.726166 410.148804,376.237427 
	C410.148804,376.237427 410.145782,376.135223 410.551178,376.137512 
	C414.631500,373.085846 418.306427,370.031952 421.981354,366.978058 
	C421.981354,366.978058 422.000000,367.000000 422.309387,367.010712 
	C422.974701,366.577209 423.330688,366.132996 423.686676,365.688782 
	C423.686676,365.688782 423.866882,365.876007 424.074097,365.779236 
	C424.428192,365.373993 424.575073,365.065521 424.721924,364.757050 
	C424.721924,364.757050 424.897766,364.927185 425.110840,364.807709 
	C425.482391,364.368317 425.640900,364.048401 425.799438,363.728485 
	C426.684937,362.916718 427.570404,362.104950 428.922333,361.441681 
	C432.033539,359.757507 434.916595,358.187531 437.279602,356.044220 
	C445.273102,348.794037 453.082275,341.340607 460.961914,333.964874 
	C461.558197,335.051453 462.170654,336.129608 462.743927,337.228210 
	C463.030273,337.777008 463.244934,338.363159 463.772827,339.298157 
	C464.022491,340.105011 463.991302,340.546143 463.637390,341.039429 
	C463.236267,342.074860 463.157837,343.058197 463.079437,344.041504 
	C463.045380,344.469513 463.011353,344.897552 462.608765,345.712830 
	C461.905029,346.828186 461.569885,347.556274 461.234741,348.284363 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M311.790222,425.299622 
	C313.677032,423.950409 315.333618,422.862854 317.060059,421.439392 
	C321.128723,419.230988 325.352417,417.723602 329.087311,415.422821 
	C345.208466,405.491791 361.276764,395.467407 377.151733,385.149902 
	C381.022034,382.634552 383.847656,378.561249 387.573761,375.763885 
	C394.344055,370.681213 401.674683,366.329315 408.315369,361.093933 
	C415.164612,355.694153 421.470306,349.604889 428.083771,343.488342 
	C431.860046,340.291748 435.818176,337.692902 439.223114,334.503510 
	C447.780853,326.487488 456.379028,318.478149 464.412598,309.949554 
	C471.977997,301.917938 478.842651,293.226288 486.085449,284.489868 
	C490.072632,279.795837 494.334442,275.694550 497.829834,271.020142 
	C503.652893,263.232941 509.024658,255.105576 514.502319,247.064133 
	C516.448181,244.207535 518.050598,241.117050 519.996399,238.260422 
	C521.262390,236.401749 522.865173,234.772522 524.669739,233.029053 
	C521.780579,238.954193 518.925232,245.143784 515.232117,250.784943 
	C496.193726,279.865784 473.406952,305.891479 448.254547,329.717621 
	C422.897095,353.738068 395.695190,375.574799 366.669525,395.149048 
	C332.895477,417.925537 297.141541,436.851105 259.366913,451.974579 
	C252.035049,454.910004 244.304642,456.849854 236.437927,459.198608 
	C240.043427,457.114655 243.864868,454.839783 247.913727,453.091492 
	C261.558685,447.199829 275.310333,441.555725 288.969147,435.695618 
	C296.552826,432.441956 304.033081,428.947327 311.790222,425.299622 
z"/>
<path fill="#06A6E4" opacity="1.000000" stroke="none" 
	d="
M398.897522,386.875000 
	C398.937286,387.414032 398.977081,387.953125 399.050934,388.902069 
	C397.384613,391.237579 395.684174,393.163208 393.835632,395.381165 
	C387.686829,399.445251 381.728149,403.285858 375.676727,406.974396 
	C363.415649,414.447815 351.096100,421.825287 338.817322,429.269806 
	C332.890045,432.863403 327.000458,436.519135 320.953369,440.444519 
	C313.852844,443.740753 306.960663,446.910400 299.919525,449.704590 
	C280.930298,457.240204 261.877625,464.615845 242.219803,471.926636 
	C241.386398,471.485291 241.183289,471.172211 241.341171,470.828186 
	C257.926300,464.434601 274.177765,458.139954 290.341736,451.628021 
	C292.491425,450.761993 294.196747,448.792908 296.107849,447.334656 
	C296.672424,447.064240 297.237000,446.793793 298.460144,446.609436 
	C299.742065,446.123260 300.365417,445.550964 300.988770,444.978699 
	C300.988800,444.978699 301.256073,444.756805 301.794312,444.803833 
	C303.493042,444.103729 304.653473,443.356628 305.813934,442.609497 
	C306.073029,442.438080 306.349060,442.300659 307.189240,442.139709 
	C308.524017,441.488586 309.311554,440.895020 310.099121,440.301483 
	C310.410095,440.128174 310.721039,439.954865 311.673462,439.800354 
	C319.471558,436.124146 326.689697,432.541626 333.755219,428.679871 
	C336.668793,427.087433 339.254486,424.895050 341.989319,422.975555 
	C341.989319,422.975555 342.241608,422.751190 342.767151,422.783752 
	C344.129150,422.090668 344.965607,421.365021 345.802063,420.639374 
	C345.802063,420.639374 346.087616,420.425385 346.577881,420.484222 
	C347.275391,420.212952 347.482635,419.882812 347.689880,419.552673 
	C348.122284,419.279663 348.554657,419.006653 349.623840,418.770935 
	C355.376526,415.828156 360.556244,412.949677 365.567017,409.802246 
	C366.956787,408.929260 367.845276,407.258209 368.963715,405.953308 
	C368.963715,405.953308 369.001221,405.998779 369.354797,406.011780 
	C377.231140,401.024261 384.760162,396.033142 392.253113,390.988464 
	C392.546631,390.790863 392.495605,390.081482 392.605164,389.610596 
	C392.605164,389.610596 392.719055,389.678040 393.043518,389.727997 
	C395.211151,388.810303 397.054352,387.842651 398.897522,386.875000 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M101.026917,454.322479 
	C102.580093,453.686554 104.160789,453.374023 105.806870,452.731689 
	C106.403107,452.577209 106.933960,452.752533 107.751198,452.932434 
	C108.361412,453.388794 108.685234,453.840607 109.111313,454.781006 
	C109.180359,455.497437 109.147156,455.725281 108.842361,456.159973 
	C108.349083,461.316681 112.105156,459.644806 114.929550,460.213684 
	C117.816750,461.975250 120.411438,463.533936 123.006119,465.092651 
	C122.506119,470.571564 122.071571,470.729523 117.381584,468.047150 
	C116.300262,467.428711 114.710754,467.698853 113.130966,467.854279 
	C111.935417,467.772675 110.964012,467.396851 109.636627,466.598175 
	C109.170815,465.814392 109.060982,465.453491 108.936531,464.742950 
	C107.739944,462.906830 106.643806,461.333466 105.302750,460.007874 
	C105.038383,459.746552 103.830070,460.440216 103.057098,460.693451 
	C102.186996,459.983917 101.316887,459.274414 100.395645,458.281982 
	C100.581161,456.881348 100.817802,455.763611 101.026917,454.322479 
z"/>
<path fill="#0B8BBF" opacity="1.000000" stroke="none" 
	d="
M461.110901,333.667908 
	C453.082275,341.340607 445.273102,348.794037 437.279602,356.044220 
	C434.916595,358.187531 432.033539,359.757507 429.206024,361.344482 
	C434.975830,355.572845 440.989014,350.110443 446.865997,344.505310 
	C454.697662,337.035919 462.423340,329.455444 470.518311,321.818268 
	C467.647980,325.599548 464.453918,329.485260 461.110901,333.667908 
z"/>
<path fill="#0979A9" opacity="1.000000" stroke="none" 
	d="
M295.743103,447.276947 
	C294.196747,448.792908 292.491425,450.761993 290.341736,451.628021 
	C274.177765,458.139954 257.926300,464.434601 241.118195,470.660706 
	C238.697937,470.712036 236.861618,470.899811 235.025314,471.087585 
	C241.966644,468.486053 248.955078,466.002686 255.839233,463.257690 
	C269.050201,457.989990 282.202026,452.573853 295.743103,447.276947 
z"/>
<path fill="#089DD3" opacity="1.000000" stroke="none" 
	d="
M234.685226,471.094910 
	C236.861618,470.899811 238.697937,470.712036 240.757202,470.691711 
	C241.183289,471.172211 241.386398,471.485291 241.832047,471.946289 
	C238.230957,473.405151 234.381927,474.700500 230.546036,476.033661 
	C228.726807,476.665955 226.932724,477.370575 225.033813,478.382599 
	C224.509750,478.814423 224.079102,478.906067 223.317139,478.979340 
	C218.390869,479.588623 213.796310,480.219116 209.200897,480.843292 
	C202.531097,481.749207 195.860687,482.650604 189.030136,482.428406 
	C197.188202,480.943268 205.410187,479.736938 213.484268,477.885468 
	C220.022568,476.386169 226.382919,474.110809 232.952087,471.875061 
	C233.503052,471.414398 233.924088,471.258301 234.685226,471.094910 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M556.942383,180.295990 
	C556.177063,185.458389 555.696838,191.055252 553.966980,196.234711 
	C551.303406,204.209396 547.782654,211.897797 544.305786,219.851044 
	C546.995789,211.295670 550.098022,202.627365 552.976135,193.885269 
	C554.417358,189.507370 555.450012,184.994980 556.942383,180.295990 
z"/>
<path fill="#0979A9" opacity="1.000000" stroke="none" 
	d="
M341.625122,422.970551 
	C339.254486,424.895050 336.668793,427.087433 333.755219,428.679871 
	C326.689697,432.541626 319.471558,436.124146 311.930145,439.724548 
	C321.450562,434.075104 331.355713,428.520325 341.625122,422.970551 
z"/>
<path fill="#097EB0" opacity="1.000000" stroke="none" 
	d="
M392.267151,389.660034 
	C392.495605,390.081482 392.546631,390.790863 392.253113,390.988464 
	C384.760162,396.033142 377.231140,401.024261 369.332764,405.992676 
	C376.614441,400.543610 384.271790,395.126556 392.267151,389.660034 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M155.070282,470.612549 
	C162.460480,470.727570 169.850677,470.842590 177.710785,471.091919 
	C177.205978,471.750641 176.230377,472.729004 175.256699,472.727081 
	C168.038910,472.712677 160.821487,472.503326 153.315140,471.798401 
	C153.039703,471.072052 153.053055,470.902588 153.401855,470.780701 
	C154.181641,470.756348 154.625961,470.684448 155.070282,470.612549 
z"/>
<path fill="#097EB0" opacity="1.000000" stroke="none" 
	d="
M368.612793,405.982300 
	C367.845276,407.258209 366.956787,408.929260 365.567017,409.802246 
	C360.556244,412.949677 355.376526,415.828156 349.875488,418.712830 
	C355.747528,414.415405 362.004730,410.213348 368.612793,405.982300 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M551.960938,129.384521 
	C556.687561,135.011826 558.329529,142.014542 558.374023,149.714508 
	C556.049072,143.241531 553.989624,136.504257 551.960938,129.384521 
z"/>
<path fill="#097EB0" opacity="1.000000" stroke="none" 
	d="
M399.112091,386.621826 
	C397.054352,387.842651 395.211151,388.810303 393.018616,389.666687 
	C396.455322,386.616364 400.241394,383.677307 404.373718,380.717957 
	C402.922211,382.588013 401.124420,384.478333 399.112091,386.621826 
z"/>
<path fill="#097EB0" opacity="1.000000" stroke="none" 
	d="
M421.635742,366.993866 
	C418.306427,370.031952 414.631500,373.085846 410.600464,376.151031 
	C413.926300,373.111420 417.608246,370.060547 421.635742,366.993866 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M220.878922,462.906433 
	C225.105026,461.448639 229.331131,459.990845 233.773880,459.034210 
	C233.965347,459.875427 233.940155,460.215485 233.914978,460.555542 
	C232.912079,460.742920 231.909164,460.930298 230.336823,461.120239 
	C229.157440,461.559753 228.547501,461.996735 227.937561,462.433716 
	C226.324295,462.726105 224.711029,463.018494 222.387314,463.194916 
	C221.410858,463.021423 221.144882,462.963928 220.878922,462.906433 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M136.853561,468.658875 
	C137.216324,468.192474 137.721283,468.048218 138.850204,468.156555 
	C142.002777,468.834045 144.531372,469.258942 147.059967,469.683838 
	C147.490067,469.743622 147.920166,469.803375 148.683823,470.194427 
	C149.024780,470.735168 149.032166,470.944641 149.039551,471.154083 
	C145.024948,470.429749 141.010361,469.705414 136.853561,468.658875 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M123.271210,464.988281 
	C120.411438,463.533936 117.816750,461.975250 115.009293,459.991943 
	C114.796524,459.567352 114.818840,459.099121 115.188339,459.061951 
	C116.053169,459.008118 116.548515,458.991425 117.043854,458.974701 
	C117.790298,459.347778 118.536743,459.720886 119.657501,460.416992 
	C120.425163,461.113922 120.818527,461.487823 121.211891,461.861725 
	C121.886383,461.950195 122.560875,462.038635 123.595428,462.495850 
	C123.990654,463.463593 124.025826,464.062653 124.060989,464.661682 
	C123.886086,464.735779 123.711189,464.809845 123.271210,464.988281 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M537.798767,110.534416 
	C539.746155,111.994804 541.786377,113.778099 543.747375,115.920670 
	C541.742676,114.472397 539.817139,112.664856 537.798767,110.534416 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M541.094055,225.625702 
	C540.187195,228.260529 539.014832,231.097137 537.508057,233.997208 
	C538.392029,231.316284 539.610291,228.571899 541.094055,225.625702 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M543.727295,116.632774 
	C545.431335,118.379128 547.171936,120.467087 548.852661,122.923294 
	C547.116455,121.185822 545.440186,119.080101 543.727295,116.632774 
z"/>
<path fill="#0B8BBF" opacity="1.000000" stroke="none" 
	d="
M485.749817,305.322754 
	C484.638153,307.172516 483.206696,309.078430 481.430939,311.008423 
	C482.534424,309.147919 483.982208,307.263397 485.749817,305.322754 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M126.913307,465.509949 
	C129.417740,465.890350 132.010696,466.607788 134.797424,467.624268 
	C132.328079,467.231201 129.664948,466.539093 126.913307,465.509949 
z"/>
<path fill="#0B8BBF" opacity="1.000000" stroke="none" 
	d="
M476.457062,315.700562 
	C475.311249,317.355408 473.842529,319.118011 471.996948,320.836609 
	C473.124756,319.131195 474.629456,317.469727 476.457062,315.700562 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M205.505981,467.270233 
	C207.277802,466.278198 209.394592,465.359314 211.750671,464.998535 
	C211.986694,465.766205 211.983429,465.975800 211.980164,466.185425 
	C211.647385,466.239075 211.314621,466.292725 210.365143,466.244019 
	C209.151794,466.472717 208.555145,466.803680 207.958496,467.134644 
	C207.255997,467.204254 206.553467,467.273834 205.505981,467.270233 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M182.999374,469.933655 
	C184.008118,469.900452 185.016846,469.867279 186.840912,469.896545 
	C188.234512,470.074280 188.812790,470.189606 189.391052,470.304932 
	C187.438065,470.750580 185.485092,471.196259 183.255371,471.108063 
	C182.985535,470.360687 182.992462,470.147186 182.999374,469.933655 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M203.007812,466.971252 
	C203.508224,466.988007 204.008636,467.004791 204.858734,467.166565 
	C203.298172,468.056183 201.387909,468.800751 199.230865,469.045227 
	C198.989090,468.349213 198.994125,468.153290 198.999146,467.957336 
	C199.403793,467.930420 199.808441,467.903503 200.838882,467.894318 
	C201.979050,467.598450 202.493439,467.284851 203.007812,466.971252 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M149.373734,471.213928 
	C149.032166,470.944641 149.024780,470.735168 149.009491,470.210571 
	C150.085632,469.615021 151.169662,469.334595 152.621948,469.338837 
	C153.015594,469.993378 153.041000,470.363281 153.066391,470.733154 
	C153.053055,470.902588 153.039703,471.072052 153.034744,471.495483 
	C151.931412,471.590881 150.819656,471.432343 149.373734,471.213928 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M558.811401,164.311371 
	C559.038574,165.636551 559.047180,167.265869 558.744141,169.008575 
	C558.485962,167.619827 558.539368,166.117645 558.811401,164.311371 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M511.748474,273.193146 
	C511.141724,274.614441 510.219330,276.141998 508.940308,277.706879 
	C509.533417,276.262573 510.483124,274.780975 511.748474,273.193146 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M549.147949,124.274887 
	C550.075562,125.186295 550.973755,126.436661 551.772461,128.051651 
	C550.821533,127.148788 549.970032,125.881317 549.147949,124.274887 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M189.653687,470.327942 
	C188.812790,470.189606 188.234512,470.074280 187.219055,469.881317 
	C188.038239,469.360443 189.294632,468.917206 190.768753,469.046326 
	C190.981888,469.835754 190.977280,470.052856 190.972672,470.269958 
	C190.620560,470.296967 190.268433,470.323975 189.653687,470.327942 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M535.442627,236.799881 
	C535.110779,238.081741 534.478699,239.505722 533.506897,240.996262 
	C533.825623,239.689194 534.483948,238.315598 535.442627,236.799881 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M558.524902,155.209717 
	C558.987061,156.153152 559.261902,157.346405 559.280640,158.767151 
	C558.795593,157.816269 558.566589,156.637894 558.524902,155.209717 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M532.503540,241.865051 
	C532.142639,243.140335 531.475952,244.544174 530.466370,245.997803 
	C530.814941,244.696289 531.506348,243.344955 532.503540,241.865051 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M557.851929,173.294678 
	C558.103333,174.298538 558.128784,175.584244 557.880432,177.005615 
	C557.613098,175.953018 557.619507,174.764786 557.851929,173.294678 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M514.369751,269.691772 
	C514.054993,270.713867 513.428406,271.844513 512.445374,273.016296 
	C512.745239,271.971741 513.401550,270.886017 514.369751,269.691772 
z"/>
<path fill="#097EB0" opacity="1.000000" stroke="none" 
	d="
M407.704773,378.045044 
	C407.164703,378.985016 406.297638,379.914001 405.082581,380.762085 
	C405.615662,379.798828 406.496704,378.916443 407.704773,378.045044 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M505.376404,281.834534 
	C505.021210,282.807983 504.365601,283.883820 503.357452,284.979767 
	C503.695251,283.978882 504.385620,282.957886 505.376404,281.834534 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M124.379051,464.800781 
	C124.025826,464.062653 123.990654,463.463593 123.927795,462.569275 
	C124.623001,462.904907 125.345894,463.535828 126.063164,464.511902 
	C125.604065,464.884644 125.150581,464.912262 124.379051,464.800781 
z"/>
<path fill="#0979A9" opacity="1.000000" stroke="none" 
	d="
M305.457092,442.607483 
	C304.653473,443.356628 303.493042,444.103729 301.963928,444.766541 
	C302.763580,443.989960 303.931915,443.297699 305.457092,442.607483 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M191.218979,470.459503 
	C190.977280,470.052856 190.981888,469.835754 190.997208,469.292816 
	C192.160034,468.989136 193.312134,469.011322 194.753571,469.207642 
	C193.850372,469.804230 192.657837,470.226624 191.218979,470.459503 
z"/>
<path fill="#0979A9" opacity="1.000000" stroke="none" 
	d="
M309.783569,440.232117 
	C309.311554,440.895020 308.524017,441.488586 307.406982,442.060883 
	C307.874390,441.414001 308.671204,440.788391 309.783569,440.232117 
z"/>
<path fill="#0979A9" opacity="1.000000" stroke="none" 
	d="
M345.467224,420.666962 
	C344.965607,421.365021 344.129150,422.090668 342.928406,422.733765 
	C343.420166,421.998993 344.276276,421.346771 345.467224,420.666962 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M182.816040,469.724243 
	C182.992462,470.147186 182.985535,470.360687 182.962463,470.895172 
	C182.501434,471.216034 182.056580,471.215942 181.299774,471.094482 
	C181.536118,470.486969 182.084412,470.000885 182.816040,469.724243 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M208.161194,467.309906 
	C208.555145,466.803680 209.151794,466.472717 210.113434,466.213196 
	C209.773590,466.684814 209.068756,467.084991 208.161194,467.309906 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M198.818848,467.748962 
	C198.994125,468.153290 198.989090,468.349213 198.973816,468.839417 
	C198.512817,469.146912 198.062042,469.160095 197.292114,469.057404 
	C197.528137,468.474548 198.083344,468.007599 198.818848,467.748962 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M212.170120,466.365234 
	C211.983429,465.975800 211.986694,465.766205 212.003082,465.241821 
	C212.505081,464.945282 212.993958,464.963562 213.829712,465.108398 
	C213.571091,465.671631 212.965591,466.108307 212.170120,466.365234 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M216.000748,463.986389 
	C216.530350,464.049622 217.059967,464.112854 217.761963,464.380981 
	C217.414017,464.893921 216.893677,465.201904 216.179108,465.044495 
	C215.990173,464.381531 215.995453,464.183960 216.000748,463.986389 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M220.676849,463.007690 
	C221.144882,462.963928 221.410858,463.021423 222.071930,463.190796 
	C221.732346,463.722961 220.997696,464.143311 219.979492,464.359161 
	C219.955551,463.806122 220.215149,463.457520 220.676849,463.007690 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M215.815765,463.767303 
	C215.995453,464.183960 215.990173,464.381531 215.970642,464.875854 
	C215.541519,465.213074 215.126617,465.253601 214.409225,465.215698 
	C214.614746,464.607605 215.122772,464.077911 215.815765,463.767303 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M228.229492,462.548828 
	C228.547501,461.996735 229.157440,461.559753 230.088089,461.176880 
	C229.779663,461.708618 229.150543,462.186279 228.229492,462.548828 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M234.212769,460.640808 
	C233.940155,460.215485 233.965347,459.875427 233.995850,459.278259 
	C234.492950,459.015808 234.984711,459.010529 235.828003,459.124237 
	C235.623230,459.737518 235.066910,460.231812 234.212769,460.640808 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M516.438843,266.749756 
	C516.404358,267.406677 516.066711,268.167450 515.383850,268.972290 
	C515.404358,268.295441 515.770020,267.574524 516.438843,266.749756 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M524.360229,254.833252 
	C524.373108,255.467361 524.105103,256.211243 523.507812,257.008850 
	C523.478760,256.356110 523.779053,255.649567 524.360229,254.833252 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M518.438232,263.757660 
	C518.432495,264.412079 518.130249,265.177643 517.492493,266.001587 
	C517.485168,265.329590 517.813416,264.599213 518.438232,263.757660 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M520.469727,260.774658 
	C520.452271,261.436218 520.126709,262.188873 519.466309,262.993256 
	C519.474854,262.318573 519.818237,261.592194 520.469727,260.774658 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M522.486023,257.813721 
	C522.475952,258.459656 522.170471,259.213867 521.531189,260.021606 
	C521.528442,259.357422 521.859558,258.639679 522.486023,257.813721 
z"/>
<path fill="#0979A9" opacity="1.000000" stroke="none" 
	d="
M300.672272,444.953949 
	C300.365417,445.550964 299.742065,446.123260 298.767548,446.544617 
	C299.062866,445.905518 299.709320,445.417358 300.672272,444.953949 
z"/>
<path fill="#0B8BBF" opacity="1.000000" stroke="none" 
	d="
M487.748810,303.173340 
	C487.776550,303.650208 487.502563,304.195587 486.906433,304.785675 
	C486.871918,304.300873 487.159515,303.771393 487.748810,303.173340 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M529.485596,246.855820 
	C529.571716,247.325806 529.389221,247.955627 528.856934,248.647293 
	C528.743652,248.144638 528.980286,247.580139 529.485596,246.855820 
z"/>
<path fill="#097EB0" opacity="1.000000" stroke="none" 
	d="
M409.850891,376.242859 
	C409.824402,376.726166 409.499969,377.214874 408.862427,377.681946 
	C408.883850,377.189636 409.218414,376.718964 409.850891,376.242859 
z"/>
<path fill="#097EB0" opacity="1.000000" stroke="none" 
	d="
M423.394714,365.715881 
	C423.330688,366.132996 422.974701,366.577209 422.299316,367.000458 
	C422.354187,366.567322 422.728455,366.155151 423.394714,365.715881 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M542.433594,222.835999 
	C542.576599,223.301773 542.459412,223.959915 542.005127,224.737457 
	C541.836426,224.247330 542.004883,223.637848 542.433594,222.835999 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M525.579895,252.940292 
	C525.675293,253.406708 525.491760,253.999847 524.982544,254.656250 
	C524.871521,254.168671 525.086182,253.617844 525.579895,252.940292 
z"/>
<path fill="#097EB0" opacity="1.000000" stroke="none" 
	d="
M424.474121,364.797089 
	C424.575073,365.065521 424.428192,365.373993 424.004364,365.669617 
	C423.893738,365.383545 424.060028,365.110352 424.474121,364.797089 
z"/>
<path fill="#097EB0" opacity="1.000000" stroke="none" 
	d="
M425.535217,363.779663 
	C425.640900,364.048401 425.482391,364.368317 425.040009,364.707947 
	C424.927734,364.428741 425.099365,364.129791 425.535217,363.779663 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M500.587402,287.858154 
	C500.699585,288.103821 500.543518,288.434113 500.110687,288.836121 
	C499.995667,288.586121 500.157410,288.264465 500.587402,287.858154 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M525.337219,232.985931 
	C525.187378,232.764130 525.285889,232.448746 525.620361,232.030212 
	C525.766113,232.248840 525.675842,232.570587 525.337219,232.985931 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M531.335327,220.806610 
	C531.027771,220.765793 530.836853,220.596054 530.645996,220.426300 
	C530.772400,220.356430 530.898804,220.286545 531.241821,220.273270 
	C531.458435,220.329880 531.452087,220.677689 531.335327,220.806610 
z"/>
<path fill="#0A7AAB" opacity="1.000000" stroke="none" 
	d="
M506.334137,280.643433 
	C506.482727,280.884369 506.398254,281.253571 506.011719,281.675079 
	C505.840088,281.408844 505.970581,281.090240 506.334137,280.643433 
z"/>
<path fill="#0979A9" opacity="1.000000" stroke="none" 
	d="
M347.431610,419.604309 
	C347.482635,419.882812 347.275391,420.212952 346.755249,420.464905 
	C346.686005,420.143158 346.929657,419.899536 347.431610,419.604309 
z"/>
<path fill="#BB0405" opacity="1.000000" stroke="none" 
	d="
M120.922150,444.455292 
	C121.778870,444.358734 122.672592,444.633026 123.919022,444.857910 
	C124.942993,444.924500 125.614250,445.040527 126.683075,445.555237 
	C127.716873,446.314667 128.353104,446.675415 128.994232,447.273560 
	C128.999115,447.510925 128.997025,447.985809 128.997025,447.985809 
	C125.166824,449.860382 122.398354,449.034027 120.922150,444.455292 
z"/>
<path fill="#E60202" opacity="1.000000" stroke="none" 
	d="
M147.027252,469.328308 
	C144.531372,469.258942 142.002777,468.834045 139.242767,468.109833 
	C142.256287,464.187744 144.600769,466.749542 147.027252,469.328308 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M116.990242,458.626617 
	C116.548515,458.991425 116.053169,459.008118 114.907341,459.009399 
	C113.745705,458.468292 113.234558,457.942566 112.718338,457.095642 
	C112.481300,456.174103 112.249352,455.573853 112.017403,454.973572 
	C113.657150,456.075226 115.296890,457.176880 116.990242,458.626617 
z"/>
<path fill="#BB0405" opacity="1.000000" stroke="none" 
	d="
M116.184593,440.972839 
	C116.615524,441.407593 116.780769,441.832794 116.976852,442.598938 
	C116.036545,442.840332 115.065392,442.740753 114.029999,442.320740 
	C114.616806,441.654633 115.267853,441.308960 116.184593,440.972839 
z"/>
<path fill="#BB0405" opacity="1.000000" stroke="none" 
	d="
M172.037933,449.371277 
	C171.992737,449.027649 172.257141,448.766418 172.437729,448.730621 
	C173.498352,448.808838 174.378372,448.922852 175.700150,449.089386 
	C174.788971,449.332886 173.436066,449.523895 172.037933,449.371277 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M112.016678,454.982788 
	C112.249352,455.573853 112.481300,456.174103 112.406570,457.005463 
	C111.104568,456.808716 110.109261,456.380920 109.113953,455.953125 
	C109.147156,455.725281 109.180359,455.497437 109.340302,454.946655 
	C109.467049,454.623657 109.554710,454.538849 109.994125,454.687469 
	C110.964264,454.883026 111.494987,454.929993 112.025703,454.976959 
	C112.025703,454.976959 112.015961,454.991974 112.016678,454.982788 
z"/>
<path fill="#E60202" opacity="1.000000" stroke="none" 
	d="
M153.401855,470.780701 
	C153.041000,470.363281 153.015594,469.993378 152.980225,469.344910 
	C153.654633,469.388702 154.339020,469.711090 155.046844,470.323029 
	C154.625961,470.684448 154.181641,470.756348 153.401855,470.780701 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M202.831970,466.760254 
	C202.493439,467.284851 201.979050,467.598450 201.142822,467.882690 
	C201.432709,467.418610 202.044418,466.983917 202.831970,466.760254 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M111.989700,454.685852 
	C111.494987,454.929993 110.964264,454.883026 110.054092,454.698578 
	C110.434319,454.505615 111.194000,454.450165 111.989700,454.685852 
z"/>
<path fill="#BB0405" opacity="1.000000" stroke="none" 
	d="
M236.190765,434.719025 
	C236.382294,434.118958 236.895676,433.587341 237.713638,433.035034 
	C237.516357,433.605408 237.014496,434.196442 236.190765,434.719025 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M129.330002,447.994751 
	C128.997025,447.985809 128.999115,447.510925 129.289383,447.257172 
	C129.776154,446.989868 129.972656,446.976288 130.387054,446.968475 
	C130.604935,446.974274 131.040878,446.977173 131.040878,446.977173 
	C131.040878,446.977173 131.020279,446.993774 131.014709,446.988892 
	C131.006500,447.156647 131.003845,447.329346 130.996857,447.762329 
	C130.549347,448.016296 130.106171,448.009979 129.330002,447.994751 
z"/>
<path fill="#BB0405" opacity="1.000000" stroke="none" 
	d="
M260.170471,423.371948 
	C260.358887,422.949097 260.726166,422.750549 261.408386,422.528564 
	C261.265350,422.868835 260.807373,423.232513 260.170471,423.371948 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M121.136108,461.582764 
	C120.818527,461.487823 120.425163,461.113922 119.981293,460.456451 
	C120.307297,460.549866 120.683807,460.926819 121.136108,461.582764 
z"/>
<path fill="#BB0405" opacity="1.000000" stroke="none" 
	d="
M268.054321,420.734131 
	C267.873810,420.731812 267.961365,420.328705 268.022278,420.130737 
	C268.217896,420.116577 268.365509,420.293304 268.473419,420.491669 
	C268.489471,420.521271 268.318970,420.652313 268.054321,420.734131 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M565.548096,147.317413 
	C566.187134,149.452011 566.562683,151.862320 566.784424,154.685883 
	C566.181946,152.597107 565.733215,150.095123 565.548096,147.317413 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M524.552612,267.974060 
	C524.887634,268.358246 524.879333,268.776886 524.828613,269.510712 
	C524.156250,270.625702 523.526367,271.425476 522.852112,272.533630 
	C522.046997,273.501343 521.286194,274.160645 520.222107,274.853149 
	C521.348938,272.593750 522.779114,270.301147 524.552612,267.974060 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M497.021332,304.565491 
	C496.714142,304.864258 496.537994,304.861938 496.107117,304.788513 
	C496.984711,303.145050 498.117004,301.572723 499.594910,299.987244 
	C499.011108,301.404205 498.081726,302.834320 497.021332,304.565491 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M474.888611,328.407196 
	C474.349884,328.793427 473.968964,328.903839 473.290527,328.998596 
	C474.082581,327.652435 475.172241,326.321960 476.605774,324.979187 
	C476.315277,326.021729 475.680847,327.076538 474.888611,328.407196 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M463.960083,340.987274 
	C463.991302,340.546143 464.022491,340.105011 464.064911,339.332153 
	C464.610291,338.116425 465.144470,337.232391 465.996399,336.292175 
	C466.015076,337.968750 465.715973,339.701538 464.947479,341.608002 
	C464.247253,341.554779 464.074615,341.289978 463.960083,340.987274 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M508.644928,289.020020 
	C508.179382,290.228607 507.366547,291.458954 506.233673,292.746887 
	C506.708313,291.550262 507.502991,290.296051 508.644928,289.020020 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M565.345703,176.963074 
	C565.590942,177.776413 565.540771,178.659485 565.231445,179.768005 
	C564.998352,179.006577 565.024292,178.019699 565.345703,176.963074 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M504.589386,293.995270 
	C504.878418,294.364624 504.848236,294.762238 504.761597,295.456726 
	C504.262756,296.045105 503.820343,296.336639 503.112915,296.614014 
	C503.321960,295.741089 503.796051,294.882294 504.589386,293.995270 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M478.456055,322.930664 
	C478.362732,323.537659 477.962677,324.192749 477.263672,324.899597 
	C477.359589,324.293823 477.754456,323.636292 478.456055,322.930664 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M502.039490,297.233765 
	C502.077637,297.713074 501.803406,298.227173 501.230804,298.796173 
	C501.197327,298.323547 501.462219,297.796021 502.039490,297.233765 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M495.441010,304.890930 
	C495.459137,305.305908 495.178589,305.762665 494.617096,306.276672 
	C494.604889,305.866852 494.873627,305.399780 495.441010,304.890930 
z"/>
<path fill="#0A7BAC" opacity="1.000000" stroke="none" 
	d="
M494.030029,306.330566 
	C494.083893,306.823944 493.821198,307.328369 493.267944,307.898682 
	C493.222748,307.423615 493.468079,306.882629 494.030029,306.330566 
z"/>
<path fill="#EE0101" opacity="1.000000" stroke="none" 
	d="
M480.558899,102.151543 
	C480.976990,107.118156 477.376740,105.845963 474.574432,105.923157 
	C471.852692,105.998138 469.125275,105.949486 466.408356,106.099663 
	C461.904999,106.348587 457.387238,106.547287 452.917847,107.105438 
	C450.940308,107.352402 449.066040,108.426186 447.144867,109.124626 
	C447.136688,109.708557 447.128540,110.292488 447.120361,110.876419 
	C448.755157,111.194519 450.654236,112.209343 451.977966,111.707245 
	C457.066895,109.777061 459.768066,112.259270 461.963379,116.635483 
	C456.901794,116.661232 451.798828,116.025871 446.703369,116.081078 
	C438.446228,116.170532 430.194031,116.713120 421.325195,116.833801 
	C420.125549,117.015823 419.540405,117.428421 418.955231,117.841003 
	C417.579834,117.914536 416.204407,117.988060 414.416321,117.636719 
	C414.010590,115.495621 414.017548,113.779381 414.024536,112.063148 
	C424.806763,109.602867 435.562042,107.014236 446.384064,104.743706 
	C450.890228,103.798286 455.538605,103.530800 460.740997,103.210648 
	C462.254669,103.033867 463.149628,102.601479 464.044617,102.169098 
	C469.394135,102.139351 474.743652,102.109612 480.558899,102.151543 
z"/>
<path fill="#D80303" opacity="1.000000" stroke="none" 
	d="
M413.644104,112.076889 
	C414.017548,113.779381 414.010590,115.495621 414.002960,117.646202 
	C401.383698,121.392120 388.738403,124.606590 376.160492,128.066071 
	C371.536407,129.337891 367.074493,131.196976 362.532379,132.770187 
	C361.270172,133.207367 359.985718,133.580170 358.460144,133.255814 
	C376.173492,124.716133 394.354767,117.463127 413.644104,112.076889 
z"/>
<path fill="#D80303" opacity="1.000000" stroke="none" 
	d="
M463.708130,102.118790 
	C463.149628,102.601479 462.254669,103.033867 461.172058,103.204208 
	C461.780182,102.650940 462.575928,102.359703 463.708130,102.118790 
z"/>
<path fill="#D80303" opacity="1.000000" stroke="none" 
	d="
M492.030090,104.567406 
	C491.112579,104.523300 490.106628,104.143776 489.033447,103.421036 
	C489.958008,103.462540 490.949829,103.847267 492.030090,104.567406 
z"/>
<path fill="#DF0405" opacity="1.000000" stroke="none" 
	d="
M504.035034,152.279816 
	C504.491241,152.558151 504.941650,153.132721 505.147034,153.866882 
	C504.611053,153.543015 504.320129,153.059525 504.035034,152.279816 
z"/>
<path fill="#D80303" opacity="1.000000" stroke="none" 
	d="
M419.251221,117.873245 
	C419.540405,117.428421 420.125549,117.015823 420.866272,116.843224 
	C420.530304,117.357307 420.038757,117.631386 419.251221,117.873245 
z"/>
<path fill="#050505" opacity="1.000000" stroke="none" 
	d="
M107.701721,528.357178 
	C117.187271,529.734375 120.498436,533.297180 121.054825,542.428589 
	C121.186127,544.583496 121.198967,546.756042 121.099960,548.912842 
	C120.586220,560.103760 116.674728,563.555176 104.901878,562.814758 
	C104.901878,552.080017 104.854752,541.304932 105.004166,530.532593 
	C105.014511,529.786438 106.487747,529.060608 107.701721,528.357178 
z"/>
<path fill="#090909" opacity="1.000000" stroke="none" 
	d="
M227.779388,560.213013 
	C230.221359,558.126343 232.485458,556.197937 235.055222,554.853821 
	C236.900345,553.888855 239.153854,553.704529 241.226654,553.171509 
	C242.629807,559.881287 240.908127,563.833435 235.805344,564.943359 
	C232.104462,565.748291 228.263580,566.076721 227.779388,560.213013 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M155.723785,553.958557 
	C157.041504,553.661072 157.979721,553.432129 158.933685,553.199402 
	C160.267349,561.246216 157.422318,565.625488 150.805328,565.207947 
	C148.971695,565.092285 145.936752,563.192932 145.808090,561.880981 
	C145.626434,560.028687 147.205490,557.418274 148.826233,556.136414 
	C150.504883,554.808716 153.132019,554.680176 155.723785,553.958557 
z"/>
<path fill="#DF0405" opacity="1.000000" stroke="none" 
	d="
M484.691772,233.186493 
	C484.974854,235.067184 484.989410,237.146057 484.997284,239.607788 
	C483.917358,239.990479 482.844177,239.990311 481.367432,239.978973 
	C482.117035,237.773422 483.270142,235.579041 484.691772,233.186493 
z"/>
<path fill="#DF0405" opacity="1.000000" stroke="none" 
	d="
M440.373810,292.988892 
	C441.431091,291.393097 442.890900,289.805359 444.632080,288.056610 
	C446.654968,292.126740 444.766205,293.413818 440.373810,292.988892 
z"/>
<path fill="#DF0405" opacity="1.000000" stroke="none" 
	d="
M413.304993,320.958252 
	C414.079590,319.705841 415.238312,318.468872 416.690460,317.103577 
	C415.885651,318.308075 414.787415,319.640900 413.304993,320.958252 
z"/>
<path fill="#DF0405" opacity="1.000000" stroke="none" 
	d="
M480.256927,240.899765 
	C480.102051,240.675293 480.267670,240.400635 480.757812,240.080444 
	C480.909241,240.305237 480.740906,240.576813 480.256927,240.899765 
z"/>
<path fill="#090909" opacity="1.000000" stroke="none" 
	d="
M474.387268,546.726318 
	C476.106537,541.823853 479.471161,540.306946 483.923798,541.035400 
	C488.046722,541.709900 489.459442,544.717712 489.913757,549.067322 
	C485.034698,549.067322 480.317383,549.122192 475.605865,548.982056 
	C475.136810,548.968018 474.703064,547.766052 474.387268,546.726318 
z"/>
<path fill="#090909" opacity="1.000000" stroke="none" 
	d="
M366.951233,553.837585 
	C366.564819,556.034058 366.751404,558.333740 365.691071,559.473267 
	C363.704193,561.608521 361.015594,564.403442 358.581116,564.440552 
	C356.143555,564.477539 352.298676,561.959229 351.505890,559.704712 
	C350.219299,556.045837 350.410217,551.622559 350.923645,547.644043 
	C351.442230,543.625183 354.492157,541.242004 358.667908,541.340820 
	C362.585510,541.433533 365.123291,543.770447 366.089966,547.551697 
	C366.576965,549.456665 366.683105,551.458923 366.951233,553.837585 
z"/>
<path fill="#0C6E98" opacity="1.000000" stroke="none" 
	d="
M86.581902,444.947357 
	C86.693520,443.899902 86.836777,443.188538 87.045570,441.937195 
	C87.292740,441.382782 87.474380,441.368256 87.925903,441.823669 
	C88.480812,442.530121 88.765831,442.766663 89.050858,443.003204 
	C89.412636,443.680573 89.774414,444.357941 90.181870,445.626221 
	C90.512161,446.479523 90.796776,446.741943 91.081390,447.004364 
	C91.081398,447.004364 91.203690,447.270508 91.196358,447.695343 
	C91.448929,448.408844 91.708839,448.697540 91.968758,448.986206 
	C91.968765,448.986206 92.185013,449.375671 92.241585,449.590820 
	C91.727539,450.715668 91.156914,451.625336 90.340836,452.757507 
	C88.934769,450.414459 87.774147,447.848938 86.581902,444.947357 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M103.125549,460.973877 
	C103.830070,460.440216 105.038383,459.746552 105.302750,460.007874 
	C106.643806,461.333466 107.739944,462.906830 108.612381,464.728943 
	C107.871452,465.040497 107.440048,465.016449 107.008644,464.992371 
	C107.008644,464.992371 106.729195,464.801117 106.425308,464.546021 
	C105.825317,464.121735 105.529221,463.952515 105.233109,463.783325 
	C105.233109,463.783325 105.009651,463.940948 105.027031,463.603973 
	C104.767471,463.060455 104.490540,462.853912 104.213593,462.647369 
	C103.873734,462.183014 103.533875,461.718658 103.125549,460.973877 
z"/>
<path fill="#0B5D85" opacity="1.000000" stroke="none" 
	d="
M107.066505,465.340088 
	C107.440048,465.016449 107.871452,465.040497 108.626999,465.078552 
	C109.060982,465.453491 109.170815,465.814392 109.356781,466.447876 
	C108.663399,466.376221 107.893883,466.032013 107.066505,465.340088 
z"/>
<path fill="#0C6E98" opacity="1.000000" stroke="none" 
	d="
M102.773407,467.155396 
	C102.314667,467.176147 101.787575,466.870514 101.135117,466.283539 
	C101.574860,466.277771 102.139961,466.553345 102.773407,467.155396 
z"/>
<path fill="#0B5D85" opacity="1.000000" stroke="none" 
	d="
M105.299919,464.063782 
	C105.529221,463.952515 105.825317,464.121735 106.259064,464.513885 
	C106.053383,464.605957 105.710052,464.475098 105.299919,464.063782 
z"/>
<path fill="#0B5D85" opacity="1.000000" stroke="none" 
	d="
M104.247177,462.931793 
	C104.490540,462.853912 104.767471,463.060455 105.130424,463.514343 
	C104.904541,463.579895 104.592651,463.398041 104.247177,462.931793 
z"/>
<path fill="#0B5D85" opacity="1.000000" stroke="none" 
	d="
M95.322693,453.870605 
	C95.576019,453.796936 95.858505,454.035858 96.260300,454.528748 
	C96.037018,454.582855 95.694427,454.382996 95.322693,453.870605 
z"/>
<path fill="#03AFF5" opacity="1.000000" stroke="none" 
	d="
M331.706207,310.343414 
	C333.174805,308.699066 334.606171,306.516327 336.065521,306.497467 
	C347.372894,306.351196 358.685364,306.669891 369.996918,306.734497 
	C373.096863,306.752228 373.939575,308.267853 373.982422,311.094025 
	C374.103394,319.076080 374.532928,327.055084 374.543945,335.035706 
	C374.546112,336.598694 373.632172,338.690094 372.430939,339.643341 
	C363.710724,346.563324 354.732391,353.157837 345.999420,360.062408 
	C342.273407,363.008362 339.474091,361.858582 338.236816,358.140198 
	C335.685760,350.473297 332.998169,342.689606 331.913483,334.742859 
	C330.843018,326.899963 331.681091,318.796509 331.706207,310.343414 
z"/>
<path fill="#DF0405" opacity="1.000000" stroke="none" 
	d="
M495.699768,211.186646 
	C495.524445,212.297867 495.089600,213.606049 494.329529,214.958511 
	C494.482971,213.796402 494.961609,212.590012 495.699768,211.186646 
z"/>
<path fill="#DF0405" opacity="1.000000" stroke="none" 
	d="
M498.700134,204.190826 
	C498.807953,204.954041 498.662262,205.920441 498.171997,206.886703 
	C498.033844,206.055725 498.240234,205.224884 498.700134,204.190826 
z"/>
<path fill="#DF0405" opacity="1.000000" stroke="none" 
	d="
M496.779968,208.086639 
	C496.930450,208.285095 496.789886,208.545639 496.379028,208.884766 
	C496.193085,208.678375 496.319794,208.406830 496.779968,208.086639 
z"/>
<path fill="#0B5D85" opacity="1.000000" stroke="none" 
	d="
M88.980392,442.725739 
	C88.765831,442.766663 88.480812,442.530121 88.118492,442.017639 
	C88.330772,441.977203 88.620354,442.212738 88.980392,442.725739 
z"/>
<path fill="#0B5D85" opacity="1.000000" stroke="none" 
	d="
M91.008484,446.727844 
	C90.796776,446.741943 90.512161,446.479523 90.196274,445.916260 
	C90.421860,445.894073 90.678711,446.172699 91.008484,446.727844 
z"/>
<path fill="#0979A9" opacity="1.000000" stroke="none" 
	d="
M91.923431,448.690277 
	C91.708839,448.697540 91.448929,448.408844 91.127129,447.824402 
	C91.336189,447.817169 91.607155,448.105743 91.923431,448.690277 
z"/>
<path fill="#B30507" opacity="1.000000" stroke="none" 
	d="
M97.222862,433.828003 
	C96.689987,433.515259 96.374100,433.029022 96.060928,432.235291 
	C96.522377,432.503326 96.981110,433.078918 97.222862,433.828003 
z"/>
<path fill="#089DD3" opacity="1.000000" stroke="none" 
	d="
M453.927551,365.307922 
	C454.254761,364.375275 454.867035,363.312408 455.832703,362.160461 
	C455.528259,363.106842 454.870422,364.142273 453.927551,365.307922 
z"/>
<path fill="#05A5E6" opacity="1.000000" stroke="none" 
	d="
M456.995605,358.995056 
	C457.437256,358.032227 457.878906,357.069427 458.665009,356.055084 
	C459.026733,356.202942 459.044006,356.402283 459.105835,356.898499 
	C459.007233,357.750122 458.864105,358.304901 458.154968,358.936829 
	C457.391144,359.007660 457.193390,359.001373 456.995605,358.995056 
z"/>
<path fill="#089DD3" opacity="1.000000" stroke="none" 
	d="
M456.992371,359.358063 
	C457.193390,359.001373 457.391144,359.007660 457.884460,359.048584 
	C458.015076,359.692810 457.850159,360.302490 457.389130,361.041565 
	C457.058380,360.687714 457.023773,360.204376 456.992371,359.358063 
z"/>
<path fill="#0B5D85" opacity="1.000000" stroke="none" 
	d="
M525.854980,101.945114 
	C525.244507,102.045853 524.683350,101.885094 523.936157,101.489998 
	C524.224426,101.198822 524.698669,101.141975 525.529419,101.118446 
	C525.891968,101.329048 525.898071,101.506340 525.854980,101.945114 
z"/>
<path fill="#0B5D85" opacity="1.000000" stroke="none" 
	d="
M493.450806,92.397308 
	C493.169312,92.474884 492.707794,92.413300 492.058563,92.182640 
	C492.337524,92.095085 492.804138,92.176613 493.450806,92.397308 
z"/>
<path fill="#05A5E6" opacity="1.000000" stroke="none" 
	d="
M463.637390,341.039429 
	C464.074615,341.289978 464.247253,341.554779 464.694336,341.881226 
	C464.898834,342.413055 464.886993,342.845306 464.777252,343.588013 
	C464.323608,343.928802 463.967896,343.959167 463.345795,344.015503 
	C463.157837,343.058197 463.236267,342.074860 463.637390,341.039429 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M504.491638,161.311569 
	C504.967590,165.165115 505.196045,169.339371 505.082520,173.763947 
	C504.575134,169.886917 504.409637,165.759613 504.491638,161.311569 
z"/>
<path fill="#05A5E6" opacity="1.000000" stroke="none" 
	d="
M461.525177,348.163940 
	C461.569885,347.556274 461.905029,346.828186 462.564056,346.031860 
	C462.530457,346.656891 462.173035,347.350189 461.525177,348.163940 
z"/>
<path fill="#970506" opacity="1.000000" stroke="none" 
	d="
M130.169159,446.962677 
	C129.972656,446.976288 129.776154,446.989868 129.284500,447.019775 
	C128.353104,446.675415 127.716873,446.314667 127.002075,445.680725 
	C127.806343,445.623993 128.689178,445.840485 129.851120,446.403107 
	C130.130234,446.749237 130.169159,446.962677 130.169159,446.962677 
z"/>
<path fill="#BB0405" opacity="1.000000" stroke="none" 
	d="
M130.387054,446.968475 
	C130.169159,446.962677 130.130234,446.749237 130.119690,446.644592 
	C130.374832,446.410217 130.613770,446.448364 130.933411,446.815796 
	C131.040878,446.977173 130.604935,446.974274 130.387054,446.968475 
z"/>
<path fill="#06060B" opacity="1.000000" stroke="none" 
	d="
M108.842361,456.159973 
	C110.109261,456.380920 111.104568,456.808716 112.411652,457.326721 
	C113.234558,457.942566 113.745705,458.468292 114.537842,459.046570 
	C114.818840,459.099121 114.796524,459.567352 114.716782,459.789093 
	C112.105156,459.644806 108.349083,461.316681 108.842361,456.159973 
z"/>

	

</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
